import React, { useEffect, useState } from "react";
import { Spin, Table, Input } from "antd";
import {
  MessageOutlined,
  CloseOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Fuse from "fuse.js";
import { bindActionCreators } from "redux";
import Title from "antd/lib/typography/Title";
import {
  getAllQueriesAsync,
  setQueryList,
  selectQueryAsync,
} from "../../../redux/query/actions";
import { formatDateTimeFromIso } from "../../../helpers/functions";

const QueryList = ({
  user,
  contentIsLoading,
  queryList,
  getAllQueriesAsync,
  setQueryList,
}) => {
  const reinitFuse = () => {
    const fuseOptions = {
      includeScore: true,
      keys: ["field.label", "patient.customId"],
    };

    return new Fuse(queryList, fuseOptions);
  };

  const handleListFiltering = () => {
    const strFilteredResults =
      strFilter.trim().length === 0
        ? queryList
        : fuse
            .search(strFilter)
            .filter((result) => result.score < 0.2)
            .map((result) => result.item);

    setFilteredQueryList(strFilteredResults);
  };

  const [fuse, setFuse] = useState(reinitFuse());
  const [strFilter, setStrFilter] = useState("");
  const [filteredQueryList, setFilteredQueryList] = useState(queryList);

  useEffect(() => {
    if (user) {
      const query =
        user.type === 1
          ? "?status=closed&isDoctorAgent=true"
          : "?status=closed";
      getAllQueriesAsync(query);
    }
    return () => {
      setQueryList([]);
    };
  }, [user]);

  useEffect(() => {
    setFuse(reinitFuse());
  }, [queryList]);

  useEffect(() => {
    handleListFiltering();
  }, [strFilter, queryList]);

  const columns = [
    {
      title: <b>Nature</b>,
      key: "queryName",
      render: (_, record) => (
        <p>
          {record.field.name === "hasNoTreatments"
            ? "Aucun traitement"
            : record.field.label}
        </p>
      ),
    },
    {
      title: <b>Patient</b>,
      key: "name",
      render: (_, record) => (
        /*<p>
          {`${record.patient.firstName ? record.patient.firstName.value : ""} ${
            record.patient.lastName ? record.patient.lastName.value : ""
          }`}
        </p> */
        <p>{`${record.patient ? record.patient.customId : ""}`}</p>
      ),
    },
    {
      title: <b> Date d&apos;envoi</b>,
      key: "date",
      render: (_, record) => <p>{formatDateTimeFromIso(record.createdAt)}</p>,
    },
  ];

  return (
    <Spin spinning={contentIsLoading}>
      <div className="section-content">
        <Title level={5}>Queries fermées</Title>
        <Input
          size="large"
          placeholder="Filtrer..."
          value={strFilter}
          onChange={(e) => setStrFilter(e.target.value)}
          prefix={<SearchOutlined />}
        />
        <Table
          className="m-top-medium"
          columns={columns}
          dataSource={filteredQueryList}
          locale={{ emptyText: "Aucune query" }}
        />
      </div>
    </Spin>
  );
};

QueryList.propTypes = {
  contentIsLoading: PropTypes.bool.isRequired,
  user: PropTypes.object,
  setQueryList: PropTypes.array.isRequired,
  getAllQueriesAsync: PropTypes.func.isRequired,
  queryList: PropTypes.array.isRequired,
};

QueryList.defaultProps = {
  user: null,
};

const stateToProps = (state) => ({
  user: state.auth.user,
  queryList: state.queries.queryList,
  contentIsLoading: state.loading.contentIsLoading,
});

const dispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllQueriesAsync,
      selectQueryAsync,
      setQueryList,
    },
    dispatch
  );

export default connect(stateToProps, dispatchToProps)(QueryList);

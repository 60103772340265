import React from "react";
import PropTypes from "prop-types";
import { Form, Card, Typography, Divider } from "antd";
import DynamicField from "../DynamicField";
import Title from "antd/lib/skeleton/Title";

const ExamenHistologique = (props) => {
  const hostoriqueV0 = [
    "NOMBiopsie",
    "NumBiopsie",
    "typeHisto",
    "SBR",
    "RAO",
    "Rprog",
    "Her2Neu",
    "Ki67",
    "PDL1",
    "MMR",
    "TILs",
  ];
  const hostoriqueV1 = ["PDL1", "TILs"];

  let histologiqueToLoop = props.visit === "j0" ? hostoriqueV0 : hostoriqueV1;
  console.log('Bebug Her2Neu val',props["Her2Neu"])
  return (
    <Form
      style={{ marginTop: 30 }}
      form={props.form}
      className="biological-examination-form"
    >
      {props.isAdd ? (
        <>
          {props.visit === "j0" || props.visit === "j2" ? (
            <Card title="Examen Histologique" className="add-antecedent-card">
              <div style={{ marginLeft: "4%" }}>
                <div>
                  {histologiqueToLoop.map((k,idx) => (
                    <>
                      {idx <1 && <h1>Biopsie avec numéro de la pièce attribué par le laboratoire</h1>}
                      <DynamicField
                        key={k}
                        field={props.values.examenHistologique[k]}
                        onUpdate={props.onUpdate}
                        fieldFamily="examenHistologique"
                        radio={true}
                      />
                      {props[k] === "Score 2" ||
                      props.values.examenHistologique[k].value ===
                        "Score 2" ? (
                        <>
                          <DynamicField
                            key={props.values.examenHistologique[k].name}
                            field={
                              props.values.examenHistologique[`Her2NeuCheck`]
                            }
                            onUpdate={props.onUpdate}
                            fieldFamily="examenHistologique"
                          />
                        </>
                      ) : null}
                    </>
                  ))}
                </div>
              </div>
            </Card>
          ) : null}
        </>
      ) : (
        <>
          {props.visit === "j0" || props.visit === "j2" ? (
            <Card title="Examen Histologique" className="add-antecedent-card">
              <div style={{ marginLeft: "4%" }}>
                <div>
                  {histologiqueToLoop.map((k,idx) => (
                    <>
                      {idx <1 && <h1>Biopsie avec numéro de la pièce attribué par le laboratoire</h1>}
                      <DynamicField
                        key={k}
                        field={props.values.examenHistologique[k]}
                        onUpdate={props.onUpdate}
                        fieldFamily="examenHistologique"
                        radio={false}
                      />
                      {props[k] === "Score 2" ||
                      props.values.examenHistologique[k].value ===
                        "Score 2" ? (
                        <>
                          <DynamicField
                            key={props.values.examenHistologique[k].name}
                            field={
                              props.values.examenHistologique[`Her2NeuCheck`]
                            }
                            onUpdate={props.onUpdate}
                            fieldFamily="examenHistologique"
                          />
                        </>
                      ) : null}
                    </>
                  ))}
                </div>
              </div>
            </Card>
          ) : null}
        </>
      )}
    </Form>
  );
};
ExamenHistologique.propTypes = {
  form: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
};

ExamenHistologique.defaultProps = {
  onUpdate: () => {},
};

export default ExamenHistologique;

import React from "react";
import PropTypes from "prop-types";
import { Form, Card, Divider, Typography } from "antd";
import DynamicField from "../DynamicField";
import { calculateBMI } from "../../helpers/functions";
import "./styles.css";
import moment from "moment";

const { Text } = Typography;

const ShowPatientData = ({ patient, visit }) => {
  return (
    <div className="inclusionContainer">
      <Card title="Données personnelles" className="add-antecedent-card">
        <div>
          <div>
            <strong>Identifiant du participant</strong>
            <p>{patient && patient.customId}</p>
          </div>
          <div>
            <strong>{patient.firstName.label}</strong>
            <p>{patient.firstName.value}</p>
          </div>
          <div>
            <strong>{patient.lastName.label}</strong>
            <p>{patient.lastName.value}</p>
          </div>
          <div>
            <strong>Date de naissance</strong>
            <p>{moment(patient.birthDate.value).format('L')}</p>
          </div>
          <div>
            <strong>Sexe</strong>
            <p>{patient.gender.value}</p>
          </div>
          {/* {patient.phoneFirst && (
            <div>
              <strong>{patient.phoneFirst && patient.phoneFirst.label}</strong>
              <p> {patient.phoneFirst && patient.phoneFirst.value}</p>
            </div>
          )}
          {patient.phoneSecond  && (
            <div>
              <strong>
                {patient.phoneSecond && patient.phoneSecond.label}
              </strong>
              <p>{patient.phoneSecond && patient.phoneSecond.value}</p>
            </div>
          )}
          {patient && patient.hasOwnProperty("phoneThird") ? (
            <div>
              <strong>{ patient.phoneThird.label}</strong>
              <p>{ patient.phoneThird.value } </p>
            </div>
          ) : null} */}
          {/* {patient.address !==undefined  && (
            <div>
              <strong>{patient.address && patient.address.label}</strong>
              <p>{patient.address && patient.address.value}</p>
            </div>
          )} */}
        </div>
      </Card>
    </div>
  );
};

ShowPatientData.propTypes = {
  patient: PropTypes.object.isRequired,
  visit: PropTypes.string.isRequired,
};

export default ShowPatientData;

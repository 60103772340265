import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Card, message, Table, Button, Form } from "antd";
import PropTypes from "prop-types";
import moment from "moment";
import AddAntecedent from "./add";
import FieldActions from "../FieldActions";
import { updatePatientAsync } from "../../redux/patients/actions";
import DynamicField from "../DynamicField";

const addInputs = [
  {
    name: "NatureTraitement",
    label: "Nature du traitement",
    type: "radio",
    required: true,
    radioFields: [
      { label: "Traitements en cours", value: "Traitements en cours" },
      { label: "Traitements concomitants", value: "Traitements concomitants" },
    ],
  },
  {
    label: "Traitement",
    name: "treatment",
    required: true,
    type: "input",
  },
  {
    label: "Posologie",
    name: "posology",
    required: true,
    type: "input",
  },
  {
    label: "Date de début du traitement",
    name: "date",
    required: true,
    type: "date",
    placeholder: "e.g: 01/01/2000",
  },
  {
    label: "Observation",
    name: "observation",
    required: false,
    type: "input",
    placeholder: "Observation",
  },
];

export const treatmentsColumns = [
  {
    title: <b> Nature </b>,
    dataIndex: "NatureTraitement",
    key: "NatureTraitement",
  },
  {
    title: <b> Traitement </b>,
    dataIndex: "treatment",
    key: "treatment",
  },
  {
    title: <b> Posologie </b>,
    dataIndex: "posology",
    key: "posology",
  },
  {
    title: <b> Date de debut </b>,
    dataIndex: "date",
    key: "date",
  },
  {
    title: <b> Observation </b>,
    dataIndex: "observation",
    key: "observation",
  },
];

const Treatments = ({
  values,
  onChange,
  onFormUpdate,
  isAdd,
  form,
  user,
  updatePatientAsync,
  selectedPatient,
  showForm,
  setShowForm,
}) => {
  const [hideTreatmentsTable, setHideTreatmentsTable] = useState(
    values.hasNoTreatments.value || false
  );

  const onDelete = (row) => {
    const newArr = values.treatments.filter((item) => item.key !== row.key);
    onChange("treatments", newArr);
    message.success("Traitement supprimé avec succès");
  };

  const onAdd = (allergy) => {
    allergy.date = moment(allergy.date._d).format("L");
    allergy.nature = "Traitement en cours";
    allergy.key = Math.random().toString();
    const newTreatments = [...values.treatments, allergy];
    console.log("DEBUG treamtement", {
      treamtens: values.treatments,
      newTreatments,
      allergy,
    });
    onChange("treatments", newTreatments);
    message.success("Traitement ajouté avec succès");
  };

  const onUpdate = (fieldFamily, _, prop, value) => {
    updatePatientAsync(selectedPatient._id, {
      fieldFamily,
      field: {
        ...values[fieldFamily],
        [prop]: value,
        value: "!",
      },
      visit: 1,
    });
  };

  if (user && user.type === 2 && treatmentsColumns.length === 6) {
    treatmentsColumns.pop();
  }

  console.log("[debug] no treatment : ", {
    "values.hasNoTreatments": values.hasNoTreatments,
    hideTreatmentsTable,
  });

  return (
    <div>
      <Form form={form}>
        <DynamicField
          key={"hasNoTreatments"}
          field={values["hasNoTreatments"]}
          onUpdate={
            isAdd
              ? (name, value) => {
                  const boolValue = value === "true";

                  onFormUpdate(name, boolValue);
                  console.log("[debug] form change value : ", boolValue);
                  setHideTreatmentsTable(boolValue);
                }
              : onFormUpdate
          }
          fieldFamily="hasNoTreatments"
          radio={isAdd ? true : false}
        />
      </Form>
      {form.getFieldValue("hasNoTreatments") === true ||
      form.getFieldValue("hasNoTreatments") === "true" ? null : (
        <>
          {user && user.type === 1 ? (
            <Card title="Traitements en cours" className="add-antecedent-card">
              <AddAntecedent
                onAdd={onAdd}
                buttonLabel="Ajouter le traitement"
                radioSelection={[]}
                inputs={addInputs}
                extraRadioInputs={[]}
                disabled={values.treatmentsProps.frozen}
                radioTitle="Traitements en cours"
                lengthArrayData={values.treatments}
                showForm={showForm}
                setShowForm={setShowForm}
              />
            </Card>
          ) : (
            <div
              className="flex m-bottom-medium"
              style={{ justifyContent: "center" }}
            >
              <FieldActions
                field={values.treatmentsProps}
                fieldFamily="treatmentsProps"
                onUpdate={onUpdate}
              />
            </div>
          )}

          <div>
            <Table
              dataSource={values.treatments}
              columns={
                user && user.type === 1
                  ? [
                      ...treatmentsColumns,
                      {
                        title: <b> Actions </b>,
                        key: "action",
                        render: (a) => (
                          <Button
                            disabled={values.treatmentsProps.frozen}
                            danger
                            shape="round"
                            onClick={() => {
                              onDelete(a);
                            }}
                          >
                            Supprimer
                          </Button>
                        ),
                      },
                    ]
                  : treatmentsColumns
              }
              locale={{ emptyText: "Aucun traitement" }}
            />
          </div>
        </>
      )}
    </div>
  );
};

Treatments.propTypes = {
  values: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isAdd: PropTypes.bool,
  user: PropTypes.object,
  updatePatientAsync: PropTypes.func.isRequired,
  selectedPatient: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
};

Treatments.defaultProps = {
  user: null,
  isAdd: false,
};

const stateToProps = (state) => ({
  user: state.auth.user,
  selectedPatient: state.patients.selectedPatient,
});

const dispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updatePatientAsync,
    },
    dispatch
  );

export default connect(stateToProps, dispatchToProps)(Treatments);

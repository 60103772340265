import React from "react";
import PropTypes from "prop-types";
import { Form, Card, Typography, Divider } from "antd";
import DynamicField from "../DynamicField";

const AntecedentsMed = (props) => {
  return (
    <Form
      style={{ marginTop: 30 }}
      form={props.form}
      className="biological-examination-form"
    >
      {props.isAdd ? (
        <>
          {props.visit === "j0" && (
            <Card title="Antécedents Médicaux" className="add-antecedent-card">
              <div>
                {["HA", "DIABETE", "MC", "MR", "MF"].map((k, index) => {
                  return (
                    <>
                      <DynamicField
                        key={k}
                        field={props.values.antecedentMed[k]}
                        onUpdate={props.onUpdate}
                        fieldFamily="antecedentMed"
                        radio={true}
                      />
                      {props[k] === "Oui" ||
                      props.values.antecedentMed[k].value === "Oui" ? (
                        <>
                          <DynamicField
                            key={props.values.antecedentMed[k].name}
                            field={
                              props.values.antecedentMed[
                                `${k}dateDebut`
                              ]
                            }
                            onUpdate={props.onUpdate}
                            fieldFamily="antecedentMed"
                          />
                          <DynamicField
                            key={props.values.antecedentMed[k].name}
                            field={
                              props.values.antecedentMed[`${k}dateFin`]
                            }
                            onUpdate={props.onUpdate}
                            fieldFamily="antecedentMed"
                          />
                        </>
                      ) : null}
                      <Divider />
                    </>
                  );
                })}
              </div>
            </Card>
          )}
        </>
      ) : (
        <>
          {props.visit === "j0" && (
            <Card title="Antécedents Médicaux" className="add-antecedent-card">
              <div>
                {["HA", "DIABETE", "MC", "MR", "MF"].map((k, index) => {
                  return (
                    <>
                      <DynamicField
                        key={k}
                        field={props.values.antecedentMed[k]}
                        onUpdate={props.onUpdate}
                        fieldFamily="antecedentMed"
                        radio={false}
                      />
                      {props.values.antecedentMed[k].value === "Oui" ? (
                        <>
                          <DynamicField
                            key={props.values.antecedentMed[k].name}
                            field={
                              props.values.antecedentMed[
                                `${k}dateDebut`
                              ]
                            }
                            onUpdate={props.onUpdate}
                            fieldFamily="antecedentMed"
                          />
                          <DynamicField
                            key={props.values.antecedentMed[k].name}
                            field={
                              props.values.antecedentMed[`${k}dateFin`]
                            }
                            onUpdate={props.onUpdate}
                            fieldFamily="antecedentMed"
                          />
                        </>
                      ) : null}
                      <Divider />
                    </>
                  );
                })}
              </div>
            </Card>
          )}
        </>
      )}
    </Form>
  );
};
AntecedentsMed.propTypes = {
  form: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
};

AntecedentsMed.defaultProps = {
  onUpdate: () => {},
};

export default AntecedentsMed;

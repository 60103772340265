import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form, Divider } from "antd";
import { connect } from "react-redux";
import DynamicField from "../DynamicField";

const General = ({
  form,
  values,
  onUpdate,
  //patientsList,
  user,
  ageNumber,
  alcohol,
  tabac,
  gender,
  distnceParticipant,
}) => {
  //const [showCustomId, setShowCustomId] = useState(false);
  // const [customId, setCustomId] = useState(0);

  // useEffect(() => {
  //   let patients = [];
  //   patients = patientsList;
  //   if (!patients.length) setCustomId("001");
  //   if (patients.length) {
  //     const lastObject = patients.reduce((r, a) =>
  //       r.createdAt > a.createdAt ? r : a
  //     );
  //     let customId = lastObject.customId
  //       ? lastObject.customId.substr(lastObject.customId.lastIndexOf("-") + 1)
  //       : "0";
  //     parseInt(customId, 10);
  //     customId++;
  //     const padStart = customId.toString().padStart(3, 0);
  //     setCustomId(padStart);
  //   }
  // }, [patientsList]);

  // const handleValuesChange = (val) => {
  //   if (val.birthDate === "Oui") setShowCustomId(true);

  //   if (val.birthDate === "Non") setShowCustomId(false);
  // };
  return (
    <Form
      style={{ marginTop: 30 }}
      form={form}
      //onValuesChange={handleValuesChange}
    >
      <div
        className="flex flex-wrap"
        style={{
          justifyContent: "space-between",
          padding: "0 34px",
          marginRight: "10%",
        }}
      >
        {user && user.type === 1 ? (
          <>
            <div>
              {[
                "firstName",
                "lastName",
                // "phoneFirst",
                // "phoneSecond",
                // "phoneThird",
                // "address",
                //"education",
                // "alcohol",
                // "alcoholConsommation",
                "habitation",
                "birthDate",
              ].map((k) => (
                <DynamicField
                  key={k}
                  field={values[k]}
                  onUpdate={onUpdate}
                  fieldFamily="general"
                />
              ))}
              {ageNumber ? (
                ageNumber < 18 ? (
                  <strong style={{ color: "red" }}>âge :{ageNumber} ans</strong>
                ) : (
                  <strong style={{ color: "green" }}>
                    âge :{ageNumber} ans
                  </strong>
                )
              ) : null}
              {ageNumber ? (
                ageNumber < 18 ? (
                  <><br/><strong style={{ color: "red" }}>le patient ne peut pas être inclus : âge inférieur à 18 ans .</strong></>
                ) : null
              ) : null}
              <br/>
              {/* {
                <DynamicField
                  key={"distance"}
                  field={values["distance"]}
                  onUpdate={onUpdate}
                  fieldFamily="general"
                />
              }
              {distnceParticipant ? (
                <strong style={{ color: "red" }}>
                  Merci de vérifier la distance par rapport au centre.
                </strong>
              ) : (
                ""
              )} */}
            </div>
            {/* <div>
              <DynamicField
                key={"tabac"}
                field={values["tabac"]}
                onUpdate={onUpdate}
                fieldFamily="general"
              />
              {tabac === "true" || values.tabac.value === true ? (
                <DynamicField
                  key={"nbCigarettes"}
                  field={values["nbCigarettes"]}
                  onUpdate={onUpdate}
                  fieldFamily="general"
                />
              ) : null}
            </div> */}
            {/* <div>
              <DynamicField
                key={"alcohol"}
                field={values["alcohol"]}
                onUpdate={onUpdate}
                fieldFamily="general"
              />
              {alcohol === "true" || values.alcohol.value === true ? (
                <DynamicField
                  key={"alcoholConsommation"}
                  field={values["alcoholConsommation"]}
                  onUpdate={onUpdate}
                  fieldFamily="general"
                />
              ) : null}
            </div> */}
            <div>
              <DynamicField
                key={"gender"}
                field={values["gender"]}
                onUpdate={onUpdate}
                fieldFamily="general"
              />
              {gender === "Femme" || values.gender.value === "Femme" ? (
                <DynamicField
                  key={"pregnant"}
                  field={values["pregnant"]}
                  onUpdate={onUpdate}
                  fieldFamily="general"
                />
              ) : null}
            </div>
          </>
        ) : user.type === 2 ? (
          <div>
            {["birthDate"].map((k) => (
              <DynamicField
                key={k}
                field={values[k]}
                onUpdate={onUpdate}
                fieldFamily="general"
              />
            ))}
            {/* <div>
              <DynamicField
                key={"tabac"}
                field={values["tabac"]}
                onUpdate={onUpdate}
                fieldFamily="general"
              />
              {values.tabac.value === true ? (
                <DynamicField
                  key={"nbCigarettes"}
                  field={values["nbCigarettes"]}
                  onUpdate={onUpdate}
                  fieldFamily="general"
                />
              ) : null}
            </div> */}
            {/* <div>
              <DynamicField
                key={"alcohol"}
                field={values["alcohol"]}
                onUpdate={onUpdate}
                fieldFamily="general"
              />
              {values.alcohol.value === true ? (
                <DynamicField
                  key={"alcoholConsommation"}
                  field={values["alcoholConsommation"]}
                  onUpdate={onUpdate}
                  fieldFamily="general"
                />
              ) : null}
            </div> */}
            <div>
              <DynamicField
                key={"gender"}
                field={values["gender"]}
                onUpdate={onUpdate}
                fieldFamily="general"
              />
              {values.gender.value === "Femme" ? (
                <DynamicField
                  key={"pregnant"}
                  field={values["pregnant"]}
                  onUpdate={onUpdate}
                  fieldFamily="general"
                />
              ) : null}
            </div>
          </div>
        ) : (
          <div>
            {[
              "firstName",
              "lastName",
              // "phoneFirst",
              // "phoneSecond",
              // "phoneThird",
              // "address",
              //"distance",
              //"education",
              "habitation",
              "birthDate",
            ].map((k) => (
              <DynamicField
                key={k}
                field={values[k]}
                onUpdate={onUpdate}
                fieldFamily="general"
              />
            ))}
            {/* <div>
              <DynamicField
                key={"tabac"}
                field={values["tabac"]}
                onUpdate={onUpdate}
                fieldFamily="general"
              />
              {values.tabac.value === true ? (
                <DynamicField
                  key={"nbCigarettes"}
                  field={values["nbCigarettes"]}
                  onUpdate={onUpdate}
                  fieldFamily="general"
                />
              ) : null}
            </div> */}
            {/* <div>
              <DynamicField
                key={"alcohol"}
                field={values["alcohol"]}
                onUpdate={onUpdate}
                fieldFamily="general"
              />
              {values.alcohol.value === true ? (
                <DynamicField
                  key={"alcoholConsommation"}
                  field={values["alcoholConsommation"]}
                  onUpdate={onUpdate}
                  fieldFamily="general"
                />
              ) : null}
            </div> */}
            <div>
              <DynamicField
                key={"gender"}
                field={values["gender"]}
                onUpdate={onUpdate}
                fieldFamily="general"
              />
              {values.gender.value === "Femme" ? (
                <DynamicField
                  key={"pregnant"}
                  field={values["pregnant"]}
                  onUpdate={onUpdate}
                  fieldFamily="general"
                />
              ) : null}
            </div>
          </div>
        )}
      </div>
      <Divider />
      {/* {['consentSign', 'consentDate'].map(k => (
                <DynamicField
                    key={k}
                    field={values[k]}
                    onUpdate={onUpdate}
                    fieldFamily="general"
                />
            ))} */}
      {/* {showCustomId ? ( */}
      {/* <strong>
          Identifiant du patient: BEALL-212-
          {customId}
        </strong> */}
      {/* ) : null} */}
    </Form>
  );
};  

General.propTypes = {
  form: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
  user: PropTypes.func.isRequired,
  patientsList: PropTypes.array.isRequired,
};

General.defaultProps = {
  onUpdate: () => {},
};

const stateToProps = (state) => ({
  patientsList: state.patients.patientsList,
  user: state.auth.user,
});

export default connect(stateToProps)(General);

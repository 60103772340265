import React from "react";
import PropTypes from "prop-types";
import { Form, Card, Typography, Divider } from "antd";
import DynamicField from "../DynamicField";
import Title from "antd/lib/skeleton/Title";

const ExamenClinique = (props) => {
  const ExamenCLiliniqueTitle = [
    "Etat Général selon la classification de l'OMS ou selon l'Indice de Karnowsky",
    "",
    "Examen mammaire",
    "",
    "Taille de la tumeur",
    "",
    "",
    "Examen des aires ganglionnaires axillaires",
    "Examen des aires ganglionnaires sus-claviculaires",
    "Examen abdominal",
    "Auscultation pulmonaire",
    "Examen du squelette",
  ];
  return (
    <Form
      style={{ marginTop: 30 }}
      form={props.form}
      className="biological-examination-form"
    >
      {props.isAdd ? (
        <>
          <Card title="Examen clinique" className="add-antecedent-card">
            <div style={{ marginLeft: "4%" }}>
              <div>
                {[
                  "ScoreOMS",
                  "IndiceKarnowsky",
                  "symétrie",
                  "Peau",
                  "tumeurTaille",
                  "tumeurTailleSecond",
                  "écoulement",
                  "ganglionnaires",
                  "ganglionnairesSus",
                  "abdominal",
                  "auscultation",
                  "squelette",
                ].map((k, indice) => (
                  <>
                    {/* <Title style={{ marginBottom: 14 }} level={5}> */}
                    {ExamenCLiliniqueTitle[indice] &&
                    ExamenCLiliniqueTitle[indice] !== "" ? (
                      <h1>{ExamenCLiliniqueTitle[indice]}</h1>
                    ) : null}
                    {/* </Title> */}

                    {["tumeurTaille", "tumeurTailleSecond"].includes(k) ? (
                      <div style={{ width: "40%", display: "inline-block" }}>
                        <DynamicField
                          key={k}
                          field={props.values.examenClinique[k]}
                          onUpdate={props.onUpdate}
                          fieldFamily="examenClinique"
                          radio={true}
                        />
                      </div>
                    ) : (
                      <DynamicField
                        key={k}
                        field={props.values.examenClinique[k]}
                        onUpdate={props.onUpdate}
                        fieldFamily="examenClinique"
                        radio={true}
                      />
                    )}
                    {props[k] === "Anormal" ||
                    props.values.examenClinique[k].value === "Anormal" ? (
                      <>
                        <DynamicField
                          key={props.values.examenClinique[k].name}
                          field={props.values.examenClinique[`${k}Rep`]}
                          onUpdate={props.onUpdate}
                          fieldFamily="examenClinique"
                        />
                      </>
                    ) : null}
                  </>
                ))}
              </div>
            </div>
          </Card>
        </>
      ) : (
        <>
          <Card title="Examen clinique" className="add-antecedent-card">
            <div style={{ marginLeft: "4%" }}>
              <div>
                {[
                  "ScoreOMS",
                  "IndiceKarnowsky",
                  "symétrie",
                  "Peau",
                  "tumeurTaille",
                  "tumeurTailleSecond",
                  "écoulement",
                  "ganglionnaires",
                  "ganglionnairesSus",
                  "abdominal",
                  "auscultation",
                  "squelette",
                ].map((k, indice) => (
                  <>
                    {ExamenCLiliniqueTitle[indice] &&
                    ExamenCLiliniqueTitle[indice] !== "" ? (
                      <h1>{ExamenCLiliniqueTitle[indice]}</h1>
                    ) : null}
                    {["tumeurTaille", "tumeurTailleSecond"].includes(k) ? (
                      <div style={{ width: "40%", display: "inline-block" }}>
                        <DynamicField
                          key={k}
                          field={props.values.examenClinique[k]}
                          onUpdate={props.onUpdate}
                          fieldFamily="examenClinique"
                          radio={false}
                        />
                      </div>
                    ) : (
                      <DynamicField
                        key={k}
                        field={props.values.examenClinique[k]}
                        onUpdate={props.onUpdate}
                        fieldFamily="examenClinique"
                        radio={false}
                      />
                    )}
                    {props[k] === "Anormal" ||
                    props.values.examenClinique[k].value === "Anormal" ? (
                      <>
                        <DynamicField
                          key={props.values.examenClinique[k].name}
                          field={props.values.examenClinique[`${k}Rep`]}
                          onUpdate={props.onUpdate}
                          fieldFamily="examenClinique"
                        />
                      </>
                    ) : null}
                  </>
                ))}
              </div>
            </div>
          </Card>
        </>
      )}
    </Form>
  );
};
ExamenClinique.propTypes = {
  form: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
};

ExamenClinique.defaultProps = {
  onUpdate: () => {},
};

export default ExamenClinique;

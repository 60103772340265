import React, { useState, useEffect, createContext } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Spin, Table } from "antd";
import Title from "antd/lib/typography/Title";
import { PlusOutlined } from "@ant-design/icons";
import AddVisit from "./add";
import "./styles.css";
import {
  getAllVisitsAsync,
  selectVisitAsync,
  setVisitsList,
  selectVisit,
} from "../../redux/visits/actions";
import { formatDateTimeFromIso } from "../../helpers/functions";
import SelectedVisit from "./SelectedVisit";
import moment from "moment";
import { withRouter } from "react-router-dom";
import history from "../../history";

export const EditContext = createContext(false);
const Visit = ({
  contentIsLoading,
  getAllVisitsAsync,
  setVisitsList,
  visitsList,
  visit,
  selectVisitAsync,
  selectedVisit,
  selectVisit,
  user,
  ...props
}) => {
  const [showAdd, setShowAdd] = useState(false);
  const [showConsult, setShowConsult] = useState(false);
  const { location } = props;

  useEffect(() => {
    getAllVisitsAsync(visit);
    return () => {
      setVisitsList([]);
    };
  }, [visit, showAdd, showConsult]);

  useEffect(() => {
    console.log("debug nav - hook triggered",location.state);
    if ((location.state || {}).newPatient !== undefined) {
      setShowAdd(true);
    } else if ((location.state || {}).clicked !== undefined) {
      selectVisitAsync(location.state.selectedVisitFromTree._id);
      setShowConsult(true);
    } else if (location.state !== undefined) {
      if (location.state.withQueryPatientId && visitsList.length > 0) {
        const selectedVisitObject = visitsList.find((e) => {
          return e.patient._id === location.search.split("?")[1];
        });
        selectVisitAsync(selectedVisitObject._id);
        setShowConsult(true);
      }
    }
  }, [location, visitsList]);

  const closeSection = () => {
    
    console.log("debug nav - handling go back");
    
    if (history.location.state && (history.location.state.newPatient
      || history.location.state.patient)) {
     let state = { ...history.location.state };
     delete state.newPatient;
     delete state.patient;
     history.replace({ ...history.location, state });
 }
    setShowAdd(false);
    setShowConsult(false);
    selectVisit(null);

  };

  const isEdit = true;

  if (selectedVisit) {
    if (showConsult) {
      return (
        <EditContext.Provider value={!isEdit}>
          <SelectedVisit visit={visit} handleGoBack={closeSection} />
        </EditContext.Provider>
      );
    }
  }
  console.log("debug nav - showAdd: ", showAdd)
  if (showAdd) {
    return (
      <div className="section-content">
        <AddVisit
          visit={visit}
          closeAdd={closeSection}
          patientAdded={
            (location.state || {}).newPatient !== undefined
              ? location.state.patient
              : null
          }
        />
      </div>
    );
  }

  const columns = [
    {
      title: <b> Identifiant </b>,
      key: "customId",
      render: (_, record) => <p>{record.patient.customId}</p>,
    },
    {
      title: <b> Age </b>,
      key: "birthDate",
      render: (_, record) => (
        <p>
          {record.patient.birthDate
            ? `${new Date().getFullYear() -
                moment(record.patient.birthDate.value).year()} ans`
            : ""}
        </p>
      ),
    },
    // {
    //     title: <b>Traitement </b>,
    //     key: 'administratedDrug',
    //     render: (_, record) => <p>{record.administratedDrug.value ? record.administratedDrug.value : ''}</p>,
    // },
    {
      title: <b> Date de création </b>,
      key: "date",
      render: (_, record) => <p>{formatDateTimeFromIso(record.createdAt)}</p>,
    },
    {
      title: <b> Actions </b>,
      key: "_id",
      render: (_, record) => (
        <div className="flex">
          <Button
            type="primary"
            ghost
            onClick={() => {
              selectVisitAsync(record._id);
              setShowConsult(true);
            }}
          >
            Consulter
          </Button>
        </div>
      ),
    },
  ];

  if (visit !== "j0" && visit !== "j7") {
    columns.splice(2, 1);
  }
  
  return (
    <Spin spinning={contentIsLoading}>
      <div className="section-content">
        <Title style={{  }} level={5}>
          Liste des visites
        </Title>
        {/* {user && user.type === 1 && (visit === "j0" || visit === "j2") ? ( */}
        { user && (user.type === 1 &&  <Button
          onClick={() => setShowAdd(true)}
          shape="round"
          className="m-top-medium m-bottom-large"
          type="primary"
          size="medium"
          style={{ float: "right", marginTop: "-2px" }}
          icon={<PlusOutlined />}
        >
          Ajouter une visite
        </Button>)}
        {/* ) : user && user.type === 3 && visit === "j1" ? (
          <Button
            onClick={() => setShowAdd(true)}
            shape="round"
            className="m-top-medium m-bottom-large"
            type="primary"
            size="medium"
            style={{ float: "right", marginTop: "-2px" }}
            icon={<PlusOutlined />}
          >
            Ajouter une visite
          </Button>
        ) : null} */}
        <Table
          locale={{ emptyText: "Aucune visite" }}
          columns={columns}
          dataSource={visitsList}
        />
      </div>
    </Spin>
  );
};

Visit.propTypes = {
  contentIsLoading: PropTypes.bool.isRequired,
  getAllVisitsAsync: PropTypes.func.isRequired,
  setVisitsList: PropTypes.func.isRequired,
  visitsList: PropTypes.array.isRequired,
  visit: PropTypes.string.isRequired,
  selectVisitAsync: PropTypes.func.isRequired,
  selectVisit: PropTypes.func.isRequired,
  selectedVisit: PropTypes.object,
  user: PropTypes.object.isRequired,
};

Visit.defaultProps = {
  selectedVisit: null,
};

const stateToProps = (state) => ({
  contentIsLoading: state.loading.contentIsLoading,
  visitsList: state.visits.visitsList,
  selectedVisit: state.visits.selectedVisit,
  user: state.auth.user,
});

const dispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllVisitsAsync,
      setVisitsList,
      selectVisitAsync,
      selectVisit,
    },
    dispatch
  );

export default withRouter(connect(stateToProps, dispatchToProps)(Visit));

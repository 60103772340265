
import { message } from 'antd';
import * as PatientsAPI from '../../../api/patient';
import { sessionExpired } from '../../auth/actions';
import { setContentIsLoading } from '../../loading/actions';
import history from "../../../history";

export const SET_PATIENTS_LIST = 'SET_PATIENTS_LIST';
export const ADD_PATIENT = 'ADD_PATIENT';
export const SELECT_PATIENT = 'SELECT_PATIENT';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';
export const SELECT_AUDIT = 'SELECT_AUDIT';

export const setPatientsList = value => ({
    type: SET_PATIENTS_LIST,
    payload: value,
});

export const selectPatient = value => ({
    type: SELECT_PATIENT,
    payload: value,
});

export const addPatient = value => ({
    type: ADD_PATIENT,
    payload: value,
});

export const updatePatient = value => ({
    type: UPDATE_PATIENT,
    payload: value,
});


export const selectAudit = value => ({
    type: SELECT_AUDIT,
    payload: value,
});


export const getAllPatientsAsync = () => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await PatientsAPI.getAll();
        dispatch(setPatientsList(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const setPendingListAsync = (visit, cb) => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await PatientsAPI.getAllPendingByVisit(visit);
        dispatch(setPatientsList(res.data));
        if (!res.data.length) {
            cb();
            message.info('Aucun participant éligible à effectuer cette visite');
        }
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const createPatientAsync = body => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await PatientsAPI.create(body);
        dispatch(addPatient(res.data));
        message.success('Participant ajouté avec succès');

        history.push({
            pathname : "/j0",
            state:{ newPatient : true, patient: res.data }
        });
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const selectPatientAsync = id => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await PatientsAPI.getById(id);
        dispatch(selectPatient(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const updatePatientAsync = (id, body) => async (dispatch) => {
    console.log("[DEBUG] update patient async")
    dispatch(setContentIsLoading(true));
    try {
        const res = await PatientsAPI.update(id, body);
        dispatch(selectPatient(res.data));
        dispatch(updatePatient(res.data));
        message.success('Modification effectuée avec succès');
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const selectAuditAsync = (id, query) => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await PatientsAPI.getAuditTrial(id, query);
        dispatch(selectAudit(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};

import React from "react";
import PropTypes from "prop-types";
import { Form, Card, Typography, Divider } from "antd";
import DynamicField from "../DynamicField";

const ExamenRadiologique = (props) => {
  const ExamenRadioTitle = [
    "Mammographie et échographie mammaire",
    "Scanner thoraco-abdomino-pelvien",
    "Scintigraphie osseuse",
    "Echographie cardiaque",
  ];
  console.log(
    'props.values.examenRadiologique["Mammographie"].value',
    props["Mammographie"],
    props.values.examenRadiologique["Mammographie"].value
  );
  return (
    <Form
      style={{ marginTop: 30 }}
      form={props.form}
      className="biological-examination-form"
    >
      {props.isAdd ? (
        <>
          {(props.visit === "j0" ||
            props.visit === "j4" ||
            props.visit === "j3") && (
            <Card title="Examen radiologique" className="add-antecedent-card">
              <div style={{ marginLeft: "4%" }}>
                <div>
                  {[
                    "Mammographie",
                    // "mamoTaille",
                    // "mamoTailleSecond",
                    "thoraco",
                    "scintigraphie",
                    "echographie",
                  ].map((k, indice) => {
                    return (
                      <>
                        <h1>{ExamenRadioTitle[indice]}</h1>
                        <DynamicField
                          key={k}
                          field={props.values.examenRadiologique[k]}
                          onUpdate={props.onUpdate}
                          fieldFamily="examenRadiologique"
                          radio={true}
                        />
                        {props[k] === "Oui" ||
                        props.values.examenRadiologique["Mammographie"]
                          .value === "Oui" ? (
                          <>
                            <h1>Taille de l'opacité</h1>
                            <div
                              style={{ width: "40%", display: "inline-block" }}
                            >
                              <DynamicField
                                key={
                                  props.values.examenRadiologique["mamoTaille"]
                                    .name
                                }
                                field={
                                  props.values.examenRadiologique[
                                    `${"mamoTaille"}`
                                  ]
                                }
                                onUpdate={props.onUpdate}
                                fieldFamily="examenRadiologique"
                              />
                            </div>
                            <div
                              style={{ width: "40%", display: "inline-block" }}
                            >
                              <DynamicField
                                key={
                                  props.values.examenRadiologique[
                                    "mamoTailleSecond"
                                  ].name
                                }
                                field={
                                  props.values.examenRadiologique[
                                    `${"mamoTailleSecond"}`
                                  ]
                                }
                                onUpdate={props.onUpdate}
                                fieldFamily="examenRadiologique"
                              />
                            </div>
                          </>
                        ) : null}
                        {props[k] === "Anormal" ||
                        props.values.examenRadiologique[k].value ===
                          "Anormal" ? (
                          <>
                            <DynamicField
                              key={props.values.examenRadiologique[k].name}
                              field={props.values.examenRadiologique[`${k}Rep`]}
                              onUpdate={props.onUpdate}
                              fieldFamily="examenRadiologique"
                            />
                          </>
                        ) : null}
                      </>
                    );
                  })}
                </div>
              </div>
            </Card>
          )}
        </>
      ) : (
        <>
          {(props.visit === "j0" ||
            props.visit === "j4" ||
            props.visit === "j3") && (
            <Card title="Examen radiologique" className="add-antecedent-card">
              <div style={{ marginLeft: "4%" }}>
                <div>
                  {[
                    "Mammographie",
                    "thoraco",
                    "scintigraphie",
                    "echographie",
                  ].map((k, indice) => {
                    return (
                      <>
                        <h1>{ExamenRadioTitle[indice]}</h1>
                        <DynamicField
                          key={k}
                          field={props.values.examenRadiologique[k]}
                          onUpdate={props.onUpdate}
                          fieldFamily="examenRadiologique"
                          radio={false}
                        />
                        {props.values.examenRadiologique["Mammographie"]
                          .value === "Oui" && k === "Mammographie" ? (
                          <>
                            <h1>Taille de l'opacité</h1>
                            <div
                              style={{ width: "40%", display: "inline-block" }}
                            >
                              <DynamicField
                                key={
                                  props.values.examenRadiologique["mamoTaille"]
                                    ? props.values.examenRadiologique[
                                        "mamoTaille"
                                      ].name
                                    : "mamoTaille"
                                }
                                field={
                                  props.values.examenRadiologique[
                                    `${"mamoTaille"}`
                                  ]
                                }
                                onUpdate={props.onUpdate}
                                fieldFamily="examenRadiologique"
                              />
                            </div>
                            <div
                              style={{ width: "40%", display: "inline-block" }}
                            >
                              <DynamicField
                                key={
                                  props.values.examenRadiologique[
                                    "mamoTailleSecond"
                                  ]
                                    ? props.values.examenRadiologique[
                                        "mamoTailleSecond"
                                      ].name
                                    : "mamoTailleSecond"
                                }
                                field={
                                  props.values.examenRadiologique[
                                    `${"mamoTailleSecond"}`
                                  ]
                                }
                                onUpdate={props.onUpdate}
                                fieldFamily="examenRadiologique"
                              />
                            </div>
                          </>
                        ) : null}
                        {props.values.examenRadiologique[k].value ===
                        "Anormal" ? (
                          <>
                            <DynamicField
                              key={props.values.examenRadiologique[k].name}
                              field={props.values.examenRadiologique[`${k}Rep`]}
                              onUpdate={props.onUpdate}
                              fieldFamily="examenRadiologique"
                            />
                          </>
                        ) : null}
                      </>
                    );
                  })}
                </div>
              </div>
            </Card>
          )}
        </>
      )}
    </Form>
  );
};
ExamenRadiologique.propTypes = {
  form: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
};

ExamenRadiologique.defaultProps = {
  onUpdate: () => {},
};

export default ExamenRadiologique;

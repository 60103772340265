import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Form,
  Radio,
  Descriptions,
  Typography,
  TimePicker,
  DatePicker,
} from "antd";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import moment from "moment";
import {
  setVisitsList,
  getVisitsByPatientAsync,
} from "../../../redux/visits/actions/index";
import FORM_VALIDATORS from "../../../helpers/formRules";
import "./drugStyles.css";
import DynamicField from "../../../components/DynamicField";
const { Title } = Typography;

const drugForm = ({
  form,
  visit,
  patient,
  visitsList,
  getVisitsByPatientAsync,
  setVisitsList,
  values,
  onUpdate,
}) => {
  const [medoc, setMedoc] = useState("");
  //const medicamentToTake = parseInt(patient.customId.split('-')[2]);
  useEffect(() => {
    if (visit === "j7") {
      getVisitsByPatientAsync(patient._id);
    }
    return () => {
      setVisitsList([]);
    };
  }, [patient]);

  // useEffect(() => {
  //   visitsList.forEach((e) => {
  //       e.visitNumber === "j0" && setMedoc(e.administratedDrug.value);
  //   });
  // },[visitsList]);

  // console.log("med",medoc)

  return (
    <Form style={{ marginTop: 30, minWidth: "900px" }} form={form}>
      <div>
        {visit === "j0" || visit === "j7"
          ? [
              "dateNextVisit",
            ].map((k) => {
              return (
                values[k] && (
                  <DynamicField
                    key={k}
                    field={values[k]}
                    onUpdate={onUpdate}
                    fieldFamily="visitInformations"
                    //radio={true}
                  />
                )
              );
            })
          : [ "dateNextVisit"].map((k) => {
              return (
                values[k] && (
                  <DynamicField
                    key={k}
                    field={values[k]}
                    onUpdate={onUpdate}
                    fieldFamily="visitInformations"
                    //radio={true}
                  />
                )
              );
            })}

        {/* <Descriptions>
          <Descriptions.Item>Date de la visite :</Descriptions.Item>
          <Form.Item
            style={{ width: 200 }}
            className="m-right-medium"
            name="dateNextVisitV1"
            rules={[FORM_VALIDATORS.required]}
          >
            <DatePicker
              onChange={(date) => {
                form.setFieldsValue({
                  dateNextVisitV1: date
                    ? moment(date.toDate()).format("L")
                    : null,
                });
              }}
              defaultValue={moment(form.getFieldValue("dateNextVisitV1").toDate()).format("L")}
              format="DD/MM/YYYY"
            />
          </Form.Item>
        </Descriptions> */}
      </div>
    </Form>
    // <Form style={{ marginTop: 30 }} form={form}>
    //   <div
    //     className="flex"
    //     style={{ alignItems: "baseline", flexDirection: "column" }}
    //     id="drugForm"
    //   >
    //     <Descriptions>
    //       <Descriptions.Item>Date de la visite :</Descriptions.Item>
    //       <Form.Item
    //         style={{ width: 200 }}
    //         className="m-right-medium"
    //         name="dateNextVisitV1"
    //         rules={[FORM_VALIDATORS.required]}
    //       >
    //         <DatePicker
    //           onChange={(date) => {
    //             form.setFieldsValue({
    //               dateNextVisitV1: date
    //                 ? moment(date.toDate()).format("L")
    //                 : null,
    //             });
    //           }}
    //           defaultValue={
    //             form.getFieldValue({
    //               dateNextVisitV1: moment().format("L")
    //             })
    //           }
    //           format="DD/MM/YYYY"
    //         />
    //       </Form.Item>
    //     </Descriptions>
    //     <Descriptions>
    //       <Descriptions.Item>Heure de la visite :</Descriptions.Item>
    //       <Form.Item
    //         style={{ width: 200 }}
    //         className="m-right-medium"
    //         name="dateNextVisitV1Heure"
    //         rules={[FORM_VALIDATORS.required]}
    //       >
    //         <TimePicker
    //           onChange={(time) => {
    //             console.log("time picker change", time.toDate());
    //             form.setFieldsValue({
    //               dateNextVisitV1Heure: time
    //                 ? moment(time.toDate()).format("HH:mm")
    //                 : null,
    //             });
    //           }}
    //           format="HH:mm"
    //         />
    //       </Form.Item>
    //     </Descriptions>
    //     {visit === "j0" && (
    //       <>
    //         <Descriptions>
    //           <Descriptions.Item>
    //             Date de signature du consentement (jj/mm/aaaa) :
    //           </Descriptions.Item>
    //           <Form.Item
    //             style={{ width: 200 }}
    //             className="m-right-medium"
    //             name="consentDate"
    //             rules={[FORM_VALIDATORS.required]}
    //           >
    //             <DatePicker
    //               onChange={(date) => {
    //                 form.setFieldsValue({
    //                   consentDate: date
    //                     ? moment(date.toDate()).format("L")
    //                     : null,
    //                 });
    //               }}
    //               format="DD/MM/YYYY"
    //             />
    //           </Form.Item>
    //         </Descriptions>
    //         <Descriptions>
    //           <Descriptions.Item>
    //             Date d'inclusion (jj/mm/aaaa) :
    //           </Descriptions.Item>
    //           <Form.Item
    //             style={{ width: 200 }}
    //             className="m-right-medium"
    //             name="inclusionDate"
    //             rules={[FORM_VALIDATORS.required]}
    //           >
    //             <DatePicker
    //               onChange={(date) => {
    //                 form.setFieldsValue({
    //                   inclusionDate: date
    //                     ? moment(date.toDate()).format("L")
    //                     : null,
    //                 });
    //               }}
    //               format="DD/MM/YYYY"
    //             />
    //           </Form.Item>
    //         </Descriptions>
    //       </>
    //     )}
    //     {visit !== "j0" && (
    //       <>
    //         <Title level={5}>Date de la prochaine visite</Title>
    //         <DynamicField
    //           key={"dateVisite"}
    //           field={values["dateVisite"]}
    //           onUpdate={onUpdate}
    //           fieldFamily="general"
    //         />
    //       </>
    //     )}
    //     {/* <Descriptions>
    //       <Descriptions.Item>Heure de l'échocardiographie chez le cardiologue au centre investigateur :</Descriptions.Item>
    //       <Form.Item
    //         style={{ width: 200 }}
    //         className="m-right-medium"
    //         name="dateNextRDVCardioHeure"
    //         rules={[FORM_VALIDATORS.required]}
    //       >
    //         <TimePicker
    //           onChange={(time) => {
    //             console.log("time picker change", time.toDate());
    //             form.setFieldsValue({
    //               dateNextRDVCardioHeure: time
    //                 ? moment(time.toDate()).format("HH:mm")
    //                 : null,
    //             });
    //           }}
    //           format="HH:mm"
    //         />
    //       </Form.Item>
    //     </Descriptions> */}
    //   </div>
    // </Form>
  );
};

drugForm.propTypes = {
  form: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  setVisitsList: PropTypes.func.isRequired,
  getVisitsByPatientAsync: PropTypes.func.isRequired,
  visitsList: PropTypes.object.isRequired,
};

const stateToProps = (state) => ({
  visitsList: state.visits.visitsList,
});

const dispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getVisitsByPatientAsync,
      setVisitsList,
    },
    dispatch
  );

export default connect(stateToProps, dispatchToProps)(drugForm);

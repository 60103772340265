import React from "react";
import PropTypes from "prop-types";
import { Form, Card, Typography, Divider } from "antd";
import DynamicField from "../DynamicField";

const ClassificationTNM = (props) => {
  return (
    <Form
      style={{ marginTop: 30 }}
      form={props.form}
      className="biological-examination-form"
    >
      {props.isAdd ? (
        <>
          {(props.visit === "j0" || props.visit === "j2") && (
            <Card title="Classification TNM" className="add-antecedent-card">
              <div style={{ marginLeft: "4%" }}>
                <div>
                  {["T", "N", "MNUM"].map((k) => (
                    <DynamicField
                      key={k}
                      field={props.values.classificationTNM[k]}
                      onUpdate={props.onUpdate}
                      fieldFamily="classificationTNM"
                    />
                  ))}
                </div>
              </div>
            </Card>
          )}
        </>
      ) : (
        <>
          {(props.visit === "j0" || props.visit === "j2") && (
            <Card title="Classification TNM" className="add-antecedent-card">
              <div style={{ marginLeft: "4%" }}>
                <div>
                  {["T", "N", "MNUM"].map((k) => (
                    <DynamicField
                      key={k}
                      field={props.values.classificationTNM[k]}
                      onUpdate={props.onUpdate}
                      fieldFamily="classificationTNM"
                    />
                  ))}
                </div>
              </div>
            </Card>
          )}
        </>
      )}
    </Form>
  );
};
ClassificationTNM.propTypes = {
  form: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
};

ClassificationTNM.defaultProps = {
  onUpdate: () => {},
};

export default ClassificationTNM;

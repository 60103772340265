export default {
  dateNextVisit: {
    name: "dateNextVisit",
    label: " Date de la prochaine visite",
    rules: ["required", "validDate"],
    type: "input",
    inputStyle: { width: "min-content" },
    placeholder: "e.g: 01/01/2000",
    strongLabel: true,
  },
  dateVisite: {
    name: "dateVisite",
    label: "Date de la visite :",
    rules: ["validDate", "futurDate","required"],
    type: "input",
    inputStyle: { width: "min-content" },
    placeholder: "e.g: 01/01/2000",
    strongLabel: true,
  },
  dateNextVisitHeure: {
    name: "dateNextVisitHeure",
    label: "Heure de la visite :",
    type: "timepicker",
    rules: ["required"],
    strongLabel: true,
  },
  finHeureVdeux: {
    name: "finHeureVdeux",
    label: "Heure de fin de la visite V2 :",
    rules: ["required"],
    type: "timepicker",
    strongLabel: true,
  },

  traitements: {
    // ICD: {
    ICD: {
      name: "ICD",
      label: "Pace maker ou autre implant",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    ICDdateDebut: {
      name: "ICDdateDebut",
      label: "Date début",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    ICDdateFin: {
      name: "ICDdateFin",
      label: "Date fin",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // BB: {
    BB: {
      name: "BB",
      label: "Bêta-bloquant",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    BBdateDebut: {
      name: "BBdateDebut",
      label: "Date début",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    BBdateFin: {
      name: "BBdateFin",
      label: "Date fin",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // ACE: {
    ACE: {
      name: "ACE",
      label: "inhibiteur de l'enzyme de conversion de l'angiotensine (ACEi)",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    ACEdateDebut: {
      name: "ACEdateDebut",
      label: "Date début",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    ACEdateFin: {
      name: "ACEdateFin",
      label: "Date fin",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // ARB: {

    // },
    // ARN: {

    // },
    // ARM: {

    // },
    // IV: {
    // },
    // SGL: {
    // },
    // DA: {
    // },
    // DTH: {
    // },
    // ST: {
    // },
    // TA: {
    // },
  },
  antecedents: {
    HA: {
      name: "HA",
      label: "Hypertension artérielle",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    HAdateDebut: {
      name: "HAdateDebut",
      label: "Date début",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    HAdateFin: {
      name: "HAdateFin",
      label: "Date fin",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    DIABETE: {
      name: "DIABETE",
      label: "Diabète",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    DiabetedateDebut: {
      name: "DiabetedateDebut",
      label: "Date début",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    DiabetedateFin: {
      name: "DiabetedateFin",
      label: "Date fin",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    MC: {
      name: "MC",
      label: "Maladie cardiaque",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    MCdateDebut: {
      name: "MCdateDebut",
      label: "Date début",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    MCdateFin: {
      name: "MCdateFin",
      label: "Date fin",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    MR: {
      name: "MR",
      label: "Maladie rénale",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    MRdateDebut: {
      name: "MRdateDebut",
      label: "Date début",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    MRdateFin: {
      name: "MRdateFin",
      label: "Date fin",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    MF: {
      name: "MF",
      label: "Maladie du foie",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    MFdateDebut: {
      name: "MFdateDebut",
      label: "Date début",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    MFdateFin: {
      name: "MFdateFin",
      label: "Date fin",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },

    // IDM: {
    // },
    // PCI: {
    // },
    // },
    // },
    // ICP: {
    // },
    // },
    // AVC: {
    // },
    // MVAP: {
    // },
    // DIABETE: {
    DIABETE: {
      name: "DIABETE",
      label: "Diabéte",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    type: {
      name: "type",
      label: "Si oui",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Type I", value: "Type I" },
        { label: "Type II", value: "Type II" },
      ],
    },
    DIABETEdateDebut: {
      name: "DIABETEdateDebut",
      label: "Date début",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    DIABETEdateFin: {
      name: "DIABETEdateFin",
      label: "Date fin",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // HA: {

    // },
    // CANCER: {
    // },
    // BPCO: {
    // },
    // MRC: {
    MRC: {
      name: "MRC",
      label: "Maladie rénale chronique",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    MRCdateDebut: {
      name: "CANCERdateDebut",
      label: "Date début",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    MRCdateFin: {
      name: "CANCERdateFin",
      label: "Date fin",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // MDF: {
    MDF: {
      name: "MDF",
      label: "Maladie du foie",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    MDFdateDebut: {
      name: "MDFdateDebut",
      label: "Date début",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    MDFdateFin: {
      name: "MDFdateFin",
      label: "Date fin",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // DEPRESSION: {
    DEPRESSION: {
      name: "DEPRESSION",
      label: "Dépression",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    DEPRESSIONdateDebut: {
      name: "DEPRESSIONdateDebut",
      label: "Date début",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    DEPRESSIONdateFin: {
      name: "DEPRESSIONdateFin",
      label: "Date fin",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // DT: {
    DT: {
      name: "DT",
      label: "Dysfonctionnement thyroïdien",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    DTdateDebut: {
      name: "DTdateDebut",
      label: "Date début",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    DTdateFin: {
      name: "DTdateFin",
      label: "Date fin",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
  },

  consentDate: {
    name: "consentDate",
    label: "Date de signature du consentement (jj/mm/aaaa)",
    rules: ["validDate", "futurDate","required"],
    type: "input",
    inputStyle: { width: "min-content" },
    placeholder: "e.g: 01/01/2000",
    strongLabel: true,
  },
  inclusionDate: {
    name: "inclusionDate",
    label: `Date d'inclusion (jj/mm/aaaa)`,
    rules: ["validDate", "futurDate"],
    type: "input",
    inputStyle: { width: "min-content" },
    placeholder: "e.g: 01/01/2000",
    strongLabel: true,
  },
  consultationDate: {
    name: "consultationDate",
    label: "Date de la consultation",
    type: "datetime",
    rules: ["required"],
    strongLabel: true,
  },
  admissionDate: {
    name: "admissionDate",
    label: "Date d'admission",
    rules: ["required"],
    type: "datetime",
    strongLabel: true,
  },
  systolique: {
    name: "systolique",
    rules: ["required"],
    type: "inputNumber",
    label: "Systolique",
    placeholder: "TA systolique",
    unity: "mmHg",
    strongLabel: true,
  },
  diastolique: {
    name: "diastolique",
    rules: ["required"],
    type: "inputNumber",
    label: "Diastolique",
    placeholder: "TA diastolique",
    unity: "mmHg",
    strongLabel: true,
  },
  // systoliqueRight: {
  //   name: "systoliqueRight",
  //   rules: ["required"],
  //   type: "inputNumber",
  //   label: "PAS membre droite",
  //   placeholder: "TA systolique",
  //   strongLabel: true,
  // },
  // diastoliqueRight: {
  //   name: "diastoliqueRight",
  //   rules: ["required"],
  //   type: "inputNumber",
  //   label: "PAD membre droite",
  //   placeholder: "TA diastolique",
  //   strongLabel: true,
  // },
  bodyTemperature: {
    name: "bodyTemperature",
    rules: ["required"],
    type: "inputNumber",
    label: "Température (°C)",
    strongLabel: true,
  },
  adminstratedDate: {
    name: "adminstratedDate",
    label: "Date d'administration",
    type: "datetime",
    rules: ["required"],
    strongLabel: true,
  },
  administratedHour: {
    name: "administratedHour",
    rules: ["required"],
    type: "timepicker",
    label: "Heure d'administration",
    strongLabel: true,
  },
  inclusion: {
    age: {
      name: "age",
      label: "Âge ≥ 18 ans",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    cps: {
      name: "cps",
      label: "Consentement du Patient signé",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    phcs: {
      name: "phcs",
      label: `Preuve histologique d'un carcinome du sein.`,
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    cla: {
      name: "cla",
      label: `Carcinome localement avancé (stade III non opérable) ou métastasé (stade
        IV), dont les métastases ont été diagnostiquées d'emblée ou après traitement
        adjuvant ou néoadjuvant.`,
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    tmm: {
      name: "tmm",
      label: `Pas de traitement pour la maladie métastatique.`,
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    hmm: {
      name: "hmm",
      label: `Maladie métastasée : preuve histologique au niveau de la métastase.`,
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    prts: {
      name: "prts",
      label: `Patientes qui vont recevoir un traitement systémique (chimiothérapie,
        thérapie ciblée, hormonothérapie).`,
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    evs: {
      name: "evs",
      label: `Espérance de vie supérieure à 3 mois`,
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
  },
  exclusion: {
    anotherTrial: {
      name: "anotherTrial",
      label: "Participation dans une autre étude clinique",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    cancerSein: {
      name: "cancerSein",
      label: "Cancers du sein non-épithéliaux",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    mnb: {
      name: "mnb",
      label: "Métastases non biopsiables",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    ptsmm: {
      name: "ptsmm",
      label: `Patients ayant reçu un traitement systémique pour la maladie métastatique
      (chimiothérapie, thérapie ciblée, hormonothérapie), au préalable`,
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    pcic: {
      name: "pcic",
      label: `Patientes ayant une contre-indication à la chimiothérapie, et/ou à
      l'hormonothérapie, et/ou à une thérapie ciblée.`,
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    pet: {
      name: "pet",
      label: `Patientes étrangères.`,
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    // pharmaceuticals: {
    //     name: 'pharmaceuticals',
    //     label: 'Prise concomitante de médicaments',
    //     strongLabel: true,
    //     style: { maxWidth: '80%', margin: 'auto' },
    //     rules: [''],
    //     type: 'radio',
    //     radioFields: [
    //         { label: 'Oui', value: 'Oui' },
    //         { label: 'Non', value: 'Non' },
    //     ],
    // },
    // chronicalDisease: {
    //     name: 'chronicalDisease',
    //     label:
    // 'Volontaires présentant des signes cliniques de maladies aiguës ou porteurs de maladies chroniques',
    //     strongLabel: true,
    //     style: { maxWidth: '80%', margin: 'auto' },
    //     rules: [''],
    //     type: 'radio',
    //     radioFields: [
    //         { label: 'Oui', value: 'Oui' },
    //         { label: 'Non', value: 'Non' },
    //     ],
    // },
    // allergy: {
    //     name: 'allergy',
    //     label:
    // "Allergies connues à la lévocétirizine ou à d'autres dérivés de la pipérazine",
    //     strongLabel: true,
    //     style: { maxWidth: '80%', margin: 'auto' },
    //     rules: [''],
    //     type: 'radio',
    //     radioFields: [
    //         { label: 'Oui', value: 'Oui' },
    //         { label: 'Non', value: 'Non' },
    //     ],
    // },
    // anomalyECG: {
    //     name: 'anomalyECG',
    //     label: "Une anomalie de l'ECG à 12 dérivations",
    //     strongLabel: true,
    //     style: { maxWidth: '80%', margin: 'auto' },
    //     rules: [''],
    //     type: 'radio',
    //     radioFields: [
    //         { label: 'Oui', value: 'Oui' },
    //         { label: 'Non', value: 'Non' },
    //     ],
    // },
    // HTA: {
    //     name: 'HTA',
    //     label:
    // 'Patients hypertendus (tension artérielle systolique en décubitus dorsal supérieure ou égale à 140 mmHg ou tension artérielle diastolique en décubitus dorsal supérieure ou égale à 90 mmHg)',
    //     strongLabel: true,
    //     style: { maxWidth: '80%', margin: 'auto' },
    //     rules: [''],
    //     type: 'radio',
    //     radioFields: [
    //         { label: 'Oui', value: 'Oui' },
    //         { label: 'Non', value: 'Non' },
    //     ],
    // },
    // antecedents: {
    //     name: 'antecedents',
    //     label:
    // "Antécédents de troubles cardiovasculaires, respiratoires, hépatiques, rénaux, gastro-intestinaux, endocriniens, hématologiques, psychiatriques ou neurologiques susceptibles d'altérer significativement l'absorption, le métabolisme ou l'élimination des médicaments ou de constituer un risque lors de la prise du médicament à l'étude ou d’interférer avec l'interprétation des données",
    //     strongLabel: true,
    //     style: { maxWidth: '80%', margin: 'auto' },
    //     rules: [''],
    //     type: 'radio',
    //     radioFields: [
    //         { label: 'Oui', value: 'Oui' },
    //         { label: 'Non', value: 'Non' },
    //     ],
    // },
    // drugs: {
    //     name: 'drugs',
    //     label: 'Consommation régulière des drogues ou des stupéfiants',
    //     strongLabel: true,
    //     style: { maxWidth: '80%', margin: 'auto' },
    //     rules: [''],
    //     type: 'radio',
    //     radioFields: [
    //         { label: 'Oui', value: 'Oui' },
    //         { label: 'Non', value: 'Non' },
    //     ],
    // },
    // usageDuringTrial: {
    //     name: 'usageDuringTrial',
    //     label:
    // "Utilisation ou l'intention d'utiliser des médicaments 7 et 14 jours respectivement avant l'administration",
    //     strongLabel: true,
    //     style: { maxWidth: '80%', margin: 'auto' },
    //     rules: [''],
    //     type: 'radio',
    //     radioFields: [
    //         { label: 'Oui', value: 'Oui' },
    //         { label: 'Non', value: 'Non' },
    //     ],
    // },
    // VIH: {
    //     name: 'VIH',
    //     label: 'Volontaires avec tests positifs au VIH, hépatite B ou C',
    //     strongLabel: true,
    //     style: { maxWidth: '80%', margin: 'auto' },
    //     rules: [''],
    //     type: 'radio',
    //     radioFields: [
    //         { label: 'Oui', value: 'Oui' },
    //         { label: 'Non', value: 'Non' },
    //     ],
    // },
    // pregnant: {
    //     name: 'pregnant',
    //     label: 'Femme enceinte ou allaitante',
    //     strongLabel: true,
    //     style: { maxWidth: '80%', margin: 'auto' },
    //     rules: [''],
    //     type: 'radio',
    //     radioFields: [
    //         { label: 'Oui', value: 'Oui' },
    //         { label: 'Non', value: 'Non' },
    //     ],
    // },
  },
  examenClinique:{
    //Examen clinique
    IndiceKarnowsky: {
      name: "IndiceKarnowsky",
      label: "Indice Karnowsky :",
      rules: ["required"],
      type: "inputNumber",
      strongLabel: true,
      unity: "%",
    },
    ScoreOMS: {
      name: "ScoreOMS",
      label: "Score OMS",
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "0", value: "0" },
        { label: "1", value: "1" },
        { label: "2", value: "2" },
      ],
      strongLabel: true,
    },

    symétrie: {
      name: "symétrie",
      label: "Symétrie :",
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
      strongLabel: true,
    },

    Peau: {
      name: "Peau",
      label: "Peau :",
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "aucun", value: "aucun" },
        { label: "rougeâtre", value: "rougeâtre" },
        { label: "peau d’orange", value: "peau d’orange" },
        { label: "plis", value: "plis" },
        { label: "epaississement,", value: "epaississement" },
        { label: "croutes", value: "croutes" },
        { label: "rétraction,", value: "rétraction" },
      ],
      strongLabel: true,
    },
    tumeurTaille: {
      name: "tumeurTaille",
      label: "Largeur  :",
      rules: ["required"],
      type: "inputNumber",
      strongLabel: true,
      unity: "mm",
    },
    tumeurTailleSecond: {
      name: "tumeurTailleSecond",
      label: "longueur",
      rules: ["required"],
      type: "inputNumber",
      strongLabel: true,
      unity: "mm",
    },
    écoulement: {
      name: "écoulement",
      label: "Présence d’écoulement",
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
      strongLabel: true,
    },
    ganglionnaires: {
      name: "ganglionnaires",
      label: "",
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Normal", value: "Normal" },
        { label: "Anormal", value: "Anormal" },
      ],
      strongLabel: true,
    },
    ganglionnairesRep: {
      name: "ganglionnairesRep",
      label: "préciser :",
      rules: ["required"],
      type: "input",
      strongLabel: true,
    },

    ganglionnairesSus: {
      name: "ganglionnairesSus",
      label: "",
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Normal", value: "Normal" },
        { label: "Anormal", value: "Anormal" },
      ],
      strongLabel: true,
    },
    ganglionnairesSusRep: {
      name: "ganglionnairesSusRep",
      label: "préciser :",
      rules: ["required"],
      type: "input",
      strongLabel: true,
    },

    abdominal: {
      name: "abdominal",
      label: "",
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Normal", value: "Normal" },
        { label: "Anormal", value: "Anormal" },
      ],
      strongLabel: true,
    },
    abdominalRep: {
      name: "abdominalRep",
      label: "préciser :",
      rules: ["required"],
      type: "input",
      strongLabel: true,
    },

    auscultation: {
      name: "auscultation",
      label: "",
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Normal", value: "Normal" },
        { label: "Anormal", value: "Anormal" },
      ],
      strongLabel: true,
    },
    auscultationRep: {
      name: "auscultationRep",
      label: "préciser :",
      rules: ["required"],
      type: "input",
      strongLabel: true,
    },
    squelette: {
      name: "squelette",
      label: "",
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Normal", value: "Normal" },
        { label: "Anormal", value: "Anormal" },
      ],
      strongLabel: true,
    },
    squeletteRep: {
      name: "squeletteRep",
      label: "préciser :",
      rules: ["required"],
      type: "input",
      strongLabel: true,
    },
  },
  examenRadiologique:{
        //Examen radiologique
        mamoTaille: {
          name: "mamoTaille",
          label: "longueur",
          rules: [],
          type: "inputNumber",
          strongLabel: true,
          unity: "mm",
        },
        mamoTailleSecond: {
          name: "mamoTailleSecond",
          label: "Largeur",
          rules: [],
          type: "inputNumber",
          strongLabel: true,
          unity: "mm",
        },
        thoraco: {
          name: "thoraco",
          label: "",
          rules: [],
          type: "radio",
          radioFields: [
            { label: "Normal", value: "Normal" },
            { label: "Anormal", value: "Anormal" },
          ],
          strongLabel: true,
        },
        thoracoRep: {
          name: "thoracoRep",
          label: "préciser :",
          rules: [],
          type: "input",
          strongLabel: true,
        },
        scintigraphie: {
          name: "scintigraphie",
          label: "",
          rules: [],
          type: "radio",
          radioFields: [
            { label: "Normal", value: "Normal" },
            { label: "Anormal", value: "Anormal" },
          ],
          strongLabel: true,
        },
        scintigraphieRep: {
          name: "scintigraphieRep",
          label: "préciser :",
          rules: [],
          type: "input",
          strongLabel: true,
        },
        echographie: {
          name: "echographie",
          label: "",
          rules: [],
          type: "radio",
          radioFields: [
            { label: "Normal", value: "Normal" },
            { label: "Anormal", value: "Anormal" },
          ],
          strongLabel: true,
        },
        Mammographie: {
          name: "Mammographie",
          label: "Présence de ganglions axillaires",
          rules: [],
          type: "radio",
          radioFields: [
            { label: "Oui", value: "Oui" },
            { label: "Non", value: "Non" },
          ],
          strongLabel: true,
        },
        echographieRep: {
          name: "echographieRep",
          label: "préciser FEVG :",
          rules: [],
          type: "inputNumber",
          strongLabel: true,
          unity: "%",
        },
  },
  classificationTNM:{
        //Classification TNM
        T: {
          name: "T",
          label: "T",
          rules: [],
          type: "radio",
          radioFields: [
            { label: "TX", value: "TX" },
            { label: "T1", value: "T1" },
            { label: "T2", value: "T2" },
            { label: "T3", value: "T3" },
            { label: "T4", value: "T4" },
          ],
          strongLabel: true,
        },
        N: {
          name: "N",
          label: "N",
          rules: [],
          type: "radio",
          radioFields: [
            { label: "Nx", value: "Nx" },
            { label: "N0", value: "N0" },
            { label: "N1", value: "N1" },
            { label: "N2", value: "N2" },
            { label: "N3", value: "N3" },
          ],
          strongLabel: true,
        },
        MNUM: {
          name: "MNUM",
          label: "M",
          rules: [],
          type: "radio",
          radioFields: [
            { label: "M0", value: "M0" },
            { label: "M1", value: "M1" },
          ],
          strongLabel: true,
        },
  },
  examenHistologique:{
    //Examen Histologique
    RAO: {
      name: "RAO",
      label: "Récepteurs aux œstrogènes",
      rules: [],
      type: "radio",
      radioFields: [
        { label: "Positif", value: "Positif" },
        { label: "Négatif", value: "Négatif" },
        { label: "NA", value: "NA" },
      ],
      strongLabel: true,
    },
    SBR: {
      name: "SBR",
      label: "Grade de Scarff-Bloom et Richardson (SBR)",
      rules: [],
      type: "radio",
      radioFields: [
        { label: "grade I", value: "grade I" },
        { label: "grade II", value: "grade II" },
        { label: "grade III", value: "grade III" },
      ],
      strongLabel: true,
    },
    NOMBiopsie: {
      name: "NOMBiopsie",
      label:
        "Nom du laboratoire",
      rules: [],
      type: "input",
      strongLabel: true,
    },
    NumBiopsie: {
      name: "NumBiopsie",
      label: "Numéro",
      rules: [],
      type: "input",
      strongLabel: true,
    },
    typeHisto: {
      name: "typeHisto",
      label: "Type histologique",
      rules: [],
      type: "input",
      strongLabel: true,
    },
    Rprog: {
      name: "Rprog",
      label: "Récepteurs à la progestérone",
      rules: [],
      type: "radio",
      radioFields: [
        { label: "Positif", value: "Positif" },
        { label: "Négatif", value: "Négatif" },
        { label: "NA", value: "NA" },
      ],
      strongLabel: true,
    },
    Her2Neu: {
      name: "Her2Neu",
      label: "Her2Neu",
      rules: [],
      type: "radio",
      radioFields: [
        { label: "Score 0-1", value: "Score 0-1" },
        { label: "Score 2", value: "Score 2" },
        { label: "Score 3", value: "Score 3" },
      ],
      strongLabel: true,
    },
    Her2NeuCheck: {
      name: "Her2NeuCheck",
      label: "Her2Neu si score = 2",
      rules: [],
      type: "radio",
      radioFields: [
        { label: "FISH +", value: "FISH +" },
        { label: "FISH –", value: "FISH –" },
      ],
      strongLabel: true,
    },
    Ki67: {
      name: "Ki67",
      label: "Ki67 :",
      rules: [],
      type: "inputNumber",
      strongLabel: true,
      unity: "%",
    },
    PDL1: {
      name: "PDL1",
      label: "PD-L1 :",
      rules: [],
      type: "inputNumber",
      strongLabel: true,
      unity: "%",
    },
    MMR: {
      name: "MMR",
      label: "MMR :",
      rules: [],
      type: "inputNumber",
      strongLabel: true,
      unity: "%",
    },
    TILs: {
      name: "TILs",
      label: "TILs :",
      rules: [],
      type: "inputNumber",
      strongLabel: true,
      unity: "%",
    },
  },
  examenMolelculaire: {
    //Examen moléculaire
    ADNPCR: {
      name: "ADNPCR",
      label: "ADN tumoral Circulant par PCR digitale",
      rules: [],
      type: "radio",
      radioFields: [
        { label: "Présence", value: "Présence" },
        { label: "Absence", value: "Absence" },
      ],
      strongLabel: true,
    },
    MSIPCR: {
      name: "MSIPCR",
      label: "Statut MSI par PCR pentaplex et électrophorèse capillaire",
      rules: [],
      type: "radio",
      radioFields: [
        { label: "Présence des 5 gènes", value: "Présence des 5 gènes" },
        { label: "Absence des 5 gènes", value: "Absence des 5 gènes" },
      ],
      strongLabel: true,
    },
    TMBNGS: {
      name: "TMBNGS",
      label: "Charge mutationnelle TMB par NGS :",
      rules: [],
      type: "inputNumber",
      strongLabel: true,
      unity: "%",
    },
    MSIPARPCR: {
      name: "MSIPARPCR",
      label: "Statut MSI par PCR pentaplex et électrophorèse capillaire",
      rules: [],
      type: "radio",
      radioFields: [
        { label: "Présence", value: "Présence" },
        { label: "Absence", value: "Absence" },
      ],
      strongLabel: true,
    },
    HRD: {
      name: "HRD",
      label: "Marqueur HRD :",
      rules: [],
      type: "radio",
      radioFields: [
        { label: "Présence", value: "Présence" },
        { label: "Absence", value: "Absence" },
      ],
      strongLabel: true,
    },
  },
  antecedentMed: {
    HA: {
      name: "HA",
      label: "Hypertension artérielle",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    HAdateDebut: {
      name: "HAdateDebut",
      label: "Date début",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    HAdateFin: {
      name: "HAdateFin",
      label: "Date fin",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    DIABETE: {
      name: "DIABETE",
      label: "Diabète",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    DIABETEdateDebut: {
      name: "DIABETEdateDebut",
      label: "Date début",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    DIABETEdateFin: {
      name: "DIABETEdateFin",
      label: "Date fin",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    MC: {
      name: "MC",
      label: "Maladie cardiaque",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    MCdateDebut: {
      name: "MCdateDebut",
      label: "Date début",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    MCdateFin: {
      name: "MCdateFin",
      label: "Date fin",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    MR: {
      name: "MR",
      label: "Maladie rénale",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    MRdateDebut: {
      name: "MRdateDebut",
      label: "Date début",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    MRdateFin: {
      name: "MRdateFin",
      label: "Date fin",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    MF: {
      name: "MF",
      label: "Maladie du foie",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    MFdateDebut: {
      name: "MFdateDebut",
      label: "Date début",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    MFdateFin: {
      name: "MFdateFin",
      label: "Date fin",
      rules: [],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
  },
  biologicalExamination: {
    // mlnp

    //Indication chirurgie
    indiceChirurgie: {
      name: "indiceChirurgie",
      label: "Indication chirurgie",
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
      strongLabel: true,
    },
    indiceChirurgieRep: {
      name: "indiceChirurgieRep",
      label: "préciser :",
      rules: ["required"],
      type: "input",
      strongLabel: true,
    },
    //Evaluation radiologique
    echographieMammaire: {
      name: "echographieMammaire",
      label: "Echographie mammaire",
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
      strongLabel: true,
    },
    echographieMammaireTaille: {
      name: "echographieMammaireTaille",
      label: "Largeur",
      rules: ["required"],
      type: "inputNumber",
      strongLabel: true,
      unity: "mm",
    },
    echographieMammaireTailleSecond: {
      name: "echographieMammaireTailleSecond",
      label: "Longueur",
      rules: ["required"],
      type: "inputNumber",
      strongLabel: true,
      unity: "mm",
    },

    MammographieEvaluation: {
      name: "MammographieEvaluation",
      label: "Mammographie",
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
      strongLabel: true,
    },
    MammographieEvaluationTaille: {
      name: "MammographieEvaluationTaille",
      label: "Largeur",
      rules: ["required"],
      type: "inputNumber",
      strongLabel: true,
      unity: "mm",
    },
    
    MammographieEvaluationTailleSecond: {
      name: "MammographieEvaluationTailleSecond",
      label: "Longueur",
      rules: ["required"],
      type: "inputNumber",
      strongLabel: true,
      unity: "mm",
    },

    IRMmammaire: {
      name: "IRMmammaire",
      label: "IRM mammaire",
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
      strongLabel: true,
    },
    IRMmammaireTaille: {
      name: "IRMmammaireTaille",
      label: "Largeur",
      rules: ["required"],
      type: "inputNumber",
      strongLabel: true,
      unity: "mm",
    },
    IRMmammaireTailleSecond: {
      name: "IRMmammaireTailleSecond",
      label: "Longueur",
      rules: ["required"],
      type: "inputNumber",
      strongLabel: true,
      unity: "mm",
    },
    Mammographie: {
      name: "Mammographie",
      label: "Présence de ganglions axillaires",
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
      strongLabel: true,
    },
    Scannerthoraco: {
      name: "Scannerthoraco",
      label: "Si stade métastatique : Scanner thoraco-abdomino-pelvien",
      rules: [ ],
      type: "input",
      strongLabel: true,
    },
    //Marqueurs tumoraux
    ACE: {
      name: "ACE",
      label: "ACE :",
      rules: ["required"],
      type: "inputNumber",
      strongLabel: true,
      unity: "μg/L",
    },
    CA153: {
      name: "CA153",
      label: "CA15-3 :",
      rules: ["required"],
      type: "inputNumber",
      strongLabel: true,
      unity: "U/mL",
    },
    //BILAN hémtologues
    NFS: {
      name: "NFS",
      label: "NFS",
      rules: ["required"],
      type: "inputNumber",
      strongLabel: true,
    },


    //
    HB: {
      name: "HB",
      label: "HB",
      rules: ["required"],
      type: "inputNumber",
      strongLabel: true,
      unity: "g/dl",
    },
    GR: {
      name: "GR",
      label: "Nombre de GR",
      rules: ["required"],
      type: "inputNumber",
      strongLabel: true,
      unity: "Millions par microlitre",
    },
    hématocrite: {
      name: "hématocrite",
      label: "Hématocrite",
      rules: ["required"],
      type: "inputNumber",
      strongLabel: true,
      unity: "%",
    },
    PNP: {
      name: "PNP",
      label: "Polynucléaires neutrophiles",
      rules: ["required"],
      type: "inputNumber",
      strongLabel: true,
      unity: "Millions par microlitre",
    },
    "K+": {
      name: "K+",
      label: "potassium (K+) :",
      rules: [],
      type: "inputNumber",
      strongLabel: true,
      unity: "mmol/L",
    },
    "NA+": {
      name: "NA+",
      label: "Natrémie",
      rules: [],
      type: "inputNumber",
      strongLabel: true,
      unity: "mmol/L",
    },
    "CL-": {
      name: "CL-",
      label: "Cl-",
      rules: [],
      type: "inputNumber",
      strongLabel: true,
      unity: "mmol/L",
    },
    bicar: {
      name: "bicar",
      label: "Bicarbonate",
      rules: [],
      type: "inputNumber",
      strongLabel: true,
      unity: "mmol/L",
    },
    protides: {
      name: "protides",
      label: "Protides",
      rules: [],
      type: "inputNumber",
      strongLabel: true,
      unity: "g/L",
    },
    bilirubine: {
      name: "bilirubine",
      label: "Bilirubine",
      rules: [],
      type: "inputNumber",
      strongLabel: true,
      unity: "mmol/L",
    },
    transmaniases: {
      name: "transmaniases",
      label: "Transaminases",
      rules: [],
      type: "inputNumber",
      strongLabel: true,
      unity: "UI/L",
    },
    gammaGT: {
      name: "gammaGT",
      label: "Gamma-GT",
      rules: [],
      type: "inputNumber",
      strongLabel: true,
      unity: "UI/L",
    },
    phosphatases: {
      name: "phosphatases",
      label: "Phosphatases alcalines",
      rules: [],
      type: "inputNumber",
      strongLabel: true,
      unity: "UI/L",
    },
    Cholesterole: {
      name: "Cholesterole",
      label: "Cholesterol  total",
      rules: [],
      type: "inputNumber",
      strongLabel: true,
      unity: "g/l",
    },
    triglycerides: {
      name: "triglycerides",
      label: "Triglycérides",
      rules: [],
      type: "inputNumber",
      strongLabel: true,
      unity: "g/l",
    },
    acideUrique: {
      name: "acideUrique",
      label: "Acide urique",
      rules: [],
      type: "inputNumber",
      strongLabel: true,
      unity: "mg/l",
    },
    albuminemie: {
      name: "albuminemie",
      label: "Albuminémie",
      rules: [],
      type: "inputNumber",
      strongLabel: true,
      unity: "g/dl",
    },
    GS: {
      name: "GS",
      label: "Groupe sanguin",
      rules: [],
      type: "radio",
      radioFields: [
        { label: "A+", value: "A+" },
        { label: "A-", value: "A-" },
        { label: "B+", value: "B+" },
        { label: "B-", value: "B-" },
        { label: "AB+", value: "AB+" },
        { label: "AB-", value: "AB-" },
        { label: "O+", value: "O+" },
        { label: "O-", value: "O-" },
      ],
      strongLabel: true,
    },
    K: {
      name: "K",
      label: "Potassium (K)",
      rules: ["required"],
      type: "inputNumber",
      palceholder: "[3.50-5.10]",
      strongLabel: true,
      unity: "mmol/L",
    },
    Kdate: {
      name: "Kdate",
      label: "Date calcul potassium (k)",
      rules: ["validDate", "futurDate"],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // NA: {
    NA: {
      name: "NA",
      label: "sodium (Na)",
      rules: ["required"],
      type: "inputNumber",
      palceholder: "[135-145]",
      strongLabel: true,
      unity: "mmol/L",
    },
    // },
    // Ca: {
    Ca: {
      name: "Ca",
      label: "calcium (Ca)",
      rules: ["required"],
      type: "inputNumber",
      placeholder: "[86-102]",
      strongLabel: true,
      unity: "mmol/L",
    },
    // },
    // Mg: {
    Mg: {
      name: "Mg",
      label: "magnésium (Mg)",
      rules: ["required"],
      type: "inputNumber",
      strongLabel: true,
      unity: "mmol/L",
    },
    // },
    // Cl: {
    Cl: {
      name: "Cl",
      label: "chlore (Cl)",
      rules: ["required"],
      type: "inputNumber",
      strongLabel: true,
      unity: "mmol/L",
    },
    // },
    // CO3: {
    // },
    // eGFR: {
    // },
    // cholestérol: {
    // },
    // Glycémie: {
    Glycémie: {
      name: "Glycémie",
      label: "Glycémie",
      rules: [],
      type: "inputNumber",
      unity: "g/L",
      warning: false,
      palceholder: "[0.70-1.10]",
      strongLabel: true,
    },
    // },
    // hemoglobine: {
    hemoglobine: {
      name: "hemoglobine",
      label: "glyquée (HbA1c)",
      rules: ["required"],
      type: "inputNumber",
      unity: "%",
      warning: false,
      // placeholder: '[150-400]',
      strongLabel: true,
    },
    // },
    // hemoglobineHB: {
    // },
    // ProBNP: {
    ProBNP: {
      name: "ProBNP",
      label: "NT-ProBNP",
      rules: ["required"],
      type: "inputNumber",
      unity: "μg/L",
      warning: false,
      // placeholder: '[150-400]',
      strongLabel: true,
    },
    // },
    // Troponine: {
    // },
    leucocytes: {
      name: "leucocytes",
      label: "Leucocytes",
      rules: ["required"],
      type: "inputNumber",
      unity: "Millions par microlitre",
      warning: false,
      // placeholder: '[150-400]',
      strongLabel: true,
    },
    Plaquettes: {
      name: "Plaquettes",
      label: "Plaquettes",
      rules: ["required"],
      type: "inputNumber",
      unity: "Mille par microlitre",
      warning: false,
      placeholder: "[150-400]",
      strongLabel: true,
    },
    VS: {
      name: "VS",
      label: "VS",
      rules: ["required"],
      type: "inputNumber",
      unity: "mm/h",
      strongLabel: true,
    },
    CRP: {
      name: "CRP",
      label: "CRP",
      rules: ["required"],
      type: "inputNumber",
      strongLabel: true,
      unity: "mg/l",
    },
    LDH: {
      name: "LDH",
      label: "LDH",
      rules: ["required"],
      type: "inputNumber",
      strongLabel: true,
      unity: "UI/l",
    },
    IL6: {
      name: "IL6",
      label: "IL6",
      rules: ["required"],
      type: "inputNumber",
      strongLabel: true,
      unity: "pg/ml",
    },
    ASAT: {
      name: "ASAT",
      label: "ASAT",
      rules: ["required"],
      type: "inputNumber",
      unity: "UI/L",
      warning: false,
      placeholder: "[0-32]",
      strongLabel: true,
    },
    ALAT: {
      name: "ALAT",
      label: "ALAT",
      rules: ["required"],
      type: "inputNumber",
      unity: "UI/L",
      warning: false,
      placeholder: "[10-33]",
      strongLabel: true,
    },
    // 'Phosphatase alcaline': {
    //     name: 'Phosphatase alcaline',
    //     label: 'Réserves alcalines',
    //     rules: [''],
    //     type: 'inputNumber',
    //     palceholder: '[35-104]',
    //     strongLabel: true,
    //     unity: 'mmol/L',
    // },
    // 'K+': {
    //     name: 'K+',
    //     label: 'potassium (K) :',
    //     rules: [''],
    //     type: 'inputNumber',
    //     palceholder: '[3.50-5.10]',
    //     strongLabel: true,
    //     unity: 'mmol/L',
    // },
    // 'NA+': {
    //     name: 'NA+',
    //     label: 'NA+',
    //     rules: [''],
    //     type: 'inputNumber',
    //     palceholder: '[135-145]',
    //     strongLabel: true,
    //     unity: 'mmol/L',
    // },
    // "Ca++": {
    //   name: "Ca++",
    //   label: "calcium (Ca):",
    //   rules: ["required"],
    //   type: "inputNumber",
    //   placeholder: "[86-102]",
    //   strongLabel: true,
    //   unity: "mmol/L",
    // },
    Créatinine: {
      name: "Créatinine",
      label: "Créatinine",
      rules: [],
      type: "inputNumber",
      unity: "μmol/l",
      warning: false,
      placeholder: "[5-9]",
      strongLabel: true,
    },
    // Glycémie: {
    //   name: "Glycémie",
    //   label: "Glycémie",
    //   rules: ["required"],
    //   type: "inputNumber",
    //   unity: "g/L",
    //   warning: false,
    //   palceholder: "[0.70-1.10]",
    //   strongLabel: true,
    // },
    Protides: {
      name: "Protides",
      label: "Protides totaux",
      rules: ["required"],
      type: "inputNumber",
      unity: "g/L",
      warning: false,
      palceholder: "[65-80]",
      strongLabel: true,
    },
    calcemie: {
      name: "calcemie",
      label: "Calcémie",
      rules: [],
      type: "inputNumber",
      unity: "mmol/L",
      warning: false,
      palceholder: "[65-80]",
      strongLabel: true,
    },
  },
  radiologicalExamination: {
    thorax: {
      name: "thorax",
      label: "Radio thorax : normale",
      strongLabel: true,
      rules: [],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    ECG: {
      name: "ECG",
      label: "ECG : normal",
      strongLabel: true,
      rules: [],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
  },
  dateInvisigateur: {
    name: "dateInvisigateur",
    label: "Date de la prochaine visite V2 au centre investigateur",
    type: "datetime",
    rules: ["required"],
    strongLabel: true,
  },
  // rapportLVEF: {
  //   name: "rapportLVEF",
  //   label: "Rapport sur les résultats",
  //   strongLabel: true,
  //   //style: { maxWidth: "80%", margin: "auto" },
  //   rules: [],
  //   type: "radio",
  //   radioFields: [{ label: "a une LVEF ≤ 50%", value: "a une LVEF ≤ 50%" }],
  // },
  medicalAntecedents: [],
  hasNoTreatments: {
    name: "hasNoTreatments",
    label: "Le patient:",
    rules: [],
    type: "radio",
    radioFields: [
      { label: "Ne prend aucun traitement", value: true },
      { label: "Prend un ou plusieurs traitements", value: false }
    ],
    strongLabel: true,
  },
  treatments: [],
  medicalAntecedentsProps: {
    name: "medicalAntecedentsProps",
    sdv: false,
    query: false,
    frozen: false,
  },
  treatmentsProps: {
    name: "treatmentsProps",
    sdv: false,
    query: false,
    frozen: false,
  },
};

import React from "react";
import PropTypes from "prop-types";
import { Form, TimePicker, DatePicker, Descriptions } from "antd";
import DynamicField from "../../components/DynamicField";
import moment from "moment";
import FORM_VALIDATORS from "../../helpers/formRules";

const VisitInformations = ({
  form,
  values,
  onUpdate,
  visit,
  transport,
  isAdd,
}) => {
  console.log(form.getFieldValue("dateNextVisitV1"))
  console.log(form.getFieldValue({
    dateNextVisitV1Heure : moment().format("L")
  }))

  return (
    <Form style={{ marginTop: 30, minWidth: "900px" }} form={form}>
      <div>
        {/* {visit === 'j0' || visit === 'j7' 
                ? */}
        {["consentDate", "inclusionDate"].map((k) => {
          return (
            values[k] && (
              <DynamicField
                key={k}
                field={values[k]}
                onUpdate={onUpdate}
                fieldFamily="visitInformations"
                //radio={true}
              />
            )
          );
        })}
                <Descriptions>
          <Descriptions.Item>Date de la visite :</Descriptions.Item>
          <Form.Item
            style={{ width: 200 }}
            className="m-right-medium"
            name="dateNextVisitV1"
            rules={[FORM_VALIDATORS.required]}
          >
            <DatePicker
              onChange={(date) => {
                form.setFieldsValue({
                  dateNextVisitV1: date
                    ? moment(date.toDate()).format("L")
                    : null,
                });
              }}
              defaultValue={
                form.getFieldValue({
                  dateNextVisitV1: moment().format("L")
                })
              }
              format="DD/MM/YYYY"
            />
          </Form.Item>
        </Descriptions>
        <Descriptions>
          <Descriptions.Item>Heure de la visite :</Descriptions.Item>
          <Form.Item
            style={{ width: 200 }}
            className="m-right-medium"
            name="dateNextVisitV1Heure"
            rules={[FORM_VALIDATORS.required]}
          >
            <TimePicker
              onChange={(time) => {
                console.log("time picker change", time.toDate());
                form.setFieldsValue({
                  dateNextVisitV1Heure: time
                    ? moment(time.toDate()).format("HH:mm")
                    : null,
                });
              }}
              format="HH:mm"
            />
          </Form.Item>
        </Descriptions>
        {/* {isAdd ? (
          <DynamicField
            key={"transport"}
            field={values["transport"]}
            onUpdate={onUpdate}
            fieldFamily="visitInformations"
            radio={true}
          />
        ) : (
          <DynamicField
            key={"transport"}
            field={values["transport"]}
            onUpdate={onUpdate}
            fieldFamily="visitInformations"
            //radio={true}
          />
        )}
        {(transport === "Autre" || values.transport.value === "Autre") && (
          <DynamicField
            key={"transportAutre"}
            field={values["transportAutre"]}
            onUpdate={onUpdate}
            fieldFamily="visitInformations"
          />
        )} */}
      </div>
    </Form>
  );
};

VisitInformations.propTypes = {
  form: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
  visit: PropTypes.func.isRequired,
};

VisitInformations.defaultProps = {
  onUpdate: () => {},
};

export default VisitInformations;

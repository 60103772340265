import React from "react";
import PropTypes from "prop-types";
import { Form, Card, Divider, Typography } from "antd";
import DynamicField from "../DynamicField";
import { calculateBMI } from "../../helpers/functions";
import "./styles.css";
import moment from "moment";

const { Text } = Typography;

const Inclusion = ({
  inclusionForm,
  exclusionForm,
  dateVisiteForm,
  values,
  onUpdate,
  isAdd,
  patient,
  dateVisite,
  consentDate,
  checkValidDateConsent
}) => {

  return (
    <div className="inclusionContainer">
      <Form
        style={{ marginTop: 30 }}
        form={dateVisiteForm}
        className="dateVisite-form"
      >
        <DynamicField
          key={"dateVisite"}
          field={values["dateVisite"]}
          onUpdate={onUpdate}
          fieldFamily="dateVisiteInfos"
          radio={isAdd ? true : false}
        />
      </Form>
      <Card title="Critéres d'inclusion" className="add-antecedent-card">
        <Form
          style={{ marginTop: 30 }}
          form={inclusionForm}
          className="inclusion-form"
        >
          {["age", "cps", "phcs", "cla", "tmm", "hmm", "prts", "evs"].map(
            (k) => (
              <>
                <DynamicField
                  key={k}
                  field={values.inclusion[k]}
                  onUpdate={onUpdate}
                  fieldFamily="inclusion"
                  radio={isAdd ? true : false}
                />
                <Divider style={{ margin: "12px 0" }} />
              </>
            )
          )}
        </Form>
      </Card>
      <Card title="Critères de non inclusion" className="add-antecedent-card">
        <Form
          style={{ marginTop: 30 }}
          form={exclusionForm}
          className="inclusion-form"
        >
          {["anotherTrial", "cancerSein", "mnb", "ptsmm", "pcic", "pet"].map(
            (k) => (
              <>
                <DynamicField
                  key={k}
                  field={values.exclusion[k] ? values.exclusion[k] : null}
                  onUpdate={onUpdate}
                  fieldFamily="exclusion"
                  radio={isAdd ? true : false}
                />
                <Divider style={{ margin: "12px 0" }} />
              </>
            )
          )}
        </Form>
      </Card>
      <Form
        style={{ marginTop: 30 }}
        form={dateVisiteForm}
        className="dateVisite-form"
      >
        <Card title="Date du consentement">
          <DynamicField
            key={"consentDate"}
            field={values["consentDate"]}
            onUpdate={onUpdate}
            fieldFamily="dateVisiteInfos"
            radio={isAdd ? true : false}
          />
          { isAdd && (consentDate !== null &&
            dateVisite !== null )&&
            !checkValidDateConsent() && <p style={{ color:"red"}}>La date de consentement doit être ultérieure à la date de naissance du participant et antérieure à sa date de visite. Merci de vérifier la date saisie. 
            </p> }
        </Card>
      </Form>
    </div>
  );
};

Inclusion.propTypes = {
  exclusionForm: PropTypes.object.isRequired,
  inclusionForm: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
};

Inclusion.defaultProps = {
  onUpdate: () => {},
};

export default Inclusion;

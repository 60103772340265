/* eslint-disable max-len */
import React, { useState, useEffect } from "react";
import {
  Steps,
  Card,
  Spin,
  Form,
  Button,
  Typography,
  Popconfirm,
  Modal,
} from "antd";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//import moment from "moment";
import {
  setPatientsList,
  setPendingListAsync,
} from "../../../redux/patients/actions";
import HeadBreadCrumb from "../../../components/HeadBreadCrumb";
import PatientsList from "./PatientsList";
import emptyValues from "./emptyValues";
import emptyValuesV2 from "./emptyValuesV2";
//import FluidsNumber from "./FluidsNumber";
import { parseVisitName, structureFields, structureFieldsInclusion } from "../../../helpers/functions";
import DrugForm from "./drugForm";
import { createVisitAsync } from "../../../redux/visits/actions";
import "./stylesIndex.css";
import Inclusion from "../../../components/medicalRecord/inclusion";
import AntecedentsExams from "../../../components/medicalRecord/antecedents";
import ShowPatientData from "../../../components/medicalRecord/showPatientDatya";
import Treatments from "../../../components/medicalRecord/treatments";
import emptyValuesAdverse from "../../AdverseEvents/emptyValues";
import AdverseForm from "../../AdverseEvents/add";
import { createAdverseAsync } from "../../../redux/adverse/actions";
import AntecedentsMed from "../../../components/medicalRecord/antecedentMed";
import ExamenMleculaire from "../../../components/medicalRecord/examenMoluculaire";
import ExamenHistologique from "../../../components/medicalRecord/examenHistologique";
import ClassificationTNM from "../../../components/medicalRecord/classificationTNM";
import ExamenRadiologique from "../../../components/medicalRecord/examenRadiologique";
import ExamenClinique from "../../../components/medicalRecord/examenClinique";
import moment from "moment";

const { Step } = Steps;
const { Title } = Typography;

const AddVisit = ({
  contentIsLoading,
  setPendingListAsync,
  visit,
  patientsList,
  createVisitAsync,
  setPatientsList,
  closeAdd,
  patientAdded,
  createAdverseAsync,
}) => {
  const [current, setCurrent] = useState(0);
  const [showNextButton, setShowNextButton] = useState(false);
  const [patient, selectPatient] = useState(null);
  const [values, setValues] = useState(emptyValues);
  const [clinicalForm] = Form.useForm();
  const [visitInformationsForm] = Form.useForm();
  const [bloodSampleForm] = Form.useForm();
  const [drugForm] = Form.useForm();
  const [antecedentsMedForm] = Form.useForm();
  const [ClassificationTNMForm] = Form.useForm();
  const [examenCliniqueForm] = Form.useForm();

  const [formSubmitting, setFormSubmitting] = useState(false);

  const [inclusionForm] = Form.useForm();
  const [exclusionForm] = Form.useForm();
  const [examinationsForm] = Form.useForm();
  const [examnMoleclulaireForm] = Form.useForm();
  const [examnHistologiqueForm] = Form.useForm();
  const [examnRadiologiqueForm] = Form.useForm();
  const [antecedentsForm] = Form.useForm();
  const [dateVisiteForm] = Form.useForm();
  const [hasNoTreatmentsForm] = Form.useForm();
  const [formComplete, setFormComplete] = useState(false);

  const [eligiblePatient, setEligibilePatient] = useState(true);
  const [inclusion, setInclusion] = useState({
    //age: ageValue > 18 && ageValue < 55 ? "Non" : "Oui",
    cps: "",
    phcs: "",
    cla: "",
    tmm: "",
    hmm: "",
    prts: "",
    evs: "",
  });
  const [exclusion, setExclusion] = useState({
    anotherTrial: "",
    //age: ageValue < 18 || ageValue > 55 ? "Non" : "Oui",
    cancerSein: "",
    mnb: "",
    ptsmm: "",
    pcic: "",
    pet: "",
  });
  // const onChange = (name, value) => {
  //   setValues({ ...values, [name]: value });
  // };
  const [transport, setTransport] = useState(null);
  const [MC, setMC] = useState(null);
  const [PCI, setPCI] = useState(null);
  const [IDM, setIDM] = useState(null);
  const [MR, setMR] = useState(null);
  const [PC, setPC] = useState(null);
  const [ICP, setICP] = useState(null);
  const [AFA, setAFA] = useState(null);
  const [AVC, setAVC] = useState(null);
  const [MVAP, setMVAP] = useState(null);
  const [DIABETE, setDIABETE] = useState(null);
  const [HA, setHA] = useState(null);
  const [CANCER, setCANCER] = useState(null);
  const [BPCO, setBPCO] = useState(null);
  const [MRC, setMRC] = useState(null);
  const [MF, setMF] = useState(null);
  const [DEPRESSION, setDEPRESSION] = useState(null);
  const [DT, setDT] = useState(null);
  const [dateVisite, setDateVisite] = useState(null);
  const [consentDate, setConsentDate] = useState(null);

  
  const [didValsBioPass, setDidValsBioPass] = useState(true);

  const [hasNoTreatments, setHasNoTreatments] = useState(null);
  /** END TREATEMENTS */
  /** BIOLOGICAL VALS */
  const [mamoTaille, setMamoTaille] = useState(null);
  const [mamoTailleSecond, setMamoTailleSecond] = useState(null);
  const [Mammographie, setMammographie] = useState(null);
  const [thoraco, setThoraco] = useState(null);
  const [scintigraphie, setScintigraphie] = useState(null);
  const [echographie, setEchographie] = useState(null);
  const [Her2Neu, setHer2Neu] = useState(null);
  const [indiceChirurgie, setIndiceChirurgie] = useState(null);

  const [squelette, setSquelette] = useState(null);
  const [auscultation, setAuscultation] = useState(null);
  const [abdominal, setAbdominal] = useState(null);
  const [ganglionnaires, setGanglionnaires] = useState(null);
  const [ganglionnairesSus, setGanglionnairesSus] = useState(null);
  const [echographieMammaire, setechographieMammaire] = useState(null);

  const [HIS, setHIS] = useState(null);
  const [listMessage, setListMessage] = useState([]);

  /**END BIO */
  const staticMeasureVals = [
    {
      name: "IVSd",
      min: 6,
      max: 23,
      unit: "mm",
    },
    {
      name: "LVIDd",
      min: 37,
      max: 100,
      unit: "mm",
    },
    {
      name: "LVIDs",
      min: 22,
      max: 75,
      unit: "mm",
    },
    {
      name: "LVPWd",
      min: 6,
      max: 23,
      unit: "mm",
    },
    {
      name: "LVEDV",
      min: 50,
      max: 300,
      unit: "mL",
    },
    {
      name: "LVESV",
      min: 18,
      max: 115,
      unit: "mL",
    },
    {
      name: "LVEF",
      min: 15,
      max: 75,
      unit: "%",
    },
    {
      name: "LAESV",
      min: 40,
      max: 300,
      unit: "g/l",
    },
    {
      name: "RVIDd",
      min: 20,
      max: 100,
      unit: "mm",
    },
    {
      name: "TAPSE",
      min: 2,
      // max: 20,
      unit: "mm",
    },
    {
      name: "TR",
      min: 0,
      max: 20,
      unit: "m/s",
    },
    {
      name: "LVEDVA2C",
      min: 50,
      max: 300,
      unit: "mL",
    },
    {
      name: "LVESVA2C",
      min: 18,
      max: 115,
      unit: "mL",
    },
    {
      name: "LVEFA2C",
      min: 15,
      max: 75,
      unit: "%",
    },
    {
      name: "LAESVA2C",
      min: 40,
      max: 300,
      unit: "mL",
    },
  ];

  const fnCheckMeasuresVals = (value, name) => {
    const objFromStatic = staticMeasureVals.find((e) => e.name === name);
    const check = listMessage.some((e) => e.name === name);
    if ((value > objFromStatic.max || value < objFromStatic.min) && !check) {
      setDidValsBioPass(false);
      setListMessage([
        ...listMessage,
        {
          name,
          message:
            objFromStatic.min && objFromStatic.max
              ? `Merci de vérifier la valeur ${name} qui devrait être entre ${objFromStatic.min} et ${objFromStatic.max} ${objFromStatic.unit}.`
              : `Merci de vérifier la valeur ${name} qui devrait être supérieure à ${objFromStatic.min} ${objFromStatic.unit}.`,
        },
      ]);
    } else {
      if (value <= objFromStatic.max && value >= objFromStatic.min) {
        const deleteList = listMessage.filter((e) => e.name !== name);
        setListMessage(deleteList);
      }
    }
  };

  const fnCheckUsuellVals = (value, name) => {
    const objFromStatic = staticUsuelleVals.find((e) => e.name === name);
    const check = listMessage.some((e) => e.name === name);
    if ((value > objFromStatic.max || value < objFromStatic.min) && !check) {
      setDidValsBioPass(false);
      if (name === "Troponine") {
        setListMessage([
          ...listMessage,
          {
            name,
            message: `Merci de vérifier la valeur ${name} qui devrait être inférieur à ${objFromStatic.max} ${objFromStatic.unit} `,
          },
        ]);
      } else {
        setListMessage([
          ...listMessage,
          {
            name,
            message:
              objFromStatic.min && objFromStatic.max
                ? `Merci de vérifier la valeur ${name} qui devrait être entre ${objFromStatic.min} et ${objFromStatic.max} ${objFromStatic.unit}.`
                : `Merci de vérifier la valeur ${name} qui devrait être supérieure à ${objFromStatic.min} ${objFromStatic.unit}.`,
          },
        ]);
      }
    } else {
      if (value <= objFromStatic.max && value >= objFromStatic.min) {
        const deleteList = listMessage.filter((e) => e.name !== name);
        setListMessage(deleteList);
      }
    }
  };
  const checkValidDateConsent = () => {
    const consentDateMoment = moment(consentDate, "DD/MM/YYYY");
    const dateVisiteMoment = moment(dateVisite, "DD/MM/YYYY");
    const birthDateMoment = moment(patient.birthDate.value);

    const consentIsBeforeVisite = consentDateMoment.isBefore(dateVisiteMoment);
    const consentIsAfterBirth = consentDateMoment.isAfter(birthDateMoment);
    
    return consentIsAfterBirth && consentIsBeforeVisite;
  };
  const onUpdateValidation = (name, value) => {
    switch (name) {
      case "echographieMammaire":
        setechographieMammaire(value);
        break;
      case "consentDate":
        setConsentDate(value)
        break;
      case "dateVisite":
        setDateVisite(value);
        break;
      case "EIGDeclaration":
        setEIGDeclaration(value);
        break;
      case "EIGDeclarationIssue":
        setEIGDeclarationIssue(value);
        break;
      case "intensity":
        setintensity(value);
        break;
      case "ganglionnairesSus":
        setGanglionnairesSus(value);
        break;
      case "ganglionnaires":
        setGanglionnaires(value);
        break;
      case "abdominal":
        setAbdominal(value);
        break;
      case "auscultation":
        setAuscultation(value);
        break;
      case "squelette":
        setSquelette(value);
        break;
      case "indiceChirurgie":
        setIndiceChirurgie(value);
        break;
      case "Her2Neu":
        setHer2Neu(value);
        break;
      case "PCI":
        setPCI(value);
        break;
      case "IDM":
        setIDM(value);
        break;
      case "MR":
        setMR(value);
        break;
      case "transport":
        setTransport(value);
        break;
      case "MC":
        setMC(value);
        break;
      case "PC":
        setPC(value);
        break;
      case "ICP":
        setICP(value);
        break;
      case "AFA":
        setAFA(value);
        break;
      case "AVC":
        setAVC(value);
        break;
      case "MVAP":
        setMVAP(value);
        break;
      case "DIABETE":
        setDIABETE(value);
        break;
      case "HA":
        setHA(value);
        break;
      case "CANCER":
        setCANCER(value);
        break;
      case "BPCO":
        setBPCO(value);
        break;
      case "MRC":
        setMRC(value);
        break;
      case "MF":
        setMF(value);
        break;
      case "DEPRESSION":
        setDEPRESSION(value);
        break;
      case "DT":
        setDT(value);
        break;
      //case "age":
      case "cps":
      case "phcs":
      case "cla":
      case "tmm":
      case "hmm":
      case "prts":
      case "evs":
        setInclusion({ ...inclusion, [name]: value });
        break;
      case "anotherTrial":
      case "cancerSein":
      case "mnb":
      case "ptsmm":
      case "pcic":
      case "pet":
        setExclusion({ ...exclusion, [name]: value });
        break;
      case "mamoTaille":
        setMamoTaille(value);
        break;
      case "mamoTailleSecond":
        setMamoTailleSecond(value);
        break;
      case "Mammographie":
        setMammographie(value);
        break;
      case "thoraco":
        setThoraco(value);
        break;
      case "scintigraphie":
        setScintigraphie(value);
        break;
      case "echographie":
        setEchographie(value);
        break;
      case "HasInsuffisanceCardiaque":
        setHIS(value);
        break;
      // case "hasNoTreatments":
      //   setHasNoTreatments(value);
      //   break;
      default:
        if (staticMeasureVals.some((e) => e.name === name)) {
          fnCheckMeasuresVals(value, name);
        }
        break;
    }
  };
  function confirm(e) {
    handleNext();
  }

  function cancel(e) {
    console.log(e);
    // alert('Click on No');
  }
  useEffect(() => {
    if (current === 2) {
      setFormComplete(false);
      const didInclusionFilled = Object.values(inclusion).every(
        (x) => x === "Oui"
      );
      const didExclusionFilled = Object.values(exclusion).every(
        (x) => x === "Non"
      );
      if (didInclusionFilled && didExclusionFilled) {
        setFormComplete(true);
      }
    }
  }, [inclusion, exclusion]);

  useEffect(() => {
    if (formComplete) {
      setEligibilePatient(!eligiblePatient);
    } else {
      // setEligibilePatient(true);
      setEligibilePatient(false);
    }
  }, [formComplete]);

  useEffect(() => {
    setPendingListAsync(visit, closeAdd);
    return () => {
      setPatientsList([]);
    };
  }, []);

  useEffect(
    () => () => {
      setPatientsList([]);
      closeAdd();
    },
    [visit]
  );

  useEffect(() => {
    if (formSubmitting) {
      createVisitAsync({ ...values, patient, visitNumber: visit });

      setFormSubmitting(false);
      setValues(null);
      setValues(visit !== "j2" ? emptyValues : emptyValuesV2);
      closeAdd();
    }
    if (current === 0 && patient) {
      selectPatient(null);
    }
    if (visit === "j0" || visit === "j7") {
      switch (current) {
        case 0:
          selectPatient(null);
          setShowNextButton(false);
          break;
        default:
          if (!showNextButton) {
            setShowNextButton(true);
          }
          break;
      }
    } else {
      switch (current) {
        case 0:
          selectPatient(null);
          setShowNextButton(false);
          break;
        case 4:
          setShowNextButton(false);
          break;
        default:
          if (!showNextButton) {
            setShowNextButton(true);
          }
          break;
      }
    }
  }, [current, formSubmitting]);
  const staticUsuelleVals = [
    {
      name: "NA",
      min: 135,
      max: 145,
      unit: "mmol/l",
    },
    {
      name: "K",
      min: 3.4,
      max: 5.1,
      unit: "mmol/l",
    },
    {
      name: "Ca",
      min: 2.2,
      max: 2.6,
      unit: "mmmol/l",
    },
    {
      name: "Mg",
      min: 0.7,
      max: 1.1,
      unit: "mmol/l",
    },
    {
      name: "Cl",
      min: 95,
      max: 110,
      unit: "Cl",
    },
    {
      name: "CO3",
      min: 22,
      max: 27,
      unit: "mmol/l",
    },
    {
      name: "eGFR",
      min: 1.3,
      max: 2.0,
      unit: "mL/min /1.73 m²",
    },
    {
      name: "cholestérol",
      min: 0.7,
      max: 1.1,
      unit: "g/l",
    },
    {
      name: "Glycémie",
      min: 13,
      max: 18,
      unit: "",
    },
    {
      name: "hemoglobineHB",
      min: 4,
      max: 6.7,
      unit: "",
    },
    {
      name: "hemoglobine",
      min: 4,
      max: 6.7,
      unit: "",
    },
    {
      name: "Troponine",
      max: 0.6,
      min: 0,
      unit: "μg/L",
    },
  ];

  const handleNext = async (data) => {
    try {
      let vals;
      if (visit === "j0" || visit === "j7") {
        switch (current) {
          case 0:
            selectPatient(data);
            setCurrent(current + 1);
            setShowNextButton(true);
            break;
          case 1:
            setCurrent(current + 1);
            break;
          case 2:
            const inclusionVals = await inclusionForm.validateFields();
            const exclusionVals = await exclusionForm.validateFields();
            vals = {
              dateVisite: dateVisiteForm.getFieldValue("dateVisite"),
              consentDate: dateVisiteForm.getFieldValue("consentDate"),
            };
            setValues({
              ...values,
              ...structureFields(values, vals),
              inclusion: structureFieldsInclusion(values.inclusion, inclusionVals),
              exclusion: structureFieldsInclusion(values.exclusion, exclusionVals),
            });
            setCurrent(current + 1);
            break;
          case 3:
            vals = await antecedentsMedForm.validateFields();
            setValues({
              ...values,
              antecedentMed: structureFields(values.antecedentMed, vals),
            });
            setCurrent(current + 1);
            break;
          case 4:
            vals = await examenCliniqueForm.validateFields();
            setValues({
              ...values,
              examenClinique: structureFields(values.examenClinique, vals),
            });
            setCurrent(current + 1);
            break;
          case 5:
            vals = await examnRadiologiqueForm.validateFields();
            setValues({
              ...values,
              examenRadiologique: structureFields(
                values.examenRadiologique,
                vals
              ),
            });
            setCurrent(current + 1);
            break;
          case 6:
            vals = await ClassificationTNMForm.validateFields();
            setValues({
              ...values,
              classificationTNM: structureFields(
                values.classificationTNM,
                vals
              ),
            });
            setCurrent(current + 1);
            break;
          case 7:
            vals = await examinationsForm.validateFields();
            vals = await antecedentsForm.validateFields();
            setValues({
              ...values,
              biologicalExamination: structureFields(
                values.biologicalExamination,
                vals
              ),
              antecedents: structureFields(values.antecedents, vals),
            });
            setCurrent(current + 1);
            break;
          case 8:
            vals = await examnHistologiqueForm.validateFields();
            setValues({
              ...values,
              examenHistologique: structureFields(
                values.examenHistologique,
                vals
              ),
            });
            setCurrent(current + 1);
            break;
          case 9:
            vals = await examnMoleclulaireForm.validateFields();
            setValues({
              ...values,
              examenMolelculaire: structureFields(
                values.examenMolelculaire,
                vals
              ),
            });
            setCurrent(current + 1);
            break;
          case 10:
            vals = await hasNoTreatmentsForm.validateFields();
            setValues({
              ...values,
              ...structureFields(
                values.hasNoTreatments,
                vals
              ),
            });
            setCurrent(current + 1);
            break;
          case 11:

            vals = {
              dateNextVisit: drugForm.getFieldValue("dateNextVisit"),
              dateNextVisitHeure: drugForm.getFieldValue("dateNextVisitHeure"),
              inclusionDate: drugForm.getFieldValue("inclusionDate"),
            };
            let inclusionObj={};
            let exclusionObj={};
            Object.entries(values.inclusion).forEach(([key,value], index) =>{ inclusionObj = {
              ...inclusionObj,
              [key]:{
                ...value,
                readOnly: true
              }
            }})
            Object.entries(values.exclusion).forEach(([key,value], index) =>{ exclusionObj = {
              ...exclusionObj,  
              [key]:{
                ...value,
                readOnly: true
              }
            }})
            
            setValues({
              ...values,
              ...structureFields(values, vals),
              inclusion: inclusionObj,
              exclusion: exclusionObj,
            });
            setFormSubmitting(true);
            break;
          default:
            break;
        }
      } else if (visit === "j2") {
        switch (current) {
          case 0:
            selectPatient(data);
            setCurrent(current + 1);
            setShowNextButton(true);
            break;
          case 1:
            vals = await examenCliniqueForm.validateFields();
            setValues({
              ...values,
              examenClinique: structureFields(values.examenClinique, vals),
            });
            setCurrent(current + 1);
            break;
          case 2:
            vals = await antecedentsForm.validateFields();
            let valsDate = {
              dateVisite: dateVisiteForm.getFieldValue("dateVisite"),
            };
            setValues({
              ...values,
              ...structureFields(values, valsDate),
              biologicalExamination: structureFields(
                values.biologicalExamination,
                vals
              ),
              antecedents: structureFields(values.antecedents, vals),
            });
            setCurrent(current + 1);
            break;
          case 3:
            vals = await ClassificationTNMForm.validateFields();
            setValues({
              ...values,
              classificationTNM: structureFields(
                values.classificationTNM,
                vals
              ),
            });
            setCurrent(current + 1);
            break;
          case 4:
            vals = await examnMoleclulaireForm.validateFields();
            setValues({
              ...values,
              examenMolelculaire: structureFields(
                values.examenMolelculaire,
                vals
              ),
            });
            setCurrent(current + 1);
            break;
          case 5:
            vals = await examnHistologiqueForm.validateFields();
            setValues({
              ...values,
              examenHistologique: structureFields(
                values.examenHistologique,
                vals
              ),
            });
            setCurrent(current + 1);
            break;
          case 6:
            vals = {
              dateNextVisit: drugForm.getFieldValue("dateNextVisit"),
            };
            setValues({
              ...values,
              ...structureFields(values, vals),
            });
            setFormSubmitting(true);
            break;
          default:
            break;
        }
      } else if (visit === "j3") {
        switch (current) {
          case 0:
            selectPatient(data);
            setCurrent(current + 1);
            setShowNextButton(true);
            break;
          case 1:
            vals = await examenCliniqueForm.validateFields();
            setValues({
              ...values,
              examenClinique: structureFields(values.examenClinique, vals),
            });
            setCurrent(current + 1);
            break;
          case 2:
            vals = await antecedentsForm.validateFields();
            let valsDate = {
              dateVisite: dateVisiteForm.getFieldValue("dateVisite"),
            };
            setValues({
              ...values,
              ...structureFields(values, valsDate),
              biologicalExamination: structureFields(
                values.biologicalExamination,
                vals
              ),
              antecedents: structureFields(values.antecedents, vals),
            });
            setCurrent(current + 1);
            break;
          case 3:
            vals = await examnRadiologiqueForm.validateFields();
            setValues({
              ...values,
              examenRadiologique: structureFields(
                values.examenRadiologique,
                vals
              ),
            });
            setCurrent(current + 1);
            break;
          case 4:
            vals = {
              dateNextVisit: drugForm.getFieldValue("dateNextVisit"),
            };
            setValues({
              ...values,
              ...structureFields(values, vals),
            });
            setFormSubmitting(true);
            break;
          default:
            break;
        }
      } else if (visit === "j4") {
        switch (current) {
          case 0:
            selectPatient(data);
            setCurrent(current + 1);
            setShowNextButton(true);
            break;
          case 1:
            vals = await examenCliniqueForm.validateFields();
            setValues({
              ...values,
              examenClinique: structureFields(values.examenClinique, vals),
            });
            setCurrent(current + 1);
            break;
          case 2:
            vals = await examnRadiologiqueForm.validateFields();
            setValues({
              ...values,
              examenRadiologique: structureFields(
                values.examenRadiologique,
                vals
              ),
            });
            setCurrent(current + 1);
            break;
          case 3:
            vals = await antecedentsForm.validateFields();
            let valsDate = {
              dateVisite: dateVisiteForm.getFieldValue("dateVisite"),
            };
            setValues({
              ...values,
              ...structureFields(values, valsDate),
              biologicalExamination: structureFields(
                values.biologicalExamination,
                vals
              ),
              antecedents: structureFields(values.antecedents, vals),
            });
            setFormSubmitting(true);
            break;
          default:
            break;
        }
      } else {
        switch (current) {
          case 0:
            selectPatient(data);
            setCurrent(current + 1);
            setShowNextButton(true);
            break;
          case 1:
            vals = await examenCliniqueForm.validateFields();
            setValues({
              ...values,
              examenClinique: structureFields(values.examenClinique, vals),
            });
            setCurrent(current + 1);
            break;
          case 2:
            vals = await antecedentsForm.validateFields();
            let valsDate = {
              dateVisite: dateVisiteForm.getFieldValue("dateVisite"),
            };
            setValues({
              ...values,
              ...structureFields(values, valsDate),
              biologicalExamination: structureFields(
                values.biologicalExamination,
                vals
              ),
              antecedents: structureFields(values.antecedents, vals),
            });
            setCurrent(current + 1);
            break;
          case 3:
            vals = {
              dateNextVisit: drugForm.getFieldValue("dateNextVisit"),
            };
            setValues({
              ...values,
              ...structureFields(values, vals),
            });
            setFormSubmitting(true);
            break;
          default:
            break;
        }
      }
    } catch (err) {
      // Nvm
    }
  };
  const showButton = () => {
    if (visit === "j0") {
      if (current === 0) return "";
      if (current < 11 && current !== 0) return "Suivant";
      return "Terminer";
    }
    if (visit === "j1") {
      if (current < 3) return "Suivant";
      return "Terminer";
    }
    if (visit === "j2") {
      if (current < 6) return "Suivant";
      return "Terminer";
    }
    if (visit === "j3") {
      if (current < 4) return "Suivant";
      return "Terminer";
    }
    if (visit === "j4") {
      if (current < 2) return "Suivant";
      return "Terminer";
    }
  };

  useEffect(() => {
    if (patientAdded !== null && current === 0) {
      selectPatient(patientAdded);
      setCurrent(current + 1);
      setShowNextButton(true);
    }
  }, []);

  const showStep = () => {
    if (visit === "j0") {
      switch (current) {
        case 0:
          return (
            <Card bordered={false}>
              <PatientsList
                data={patientsList}
                title="Liste des participants qui n'ont pas eu encore cette visite"
                onSelect={handleNext}
              />
            </Card>
          );
        case 1:
          return <ShowPatientData patient={patient} visit={visit} />;
        case 2:
          return (
            <Inclusion
              inclusionForm={inclusionForm}
              exclusionForm={exclusionForm}
              dateVisiteForm={dateVisiteForm}
              values={values}
              onUpdate={onUpdateValidation}
              isAdd={true}
              patient={patient}
              dateVisite={dateVisite}
              consentDate={consentDate}
              checkValidDateConsent={checkValidDateConsent}
            />
          );
        case 3:
          return (
            <div>
              <AntecedentsMed
                values={values}
                form={antecedentsMedForm}
                onUpdate={onUpdateValidation}
                isAdd={true}
                visit={visit}
                MC={MC}
                DIABETE={DIABETE}
                HA={HA}
                MF={MF}
                MR={MR}
              />
            </div>
          );
        case 4:
          return (
            <div>
              <ExamenClinique
                values={values}
                form={examenCliniqueForm}
                onUpdate={onUpdateValidation}
                isAdd={true}
                visit={visit}
                squelette={squelette}
                abdominal={abdominal}
                auscultation={auscultation}
                ganglionnaires={ganglionnaires}
                ganglionnairesSus={ganglionnairesSus}
              />
            </div>
          );
        case 5:
          return (
            <div>
              <ExamenRadiologique
                values={values}
                form={examnRadiologiqueForm}
                onUpdate={onUpdateValidation}
                isAdd={true}
                visit={visit}
                mamoTaille={mamoTaille}
                mamoTailleSecond={mamoTailleSecond}
                Mammographie={Mammographie}
                thoraco={thoraco}
                scintigraphie={scintigraphie}
                echographie={echographie}
              />
            </div>
          );
        case 6:
          return (
            <ClassificationTNM
              values={values}
              form={ClassificationTNMForm}
              onUpdate={onUpdateValidation}
              isAdd={true}
              visit={visit}
            />
          );
        case 7:
          return (
            <AntecedentsExams
              values={values}
              form={antecedentsForm}
              onUpdate={onUpdateValidation}
              isAdd={true}
              visit={visit}
              Her2Neu={Her2Neu}
            />
          );
        case 8:
          return (
            <ExamenHistologique
              values={values}
              form={examnHistologiqueForm}
              onUpdate={onUpdateValidation}
              isAdd={true}
              visit={visit}
              Her2Neu={Her2Neu}
            />
          );
        case 9:
          return (
            <ExamenMleculaire
              values={values}
              form={examnMoleclulaireForm}
              onUpdate={onUpdateValidation}
              isAdd={true}
              visit={visit}
            />
          );
        case 10:
          return (
            <Treatments
              onChange={(name, value) => {
                setValues({ ...values, [name]: value });
              }}
              onFormUpdate={onUpdateValidation}
              values={values}
              isAdd={true}
              form={hasNoTreatmentsForm}
            />
          );
        case 11:
          return (
            <DrugForm
              form={drugForm}
              visit={visit}
              patient={patient}
              values={values}
              onUpdate={onUpdateValidation}
            />
          );
        default:
          return <div />;
      }
    } else if (visit === "j2") {
      switch (current) {
        case 0:
          return (
            <Card bordered={false}>
              <PatientsList
                data={patientsList}
                title="Liste des participants qui n'ont pas eu encore cette visite"
                onSelect={handleNext}
              />
            </Card>
          );
        case 1:
          return (
            <div>
              <ExamenClinique
                values={values}
                form={examenCliniqueForm}
                onUpdate={onUpdateValidation}
                isAdd={true}
                visit={visit}
                squelette={squelette}
                abdominal={abdominal}
                auscultation={auscultation}
                ganglionnaires={ganglionnaires}
                ganglionnairesSus={ganglionnairesSus}
              />
            </div>
          );
        case 2:
          return (
            <div style={{ minWidth: "100%" }}>
              <AntecedentsExams
                values={values}
                isAdd={true}
                form={antecedentsForm}
                dateVisiteForm={dateVisiteForm}
                onUpdate={onUpdateValidation}
                visit={visit}
                MC={MC}
                DIABETE={DIABETE}
                HA={HA}
                MF={MF}
                MR={MR}
                mamoTaille={mamoTaille}
                mamoTailleSecond={mamoTailleSecond}
                Mammographie={Mammographie}
                thoraco={thoraco}
                scintigraphie={scintigraphie}
                echographie={echographie}
                Her2Neu={Her2Neu}
                indiceChirurgie={indiceChirurgie}
                squelette={squelette}
                abdominal={abdominal}
                auscultation={auscultation}
                ganglionnaires={ganglionnaires}
                ganglionnairesSus={ganglionnairesSus}
                echographieMammaire={echographieMammaire}
              />
            </div>
          );
        case 3:
          return (
            <ClassificationTNM
              values={values}
              form={ClassificationTNMForm}
              onUpdate={onUpdateValidation}
              isAdd={true}
              visit={visit}
            />
          );
        case 4:
          return (
            <ExamenMleculaire
              values={values}
              form={examnMoleclulaireForm}
              onUpdate={onUpdateValidation}
              isAdd={true}
              visit={visit}
            />
          );
        case 5:
          return (
            <ExamenHistologique
              values={values}
              form={examnHistologiqueForm}
              onUpdate={onUpdateValidation}
              isAdd={true}
              visit={visit}
              Her2Neu={Her2Neu}
            />
          );
        case 6:
          return (
            <DrugForm
              form={drugForm}
              visit={visit}
              patient={patient}
              values={values}
              onUpdate={onUpdateValidation}
            />
          );
        default:
          return <div />;
      }
    } else if (visit === "j3") {
      switch (current) {
        case 0:
          return (
            <Card bordered={false}>
              <PatientsList
                data={patientsList}
                title="Liste des participants qui n'ont pas eu encore cette visite"
                onSelect={handleNext}
              />
            </Card>
          );
        case 1:
          return (
            <div>
              <ExamenClinique
                values={values}
                form={examenCliniqueForm}
                onUpdate={onUpdateValidation}
                isAdd={true}
                visit={visit}
                squelette={squelette}
                abdominal={abdominal}
                auscultation={auscultation}
                ganglionnaires={ganglionnaires}
                ganglionnairesSus={ganglionnairesSus}
              />
            </div>
          );
        case 2:
          return (
            <div style={{ minWidth: "100%" }}>
              <AntecedentsExams
                values={values}
                isAdd={true}
                form={antecedentsForm}
                dateVisiteForm={dateVisiteForm}
                onUpdate={onUpdateValidation}
                visit={visit}
                MC={MC}
                DIABETE={DIABETE}
                HA={HA}
                MF={MF}
                MR={MR}
                mamoTaille={mamoTaille}
                mamoTailleSecond={mamoTailleSecond}
                Mammographie={Mammographie}
                thoraco={thoraco}
                scintigraphie={scintigraphie}
                echographie={echographie}
                Her2Neu={Her2Neu}
                indiceChirurgie={indiceChirurgie}
                squelette={squelette}
                abdominal={abdominal}
                auscultation={auscultation}
                ganglionnaires={ganglionnaires}
                ganglionnairesSus={ganglionnairesSus}
                echographieMammaire={echographieMammaire}
              />
            </div>
          );
        case 3:
          return (
            <div>
              <ExamenRadiologique
                values={values}
                form={examnRadiologiqueForm}
                onUpdate={onUpdateValidation}
                isAdd={true}
                visit={visit}
                mamoTaille={mamoTaille}
                mamoTailleSecond={mamoTailleSecond}
                Mammographie={Mammographie}
                thoraco={thoraco}
                scintigraphie={scintigraphie}
                echographie={echographie}
              />
            </div>
          );
        case 4:
          return (
            <DrugForm
              form={drugForm}
              visit={visit}
              patient={patient}
              values={values}
              onUpdate={onUpdateValidation}
            />
          );
        default:
          return <div />;
      }
    } else if (visit === "j4") {
      switch (current) {
        case 0:
          return (
            <Card bordered={false}>
              <PatientsList
                data={patientsList}
                title="Liste des participants qui n'ont pas eu encore cette visite"
                onSelect={handleNext}
              />
            </Card>
          );
        case 1:
          return (
            <div>
              <ExamenClinique
                values={values}
                form={examenCliniqueForm}
                onUpdate={onUpdateValidation}
                isAdd={true}
                visit={visit}
                squelette={squelette}
                abdominal={abdominal}
                auscultation={auscultation}
                ganglionnaires={ganglionnaires}
                ganglionnairesSus={ganglionnairesSus}
              />
            </div>
          );
          case 2:
            return (
              <ExamenRadiologique
                values={values}
                form={examnRadiologiqueForm}
                onUpdate={onUpdateValidation}
                isAdd={true}
                visit={visit}
                mamoTaille={mamoTaille}
                mamoTailleSecond={mamoTailleSecond}
                Mammographie={Mammographie}
                thoraco={thoraco}
                scintigraphie={scintigraphie}
                echographie={echographie}
              />
            );
        case 3:
          return (
            <div style={{ minWidth: "100%" }}>
              <AntecedentsExams
                values={values}
                isAdd={true}
                form={antecedentsForm}
                dateVisiteForm={dateVisiteForm}
                onUpdate={onUpdateValidation}
                visit={visit}
                MC={MC}
                DIABETE={DIABETE}
                HA={HA}
                MF={MF}
                MR={MR}
                mamoTaille={mamoTaille}
                mamoTailleSecond={mamoTailleSecond}
                Mammographie={Mammographie}
                thoraco={thoraco}
                scintigraphie={scintigraphie}
                echographie={echographie}
                Her2Neu={Her2Neu}
                indiceChirurgie={indiceChirurgie}
                squelette={squelette}
                abdominal={abdominal}
                auscultation={auscultation}
                ganglionnaires={ganglionnaires}
                ganglionnairesSus={ganglionnairesSus}
              />
            </div>
          );

        default:
          return <div />;
      }
    } else {
      switch (current) {
        case 0:
          return (
            <Card bordered={false}>
              <PatientsList
                data={patientsList}
                title="Liste des participants qui n'ont pas eu encore cette visite"
                onSelect={handleNext}
              />
            </Card>
          );
        case 1:
          return (
            <div style={{ minWidth: "100%" }}>
              <ExamenClinique
                values={values}
                form={examenCliniqueForm}
                onUpdate={onUpdateValidation}
                isAdd={true}
                visit={visit}
                squelette={squelette}
                abdominal={abdominal}
                auscultation={auscultation}
                ganglionnaires={ganglionnaires}
                ganglionnairesSus={ganglionnairesSus}
              />
            </div>
          );
        case 2:
          return (
            <div>
              <AntecedentsExams
                values={values}
                isAdd={true}
                form={antecedentsForm}
                dateVisiteForm={dateVisiteForm}
                onUpdate={onUpdateValidation}
                visit={visit}
                MC={MC}
                DIABETE={DIABETE}
                HA={HA}
                MF={MF}
                MR={MR}
                mamoTaille={mamoTaille}
                mamoTailleSecond={mamoTailleSecond}
                Mammographie={Mammographie}
                thoraco={thoraco}
                scintigraphie={scintigraphie}
                echographie={echographie}
                Her2Neu={Her2Neu}
                indiceChirurgie={indiceChirurgie}
                squelette={squelette}
                abdominal={abdominal}
                auscultation={auscultation}
                ganglionnaires={ganglionnaires}
                ganglionnairesSus={ganglionnairesSus}
                echographieMammaire={echographieMammaire}
              />
            </div>
          );
        case 3:
          return (
            <DrugForm
              form={drugForm}
              visit={visit}
              patient={patient}
              values={values}
              onUpdate={onUpdateValidation}
            />
          );
        default:
          return <div />;
      }
    }
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [intensity, setintensity] = useState(null);
  const [EIGDeclarationIssue, setEIGDeclarationIssue] = useState(null);
  const [EIGDeclaration, setEIGDeclaration] = useState(null);
  const [formSubmittingAdverse, setFormSubmittingAdverse] = useState(null);
  useEffect(() => {
    if (formSubmittingAdverse) {
      createAdverseAsync({
        ...valuesAdverse,
        patient: patient._id,
        inclusionNumber: "",
      }).then(() => {
        setFormSubmittingAdverse(false);
        setValuesAdverse(null);
        setValuesAdverse(emptyValuesAdverse);
        setIsModalOpen(false)
      });
    }
  }, [formSubmittingAdverse]);

  const onFinish = async () => {
    const vals = await adverseForm.validateFields();
    setValuesAdverse({
      ...valuesAdverse,
      ...structureFields(valuesAdverse, vals),
    });
    setFormSubmittingAdverse(true);
    adverseForm.resetFields();
  };
  const [valuesAdverse, setValuesAdverse] = useState(emptyValuesAdverse);
  const [adverseForm] = Form.useForm();

  return (
    <div className="section-content">
      {current > 1 && (
        <Button type="primary" onClick={showModal}>
          Ajouter événement indésirable
        </Button>
      )}
      <Modal
        title="Evenement indésirable"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ minWidth: "700px" }}
      >
        <AdverseForm
          form={adverseForm}
          values={emptyValuesAdverse}
          patient={patient}
          onFinish={onFinish}
          onUpdate={onUpdateValidation}
          intensity={intensity}
          EIGDeclarationIssue={EIGDeclarationIssue}
          EIGDeclaration={EIGDeclaration}
        />
      </Modal>
      <HeadBreadCrumb
        firstSectionName="Visites"
        secondSectionName={parseVisitName(visit, true)}
        thirdSectionName="Ajout d'une visite"
        handleGoBack={closeAdd}
      />
      <Spin spinning={contentIsLoading}>
        <Card>
          <Steps
            current={current}
            onChange={setCurrent}
            size="small"
            className="m-bottom-medium stepsHeader"
          >
            <Step title="Identification" />

            {/* <Step disabled={current < 2} title="Nombre de fluides" /> */}

            {/* {(visit !== "j0" && visit !== "j7") ||
              visit === "j1" ||
              (visit === "j8" && (
                <Step disabled={current < 2} title="Prélèvement" />
              ))} */}

            {visit === "j2" && (
              <>
                <Step disabled={current < 2} title="Clinique" />
                <Step disabled={current < 3} title="Résultats biologiques" />
                <Step disabled={current < 4} title="Classification TNM" />
                <Step disabled={current < 5} title="Moléculaire" />
                <Step disabled={current < 6} title="Histologique" />
                <Step disabled={current < 7} title="Finalisation" />
              </>
            )}

            {visit === "j1" ? (
              <>
                <Step disabled={current < 4} title="Clinique" />
                <Step disabled={current < 2} title="Informations V2" />
                <Step disabled={current < 5} title="Finalisation" />
              </>
            ) : null}

            {visit === "j3" ? (
              <>
                <Step disabled={current < 2} title="Clinique" />
                <Step disabled={current < 5} title="Informations V4" />
                <Step disabled={current < 3} title="Radiologie" />
                <Step disabled={current < 4} title="Finalisation" />
              </>
            ) : null}
            {visit === "j4" ? (
              <>
                <Step disabled={current < 2} title="Examen Clinique" />
                <Step disabled={current < 5} title="Radiologie" />
                <Step disabled={current < 3} title="Informations" />
              </>
            ) : null}

            {visit === "j0" || visit === "j7" ? (
              <>
                <Step disabled={current < 2} title="Données personnelles" />
                <Step disabled={current < 3} title="Inclusion & Exclusion" />
                <Step disabled={current < 4} title="Antécédents" />
                <Step disabled={current < 5} title="Clinique" />
                <Step disabled={current < 6} title="Radiologie" />
                <Step disabled={current < 7} title="Classification TNM" />
                <Step disabled={current < 8} title="Biologie" />
                <Step disabled={current < 9} title="Histologie" />
                <Step disabled={current < 10} title="Examen moléculaire" />
                <Step disabled={current < 11} title="Traitements" />
                <Step  title="Finalisation" />
              </>
            ) : null}
          </Steps>

          <div className="m-top-medium" style={{ padding: 24 }}>
            {showStep()}
          </div>
          {current === 2 && !eligiblePatient && visit === "j0" ? (
            <Popconfirm
              title="Certains critéres ne permettent pas l'inclusion de ce patient. Etes vous sur de vouloir continuer?"
              onConfirm={confirm}
              onCancel={cancel}
              okText="Oui"
              cancelText="Non"
              className="m-top-medium m-bottom-medium center-button"
            >
              <Button
                type="primary"
                className="m-top-medium m-bottom-medium center-button"
                disabled={(dateVisite !== null && consentDate !== null) && !checkValidDateConsent()}
              >
                Suivant
              </Button>
            </Popconfirm>
          ) : ( 
            (current === 3 && (visit === "j1" || visit === "j4")) ||
            (current === 4 && visit === "j3" ) ||
            (current===6 && visit ==="j2")
            ) ? (
            <>
              <Popconfirm
                title="Merci de vérifier que la section évènements indésirables à bien été complétée si le participant en a subi depuis sa dernière visite."
                onConfirm={confirm}
                onCancel={cancel}
                okText="Continuer"
                cancelText="Annuler"
                className="m-top-medium m-bottom-medium center-button"
              >
                <Button
                  type="primary"
                  className="m-top-medium m-bottom-medium center-button"
                >
                  Suivant
                </Button>
              </Popconfirm>
            </>
          ) : (
            <Button
              onClick={handleNext}
              type="primary"
              className="m-top-medium m-bottom-medium center-button"
            >
              {showButton()}
            </Button>
          )}
        </Card>
      </Spin>
    </div>
  );
};

AddVisit.propTypes = {
  contentIsLoading: PropTypes.bool.isRequired,
  setPendingListAsync: PropTypes.func.isRequired,
  visit: PropTypes.string.isRequired,
  patientsList: PropTypes.array.isRequired,
  createVisitAsync: PropTypes.func.isRequired,
  setPatientsList: PropTypes.func.isRequired,
  closeAdd: PropTypes.func.isRequired,
  createAdverseAsync: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  contentIsLoading: state.loading.contentIsLoading,
  patientsList: state.patients.patientsList,
});

const dispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      setPendingListAsync,
      createVisitAsync,
      setPatientsList,
      createAdverseAsync,
    },
    dispatch
  );

export default connect(stateToProps, dispatchToProps)(AddVisit);

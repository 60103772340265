import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Form } from "antd";
import DynamicField from "../../../components/DynamicField";
import { connect } from "react-redux";

const Decision = ({ form, values, onUpdate, status, patientsList, centre, user }) => {
  const [customId, setCustomId] = useState(0);
  const [workingFiltred, setWorkingFiltred] = useState(0);
  useEffect(()=>{
    if(user.workCenter){
      const  staticValuesCenter = values.decision.centre.radioFields;
      const res = staticValuesCenter.filter(item => user.workCenter.includes(item.value));
      setWorkingFiltred({
        ...values.decision.centre,
        radioFields : res})
    }
  },[user])

  useEffect(() => {
    let patients = [];
    patients = patientsList;
    if (!patients.length) setCustomId("001");
    if (patients.length) {
      const lastObject = patients.reduce((r, a) =>
        r.createdAt > a.createdAt ? r : a
      );
      let counter=1;
      patients.map(p=>{
        if(p.customId.split("-")[1] === centre){
          counter++;
        }
      })
      const padStart = counter.toString().padStart(3, 0);
      setCustomId(padStart);
    }
  }, [patientsList,centre]);
  
  return (
    <Form form={form}>
      {/* <DynamicField
        field={values.decision.status}
        onUpdate={onUpdate}
        fieldFamily="decision"
      /> */}
      {/* {
            status === 'Ne participe pas'
                ? <DynamicField field={values.decision.descirptionNonEligibile} onUpdate={onUpdate} fieldFamily="decision" />
                : null
        } */}
      { values.decision.centre.value === undefined ? 
        user.workCenter === undefined ? (   <DynamicField
          field={values.decision.centre}
          onUpdate={onUpdate}
          fieldFamily="decision"
        />) : <DynamicField
        field={workingFiltred}
        onUpdate={onUpdate}
        fieldFamily="decision"
      />  : null
      }

      {/* <DynamicField
        field={values.decision.nextVisitDate}
        onUpdate={onUpdate}
        fieldFamily="decision"
      /> */}
      {values.customId ? <strong>Identifiant du patient: {values.customId}</strong>  : centre !== null   ? (
        <strong>
          Identifiant du patient: {customId}-{centre}-BIOPEACE
        </strong>
      ) : null}
    </Form>
  );
};

Decision.propTypes = {
  form: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  patientsList: PropTypes.array.isRequired,
};

const stateToProps = (state) => ({
  patientsList: state.patients.patientsList,
  user: state.auth.user,
});

export default connect(stateToProps)(Decision);

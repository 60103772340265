export default {
  firstName: {
    name: "firstName",
    label: "Prénom",
    rules: ["required"],
    inputStyle: { width: "min-content" },
    type: "input",
    strongLabel: true,
  },
  lastName: {
    name: "lastName",
    label: "Nom",
    rules: ["required"],
    inputStyle: { width: "min-content" },
    type: "input",
    strongLabel: true,
  },
  gender: {
    name: "gender",
    label: "Sexe",
    rules: ["required"],
    type: "radio",
    radioFields: [
      { label: "Homme", value: "Homme" },
      { label: "Femme", value: "Femme" },
    ],
    strongLabel: true,
  },
  pregnant: {
    name: "pregnant",
    label: "Grossesse",
    rules: ["required"],
    type: "radio",
    radioFields: [
      { label: "Oui", value: true },
      { label: "Non", value: false },
    ],
    strongLabel: true,
  },
  phoneFirst: {
    name: "phoneFirst",
    label: "Téléphone 1",
    rules: ["required"],
    type: "input",
    placeholder: "e.g: 20 111 111",
    strongLabel: true,
  },
  phoneSecond: {
    name: "phoneSecond",
    label: "Téléphone 2",
    rules: [],
    type: "input",
    placeholder: "e.g: 20 111 111",
    strongLabel: true,
  },
  phoneThird: {
    name: "phoneThird",
    label: "Téléphone 3",
    rules: [],
    type: "input",
    placeholder: "e.g: 20 111 111",
    strongLabel: true,
  },
  address: {
    name: "address",
    label: "Adresse",
    rules: ["required"],
    type: "input",
    placeholder: "addresse participant",
    strongLabel: true,
  },
  habitation: {
    name: "habitation",
    label: "Habitation",
    rules: ["required"],
    type: "radio",
    radioFields: [
      { label: "Zone Rurale", value: "Zone Rurale" },
      { label: "Zone Urbaine", value: "Zone Urbaine" },
    ],
    strongLabel: true,
  },
  birthDate: {
    name: "birthDate",
    label: "Date de naissance",
    rules: ["required", "validDate","futurDate"],
    inputStyle: { width: "min-content" },
    type: "input",
    placeholder: "e.g: 01/01/2000",
    strongLabel: true,
  },
  decision: {
    status: {
      name: "status",
      label: "Participant éligible à l’étude",
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Participe" },
        { label: "Non", value: "Ne participe pas" },
      ],
      strongLabel: true,
    },
    centre: {
      name: "centre",
      label: "Numéro du Centre (ISA/CH/CEN)",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "ISA", value: "ISA" },
        { label: "CH", value: "CH" },
        { label: "CEN", value: "CEN" },
      ],
    },
    nextVisitDate: {
      name: "nextVisitDate",
      label: "Prochaine date pour la visite V1 : VISITE DE SELECTION – J0",
      rules: ["required"],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
  },
};

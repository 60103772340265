import React, { createContext, useEffect, useState } from "react";
import { Table, Button, Typography, Spin, Input } from "antd";
import { PlusOutlined, EyeOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Title from "antd/lib/typography/Title";
import moment from "moment";
import { formatDateTimeFromIso } from "../../helpers/functions";
import AddPatient from "./add";
import {
  getAllPatientsAsync,
  selectPatientAsync,
  selectPatient,
  setPatientsList,
} from "../../redux/patients/actions";
import SelectedPatient from "./selectedPatient";
import { withRouter } from "react-router-dom";
import Fuse from "fuse.js";

const { Text } = Typography;
const { Search } = Input;

export const EditContext = createContext(false);

const PatientsList = ({
  patientsList,
  getAllPatientsAsync,
  selectPatientAsync,
  selectedPatient,
  contentIsLoading,
  user,
  selectPatient,
  setPatientsList,
  ...props
}) => {
  const [showAdd, setShowAdd] = useState(false);
  const [filtredList, setfiltredList] = useState([]);

  const { location } = props;
  useEffect(() => {
    getAllPatientsAsync();
    return () => {
      selectPatient(null);
      setPatientsList([]);
    };
  }, []);

  useEffect(() => {
    if (location.state !== undefined) {
      if (location.state.withQueryPatientId && patientsList.length > 0) {
        const selectedVisitObject = patientsList.find((e) => {
          return e._id === location.search.split("?")[1];
        });
        selectPatientAsync(selectedVisitObject._id);
      }
    }
  }, [location, patientsList]);

  const onSearch = (e) => {
    const value = e.target.value;
    const fuse = new Fuse(patientsList, {
      threshold: 0,
      tokenize: true,
      keys: [ "customId"],
    });
    const newList = fuse.search(value).map((item) => item.item);
    setfiltredList(newList);
  };

  const isEdit = true;
  if (showAdd) {
    return (
      <EditContext.Provider value={isEdit}>
        <AddPatient closeAdd={() => setShowAdd(false)} />
      </EditContext.Provider>
    );
  }

  if (selectedPatient) {
    return (
      <EditContext.Provider value={!isEdit}>
        <SelectedPatient />
      </EditContext.Provider>
    );
  }

  const columns = (cb) => {
    const variableColumns =
      user && user.type === 2
        ? [
            {
              title: <b>Identifiant</b>,
              key: "customId",
              render: (_, record) => (
                <p>{record.customId ? record.customId : ""}</p>
              ),
            },
          ]
        : [
            {
              title: <b>Identifiant</b>,
              key: "customId",
              render: (_, record) => (
                <p>{record.customId ? record.customId : ""}</p>
              ),
            },
            {
              title: <b>Age </b>,
              key: "birthDate",
              render: (_, record) => (
                <p>{`${
                  record.birthDate
                    ? `${new Date().getFullYear() -
                        moment(record.birthDate.value).year()} ans`
                    : ""
                }`}</p>
              ),
            },
          ];
    return [
      ...variableColumns,
      {
        title: <b> Date de création </b>,
        key: "date",
        render: (_, record) => <p>{formatDateTimeFromIso(record.createdAt)}</p>,
      },
      {
        title: <b> Actions </b>,
        key: "_id",
        render: (_, record) => (
          <div className="flex">
            <Button
              type="primary"
              ghost
              onClick={() => cb(record._id)}
              icon={<EyeOutlined />}
            >
              Consulter
            </Button>
          </div>
        ),
      },
    ];
  };

  return (
    <Spin spinning={contentIsLoading}>
      <div className="section-content">
        <div style={{ display: "flex" }}>
          <div style={{ minWidth:"200px"}}>
            <Title style={{ marginBottom: "20px" }} level={5}>
              Liste des participants
            </Title>
          </div>
          <div style={{ width: "100%" }}>
            <Search
              placeholder="Chercher un volontaire"
              onChange={onSearch}
              enterButton
              style={{ width: "30%" }}
            />
          </div>
          <div>
            {user && user.type === 1 ? (
              <Button
                onClick={() => setShowAdd(true)}
                shape="round"
                className="m-top-medium m-bottom-large"
                type="primary"
                size="medium"
                style={{ float: "right", marginTop: "-2px" }}
                icon={<PlusOutlined />}
              >
                Ajouter un participant
              </Button>
            ) : null}
          </div>
        </div>
        <Table
          columns={columns(selectPatientAsync)}
          dataSource={filtredList.length ? filtredList : patientsList}
        />
      </div>
    </Spin>
  );
};

PatientsList.propTypes = {
  patientsList: PropTypes.array.isRequired,
  getAllPatientsAsync: PropTypes.func.isRequired,
  selectPatientAsync: PropTypes.func.isRequired,
  selectedPatient: PropTypes.object,
  contentIsLoading: PropTypes.bool.isRequired,
  selectPatient: PropTypes.func.isRequired,
  setPatientsList: PropTypes.func.isRequired,
  user: PropTypes.object,
};

PatientsList.defaultProps = {
  selectedPatient: null,
  user: null,
};

const stateToProps = (state) => ({
  user: state.auth.user,
  patientsList: state.patients.patientsList,
  selectedPatient: state.patients.selectedPatient,
  contentIsLoading: state.loading.contentIsLoading,
});

const dispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getAllPatientsAsync,
      selectPatientAsync,
      selectPatient,
      setPatientsList,
    },
    dispatch
  );

export default withRouter(connect(stateToProps, dispatchToProps)(PatientsList));

export default {
  dateVisite: {
    name: "dateVisite",
    label:"Date visite :",
    rules: ["validDate", "futurDate"],
    type: "input",
    inputStyle: { width: "min-content" },
    placeholder: "e.g: 01/01/2000",
    strongLabel: true,
  },
  initial: {
    name: "initial",
    rules: ["required"],
    type: "input",
    label: "Initiales",
    strongLabel: true,
  },
  IVSd: {
    name: "IVSd",
    label: "IVSd : epaisseur du septum en diastole",
    rules: ["required"],
    type: "inputNumber",
    unity: "mm",
    warning: true,
    palceholder: "6-23 mm",
    strongLabel: true,
  },
  LVIDd: {
    name: "LVIDd",
    label: "LVIDd : dimension interne du VG en diastole",
    rules: ["required"],
    type: "inputNumber",
    unity: "mm",
    warning: true,
    palceholder: "37-100 mm",
    strongLabel: true,
  },
  LVIDs: {
    name: "LVIDs",
    label: "LVIDs : dimension interne du VG en systole",
    rules: ["required"],
    type: "inputNumber",
    unity: "mm",
    warning: true,
    palceholder: "22-75 mm",
    strongLabel: true,
  },
  LVPWd: {
    name: "LVPWd",
    label:
      "LVPWd : épaisseur de la paroi postérieure du ventricule gauche en diastole",
    rules: ["required"],
    type: "inputNumber",
    unity: "mm",
    warning: true,
    palceholder: "22-75 mm",
    strongLabel: true,
  },
  LVEDV: {
    name: "LVEDV",
    label: "LVEDV MOD (Singleplane): volume du ventricule gauche en diastole",
    rules: ["required"],
    type: "inputNumber",
    unity: "mL",
    warning: true,
    palceholder: "50-300 mL",
    strongLabel: true,
  },
  LVESV: {
    name: "LVESV",
    label: "LVESV MOD (Singleplane): volume du ventricule gauche en systole ",
    rules: ["required"],
    type: "inputNumber",
    unity: "mL",
    warning: true,
    palceholder: "18-115 mL",
    strongLabel: true,
  },
  LVEF: {
    name: "LVEF",
    label: "LVEF MOD (Singleplane): fraction d”ejection du ventricule gauche",
    rules: ["required"],
    type: "inputNumber",
    unity: "%",
    warning: true,
    palceholder: "15-75 %",
    strongLabel: true,
  },
  LAESV: {
    name: "LAESV",
    label: "LAESV MOD (Singleplane): volume de l’oreillette gauche",
    rules: ["required"],
    type: "inputNumber",
    unity: "mL",
    warning: true,
    palceholder: "40-300 mL",
    strongLabel: true,
  },
  RVIDd: {
    name: "RVIDd",
    label: "RVIDd: dimension interne du ventricule droit en diastole",
    rules: ["required"],
    type: "inputNumber",
    unity: "mm",
    warning: true,
    palceholder: "20-100 mm",
    strongLabel: true,
  },
  RAarea: {
    name: "RAarea",
    label: "RAarea: surface oreillette droite",
    rules: ["required"],
    type: "inputNumber",
    unity: "cm 2",
    warning: true,
    //palceholder: "20-100 mm",
    strongLabel: true,
  },
  TAPSE: {
    name: "TAPSE",
    label: "TAPSE",
    rules: [""],
    type: "inputNumber",
    unity: "mm",
    warning: true,
    palceholder: "2-20 mm",
    strongLabel: true,
  },
  TR: {
    name: "TR",
    label: "TR: Vitesse de régurgitation tricuspide",
    rules: [""],
    type: "inputNumber",
    unity: "m/s",
    warning: true,
    palceholder: "0-20 m/s",
    strongLabel: true,
  },
  LVEDVA2C: {
    name: "LVEDVA2C",
    label: "LVEDV MOD (Singleplane): volume du ventricule gauche en diastole",
    rules: [""],
    type: "inputNumber",
    unity: "mL",
    warning: true,
    palceholder: "50-300 mL",
    strongLabel: true,
  },
  LVESVA2C: {
    name: "LVESVA2C",
    label: "LVESV MOD (Singleplane): volume du ventricule gauche en systole",
    rules: [""],
    type: "inputNumber",
    unity: "mL",
    warning: true,
    palceholder: "18-115 mL",
    strongLabel: true,
  },
  LVEFA2C: {
    name: "LVEFA2C",
    label: "LVEF MOD (Singleplane): fraction d’ejection du ventricule gauche",
    rules: [""],
    type: "inputNumber",
    unity: "%",
    warning: true,
    palceholder: "15-75 %",
    strongLabel: true,
  },
  LAESVA2C: {
    name: "LAESVA2C",
    label: "LAESV MOD (Singleplane): volume de l’oreillette gauche en systole",
    rules: [""],
    type: "inputNumber",
    unity: "mL",
    warning: true,
    palceholder: "40-300 mL",
    strongLabel: true,
  },
  MVEA4C: {
    name: "MVEA4C",
    label: "MV-E onde E valve mitrale",
    rules: [""],
    unity: "m/s",
    type: "inputNumber",
    // unity: "mL",
    //warning: true,
    //palceholder: "40-300 mL",
    strongLabel: true,
  },
  MVAA4C: {
    name: "MVAA4C",
    label: "MV-A onde A valve mitrale",
    rules: [""],
    unity: "m/s",
    type: "inputNumber",
    strongLabel: true,
  },
  dect: {
    name: "dect",
    label: "temps de décélération",
    rules: [""],
    type: "inputNumber",
    unity: "ms",
    strongLabel: true,
  },
  lateral: {
    name: "lateral",
    label: "e'lateral",
    rules: [""],
    unity: "cm/s",
    type: "inputNumber",
    //unity: "ms",
    strongLabel: true,
  },
  septal: {
    name: "septal",
    label: "e'septal",
    rules: [""],
    unity: "cm/s",
    type: "inputNumber",
    //unity: "ms",
    strongLabel: true,
  },
  peniblie: {
    name: "peniblie",
    label: "Comment fut la pénibilité de cet examen ?",
    strongLabel: true,
    style: { maxWidth: "80%", margin: "auto" },
    rules: [""],
    type: "radio",
    radioFields: [
      { label: "pas pénible", value: "pas pénible" },
      { label: "peu pénible", value: "peu pénible" },
      { label: "assez pénible", value: "assez pénible" },
      { label: "très pénible", value: "très pénible" },
    ],
  },
  explicationDebut: {
    name: "explicationDebut",
    label:
      "Comment furent les explications données par le/la cardiologue avant le début de l’examen ?",
    strongLabel: true,
    style: { maxWidth: "80%", margin: "auto" },
    rules: [""],
    type: "radio",
    radioFields: [
      { label: "très compréhensible", value: "très compréhensible" },
      { label: "assez compréhensible", value: "assez compréhensible" },
      { label: "peu pénible", value: "peu pénible" },
      { label: "incompréhensible", value: "incompréhensible" },
    ],
  },
  mal: {
    name: "mal",
    label: "Avez-vous eu mal pendant l’examen ?",
    strongLabel: true,
    style: { maxWidth: "80%", margin: "auto" },
    rules: [""],
    type: "radio",
    radioFields: [
      { label: "pas mal", value: "pas mal" },
      { label: "peu mal", value: "peu mal" },
      { label: "assez mal", value: "assez mal" },
      { label: "très mal", value: "très mal" },
    ],
  },
  explicationFin: {
    name: "explicationFin",
    label:
      "Comment furent les explications données par le/la cardiologue à la fin de l’examen",
    strongLabel: true,
    style: { maxWidth: "80%", margin: "auto" },
    rules: [""],
    type: "radio",
    radioFields: [
      { label: "très compréhensible", value: "très compréhensible" },
      { label: "assez compréhensible", value: "assez compréhensible" },
      { label: "peu pénible", value: "peu pénible" },
      { label: "incompréhensible", value: "incompréhensible" },
    ],
  },
  examenDomicile: {
    name: "examenDomicile",
    label: "Faites-vous confiance à cet examen fait par le/la cardiologue ?",
    strongLabel: true,
    style: { maxWidth: "80%", margin: "auto" },
    rules: [""],
    type: "radio",
    radioFields: [
      { label: "complétement", value: "complétement" },
      { label: "assez", value: "assez" },
      { label: "peu", value: "peu" },
      { label: "pas du tout", value: "pas du tout" },
    ],
  },
  echoComfort: {
    name: "echoComfort",
    label:
      "Considérez-vous que le/la cardiologue est à l’aise avec l’échographe ?",
    strongLabel: true,
    style: { maxWidth: "80%", margin: "auto" },
    rules: [""],
    type: "radio",
    radioFields: [
      { label: "complétement", value: "complétement" },
      { label: "assez", value: "assez" },
      { label: "peu", value: "peu" },
      { label: "pas du tout", value: "pas du tout" },
    ],
  },
  refaireExam: {
    name: "refaireExam",
    label:
      "Si vous avez besoin de refaire cet examen, le referez-vous chez le cardiologue ?",
    strongLabel: true,
    style: { maxWidth: "80%", margin: "auto" },
    rules: [""],
    type: "radio",
    radioFields: [
      { label: "complétement", value: "complétement" },
      { label: "assez", value: "assez" },
      { label: "peu", value: "peu" },
      { label: "pas du tout", value: "pas du tout" },
    ],
  },
  BRefaireExam: {
    name: "BRefaireExam",
    label: `Si vous avez besoin de refaire cet examen et qu’on vous donne le choix, choisirez-vous de
    le refaire à la maison ou chez le cardiologue ?`,
    strongLabel: true,
    style: { maxWidth: "80%", margin: "auto" },
    rules: [""],
    type: "radio",
    radioFields: [
      { label: "complétement", value: "complétement" },
      { label: "plutôt oui", value: "plutôt oui" },
      { label: "plutôt non", value: "plutôt non" },
      { label: "pas du tout", value: "pas du tout" },
    ],
  },
  trajetPenibilite: {
    name: "trajetPenibilite",
    label: `Est-ce que la pénibilité du trajet entre chez vous et le cabinet/hôpital était ?`,
    strongLabel: true,
    style: { maxWidth: "80%", margin: "auto" },
    rules: [""],
    type: "radio",
    radioFields: [
      { label: "pas pénible", value: "pas pénible" },
      { label: "peu pénible", value: "peu pénible" },
      { label: "assez pénible", value: "assez pénible" },
      { label: "très pénible", value: "très pénible" },
    ],
  },
  salleAttente: {
    name: "salleAttente",
    label: `Est-ce que l’attente dans la salle d’attente du cardiologue était`,
    strongLabel: true,
    style: { maxWidth: "80%", margin: "auto" },
    rules: [""],
    type: "radio",
    radioFields: [
      { label: "pas pénible", value: "pas pénible" },
      { label: "peu pénible", value: "peu pénible" },
      { label: "assez pénible", value: "assez pénible" },
      { label: "très pénible", value: "très pénible" },
    ],
  },
  satisfaction: {
    name: "satisfaction",
    rules: [""],
    type: "inputNumber",
    label: `Sur une échelle de 0 (pas du tout) à 10 (oui avec enthousiasme), quelle est la probabilité
    que vous recommandiez cet examen à l’hôpital/cabinet à un ami ou à un membre de votre famille ?`,
    placeholder: "échelle de satisfaction",
    strongLabel: true,
  },
  TAEcho: {
    name: "TAEcho",
    rules: ["required"],
    type: "input",
    label: "Type d’appareillage d’échocardiographie",
    strongLabel: true,
  },
  finHeureVdeux: {
    name: "finHeureVdeux",
    label: "Heure de fin de la visite V2 :",
    rules: ["required"],
    type: "timepicker",
    strongLabel: true,
  },
  dateNextRDVCardio: {
    name: "dateNextRDVCardio",
    label:
      "Date du prochain RDV pour une échocardiographie chez le cardiologue au centre investigateur :",
    type: "datetime",
    rules: ["required"],
    strongLabel: true,
  },
  dateNextRDVCardioHeure: {
    name: "dateNextRDVCardio",
    label:
      "Heure de l'échocardiographie chez le cardiologue au centre investigateur :",
    type: "datetime",
    rules: ["required"],
    strongLabel: true,
  },
  traitements: {
    // ICD: {
    ICD: {
      name: "ICD",
      label: "Pace maker ou autre implant",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    ICDdateDebut: {
      name: "ICDdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    ICDdateFin: {
      name: "ICDdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // BB: {
    BB: {
      name: "BB",
      label: "Bêta-bloquant",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    BBdateDebut: {
      name: "BBdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    BBdateFin: {
      name: "BBdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // ACE: {
    ACE: {
      name: "ACE",
      label: "inhibiteur de l'enzyme de conversion de l'angiotensine (ACEi)",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    ACEdateDebut: {
      name: "ACEdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    ACEdateFin: {
      name: "ACEdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // ARB: {
    ARB: {
      name: "ARB",
      label: "bloqueur des récepteurs de l'angiotensine (ARB)",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    ARBdateDebut: {
      name: "ARBdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    ARBdateFin: {
      name: "ARBdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // ARN: {
    ARN: {
      name: "ARN",
      label:
        "inhibiteur de la néprilysine des récepteurs de l'angiotensine (ARNi)",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    ARNdateDebut: {
      name: "ARNdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    ARNdateFin: {
      name: "ARNdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // ARM: {
    ARM: {
      name: "ARM",
      label: "Antagoniste des récepteurs minéralocorticoïdes",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    ARMdateDebut: {
      name: "ARMdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    ARMdateFin: {
      name: "ARMdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // IV: {
    IV: {
      name: "IV",
      label: "Ivabradine",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    IVdateDebut: {
      name: "IVdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    IVdateFin: {
      name: "IVdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // SGL: {
    SGL: {
      name: "SGL",
      label: "inhibiteur du co-transporteur sodium-glucose-2 (SGLT2i)",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    SGLdateDebut: {
      name: "SGLdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    SGLdateFin: {
      name: "SGLdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // DA: {
    DA: {
      name: "DA",
      label: "Diurétiques de l'anse",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    DAdateDebut: {
      name: "DAdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    DAdateFin: {
      name: "DAdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // DTH: {
    DTH: {
      name: "DTH",
      label: "Diurétiques thiazidiques",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    DTHdateDebut: {
      name: "DTHdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    DTHdateFin: {
      name: "DTHdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // ST: {
    ST: {
      name: "ST",
      label: "Statines",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    STdateDebut: {
      name: "STdateDebut",
      label: "Date debut Statines",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    STdateFin: {
      name: "STdateFin",
      label: "Date fin Statines",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // TA: {
    TA: {
      name: "TA",
      label: "Traitement antiplaquettaire",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    TAdateDebut: {
      name: "TAdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    TAdateFin: {
      name: "TAdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
  },
  signes: {
    // RP: {
    RP: {
      name: "RP",
      label: "Rales pulmonaires",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    RPdateDebut: {
      name: "RPdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    RPdateFin: {
      name: "RPdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // ER: {
    ER: {
      name: "ER",
      label: "Essoufflement au repos",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    ERdateDebut: {
      name: "ERdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    ERdateFin: {
      name: "ERdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // EE: {
    EE: {
      name: "EE",
      label: "Essoufflement à l’effort",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    EEdateDebut: {
      name: "EEdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    EEdateFin: {
      name: "EEdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // TN: {
    TN: {
      name: "TN",
      label: "Toux nocturne",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    TNdateDebut: {
      name: "TNdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    TNdateFin: {
      name: "TNdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // RTE: {
    RTE: {
      name: "RTE",
      label: "Réduction de la tolérance à l’exercice",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    RTEdateDebut: {
      name: "RTEdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    RTEdateFin: {
      name: "RTEdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // ANG: {
    ANG: {
      name: "ANG",
      label: "Angine",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    ANGdateDebut: {
      name: "ANGdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    ANGdateFin: {
      name: "ANGdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // JPV: {
    JPV: {
      name: "JPV",
      label: "JPV (jugular venous pulse) augmenté",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    JPVdateDebut: {
      name: "JPVdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    JPVdateFin: {
      name: "JPVdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // TBC: {
    TBC: {
      name: "TBC",
      label: "Troisième bruit cardiaque",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    TBCdateDebut: {
      name: "TBCdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    TBCdateFin: {
      name: "TBCdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // RS: {
    RS: {
      name: "RS",
      label: "Respiration sifflante",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    RSdateDebut: {
      name: "RSdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    RSdateFin: {
      name: "RSdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // OP: {
    OP: {
      name: "OP",
      label: "œdème périphérique",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    OPdateDebut: {
      name: "OPdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    OPdateFin: {
      name: "OPdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // DPN: {
    DPN: {
      name: "DPN",
      label: "dyspnée paroxystique nocturne",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    DPNdateDebut: {
      name: "DPNdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    DPNdateFin: {
      name: "DPNdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // ORT: {
    ORT: {
      name: "ORT",
      label: "orthopnée",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    ORTdateDebut: {
      name: "ORTdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    ORTdateFin: {
      name: "ORTdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // CXR: {
    CXR: {
      name: "CXR",
      label:
        "Autres explorations cardiaques : Radiographie pulmonaire (CXR), échocardiographie d'effort",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    CXRdateDebut: {
      name: "CXRdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    CXRdateFin: {
      name: "CXRdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
  },
  antecedents: {
    HA: {
      name: "HA",
      label: "Hypertension artérielle",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    HAdateDebut: {
      name: "HAdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    HAdateFin: {
      name: "HAdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    DIABETE: {
      name: "DIABETE",
      label: "Diabète",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    DiabetedateDebut: {
      name: "DiabetedateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    DiabetedateFin: {
      name: "DiabetedateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    MC: {
      name: "MC",
      label: "Maladie cardiaque",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    McdateDebut: {
      name: "McdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    McdateFin: {
      name: "McdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    MR: {
      name: "MR",
      label: "Maladie rénale",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    MrdateDebut: {
      name: "MrdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    MrdateFin: {
      name: "MrdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    MF: {
      name: "MF",
      label: "Maladie du foie",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    MfdateDebut: {
      name: "MfdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    MfdateFin: {
      name: "MfdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    MC: {
      name: "MC",
      label: "Etes vous malade d'une maladie coronarienne ?",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    // IDM: {
    IDM: {
      name: "IDM",
      label: "Infarctus du myocarde",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    IDMdateDebut: {
      name: "IDMdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    IDMdateFin: {
      name: "IDMdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // PCI: {
    PCI: {
      name: "PCI",
      label: "Angioplastie coronarienne percutanée PCI",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    PCIdateDebut: {
      name: "PCIdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    PCIdateFin: {
      name: "PCIdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // CABG: {
    CABG: {
      name: "CABG",
      label: "CABG",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    CABGdateDebut: {
      name: "CABGdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    CABGdateFin: {
      name: "CABGdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // PC: {
    PC: {
      name: "PC",
      label: "Pontage coronarien",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    PCdateDebut: {
      name: "PCdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    PCdateFin: {
      name: "PCdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // ICP: {
    ICP: {
      name: "ICP",
      label: "Intervention coronarienne percutanée",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    ICPdateDebut: {
      name: "ICPdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    ICPdateFin: {
      name: "ICPdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // AFA: {
    AFA: {
      name: "AFA",
      label: "Antécédents de fibrillation auriculaire",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    AFAdateDebut: {
      name: "AFAdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    AFAdateFin: {
      name: "AFAdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // AVC: {
    AVC: {
      name: "AVC",
      label: "AVC antérieur",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    AVCdateDebut: {
      name: "AVCdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    AVCdateFin: {
      name: "AVCdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // MVAP: {
    MVAP: {
      name: "MVAP",
      label: "Maladie vasculaire artérielle périphérique",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    MVAPdateDebut: {
      name: "MVAPdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    MVAPdateFin: {
      name: "MVAPdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // DIABETE: {
    DIABETE: {
      name: "DIABETE",
      label: "Diabéte",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    type: {
      name: "type",
      label: "Si oui",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Type I", value: "Type I" },
        { label: "Type II", value: "Type II" },
      ],
    },
    DIABETEdateDebut: {
      name: "DIABETEdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    DIABETEdateFin: {
      name: "DIABETEdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // HA: {
    HA: {
      name: "HA",
      label: "Hypertension artérielle",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    HAdateDebut: {
      name: "HAdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    HAdateFin: {
      name: "HAdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // CANCER: {
    CANCER: {
      name: "CANCER",
      label: "Cancer",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    CANCERdateDebut: {
      name: "CANCERdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    CANCERdateFin: {
      name: "CANCERdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // BPCO: {
    BPCO: {
      name: "BPCO",
      label: "BPCO (Broncho Pneumopathie Chronique Obstructive)",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    BPCOdateDebut: {
      name: "CANCERdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    BPCOdateFin: {
      name: "CANCERdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // MRC: {
    MRC: {
      name: "MRC",
      label: "Maladie rénale chronique",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    MRCdateDebut: {
      name: "CANCERdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    MRCdateFin: {
      name: "CANCERdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // MDF: {
    MDF: {
      name: "MDF",
      label: "Maladie du foie",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    MDFdateDebut: {
      name: "MDFdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    MDFdateFin: {
      name: "MDFdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // DEPRESSION: {
    DEPRESSION: {
      name: "DEPRESSION",
      label: "Dépression",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    DEPRESSIONdateDebut: {
      name: "DEPRESSIONdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    DEPRESSIONdateFin: {
      name: "DEPRESSIONdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
    // DT: {
    DT: {
      name: "DT",
      label: "Dysfonctionnement thyroïdien",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    DTdateDebut: {
      name: "DTdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    DTdateFin: {
      name: "DTdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    // },
  },
  transport: {
    name: "transport",
    label: "Mode de transport aller-retour au centre ",
    strongLabel: true,
    style: { maxWidth: "80%", margin: "auto" },
    rules: ["required"],
    type: "radio",
    radioFields: [
      { label: "Marche", value: "Marche" },
      {
        label: "Voiture Privée Louée (P. Ex. Taxi)",
        value: "Voiture Privée Louée (P. Ex. Taxi)",
      },
      { label: "Ambulance", value: "Ambulance" },
      {
        label: "Voiture Personnelle/Familiale",
        value: "Voiture Personnelle/Familiale",
      },
      { label: "Transport En Commun", value: "Transport En Commun" },
      { label: "Vélo", value: "Vélo" },
      { label: "Autre", value: "Autre" },
    ],
  },
  transportAutre: {
    name: "transportAutre",
    rules: ["required"],
    type: "input",
    label: "Autre (Précisez)",
    strongLabel: true,
  },
  consentDate: {
    name: "consentDate",
    label: "Date de signature du consentement (jj/mm/aaaa)",
    rules: ["validDate", "futurDate"],
    type: "input",
    inputStyle: { width: "min-content" },
    placeholder: "e.g: 01/01/2000",
    strongLabel: true,
  },
  inclusionDate: {
    name: "inclusionDate",
    label: `Date d'inclusion (jj/mm/aaaa)`,
    rules: ["validDate", "futurDate"],
    type: "input",
    inputStyle: { width: "min-content" },
    placeholder: "e.g: 01/01/2000",
    strongLabel: true,
  },
  consultationDate: {
    name: "consultationDate",
    label: "Date de la consultation",
    type: "datetime",
    rules: ["required"],
    strongLabel: true,
  },
  admissionDate: {
    name: "admissionDate",
    label: "Date d'admission",
    rules: ["required"],
    type: "datetime",
    strongLabel: true,
  },
  systolique: {
    name: "systolique",
    rules: ["required"],
    type: "inputNumber",
    label: "Systolique",
    placeholder: "TA systolique",
    unity: "mmHg",
    strongLabel: true,
  },
  diastolique: {
    name: "diastolique",
    rules: ["required"],
    type: "inputNumber",
    label: "Diastolique",
    placeholder: "TA diastolique",
    unity: "mmHg",
    strongLabel: true,
  },
  // systoliqueRight: {
  //   name: "systoliqueRight",
  //   rules: ["required"],
  //   type: "inputNumber",
  //   label: "PAS membre droite",
  //   placeholder: "TA systolique",
  //   strongLabel: true,
  // },
  // diastoliqueRight: {
  //   name: "diastoliqueRight",
  //   rules: ["required"],
  //   type: "inputNumber",
  //   label: "PAD membre droite",
  //   placeholder: "TA diastolique",
  //   strongLabel: true,
  // },
  bodyTemperature: {
    name: "bodyTemperature",
    rules: ["required"],
    type: "inputNumber",
    label: "Température (°C)",
    strongLabel: true,
  },
  adminstratedDate: {
    name: "adminstratedDate",
    label: "Date d'administration",
    type: "datetime",
    rules: ["required"],
    strongLabel: true,
  },
  administratedHour: {
    name: "administratedHour",
    rules: ["required"],
    type: "timepicker",
    label: "Heure d'administration",
    strongLabel: true,
  },
  inclusion: {
    age: {
      name: "age",
      label: "Âge ≥ 50 ans (adulte)",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    ic: {
      name: "ic",
      label: "Présence de signe et/ou symptôme d’insuffisance cardiaque",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    pavd: {
      name: "pavd",
      label: `≥ 2 facteurs de risque cardiovasculaire, défini comme étant un diagnostic
            d'hypertension, de diabète ou d'un antécédent d'infarctus du myocarde, de
            tabagisme, d'hypercholestérolémie ou de PAVD avant de signer un
            consentement éclairé.`,
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    // goodHealth: {
    //     name: 'goodHealth',
    //     label:
    // "Volontaires de sexe masculin et féminin en bonne santé selon les antécédents médicaux et l'examen physique et si nous ne disposons d’aucune contre indication pour le sexe féminin.",
    //     strongLabel: true,
    //     style: { maxWidth: '80%', margin: 'auto' },
    //     rules: [''],
    //     type: 'radio',
    //     radioFields: [
    //         { label: 'Oui', value: 'Oui' },
    //         { label: 'Non', value: 'Non' },
    //     ],

    // },
    // contraception: {
    //     name: 'contraception',
    //     label: 'Contraception efficace pour les volontaires de sexe féminin',
    //     strongLabel: true,
    //     style: { maxWidth: '80%', margin: 'auto' },
    //     rules: [''],
    //     type: 'radio',
    //     radioFields: [
    //         { label: 'Oui', value: 'Oui' },
    //         { label: 'Non', value: 'Non' },
    //     ],
    // },
    // signedConsent: {
    //     name: 'signedConsent',
    //     label: 'Consentement éclairé signé',
    //     strongLabel: true,
    //     style: { maxWidth: '80%', margin: 'auto' },
    //     rules: [''],
    //     type: 'radio',
    //     radioFields: [
    //         { label: 'Oui', value: 'Oui' },
    //         { label: 'Non', value: 'Non' },
    //     ],
    // },
    // smoker: {
    //     name: 'smoker',
    //     label:
    // 'Non-fumeurs ou modérément fumeurs (inférieur à 10 cigarettes par jour) avec un arrêt la veille de l’hospitalisation',
    //     strongLabel: true,
    //     style: { maxWidth: '80%', margin: 'auto' },
    //     rules: [''],
    //     type: 'radio',
    //     radioFields: [
    //         { label: 'Oui', value: 'Oui' },
    //         { label: 'Non', value: 'Non' },
    //     ],
    // },
    // alcohol: {
    //     name: 'alcohol',
    //     label: 'Abstème (pas de consommation d’alcool)',
    //     strongLabel: true,
    //     style: { maxWidth: '80%', margin: 'auto' },
    //     rules: [''],
    //     type: 'radio',
    //     radioFields: [
    //         { label: 'Oui', value: 'Oui' },
    //         { label: 'Non', value: 'Non' },
    //     ],
    // },
    // BMI: {
    //     name: 'BMI',
    //     label: 'Volontaires ayant une IMC entre 18,5 et 30 kg/m2',
    //     strongLabel: true,
    //     style: { maxWidth: '80%', margin: 'auto' },
    //     rules: [''],
    //     type: 'radio',
    //     radioFields: [
    //         { label: 'Oui', value: 'Oui' },
    //         { label: 'Non', value: 'Non' },
    //     ],
    // },
    // biology: {
    //     name: 'biology',
    //     label: 'Résultats des examens biologiques normaux',
    //     strongLabel: true,
    //     style: { maxWidth: '80%', margin: 'auto' },
    //     rules: [''],
    //     type: 'radio',
    //     radioFields: [
    //         { label: 'Oui', value: 'Oui' },
    //         { label: 'Non', value: 'Non' },
    //     ],
    // },
    // followProcedure: {
    //     name: 'followProcedure',
    //     label:
    // "Volontaires disposés à se rendre disponibles pour la durée de l'étude et sont disposés à suivre les procédures de l'étude",
    //     strongLabel: true,
    //     style: { maxWidth: '80%', margin: 'auto' },
    //     rules: [''],
    //     type: 'radio',
    //     radioFields: [
    //         { label: 'Oui', value: 'Oui' },
    //         { label: 'Non', value: 'Non' },
    //     ],
    // },
  },
  exclusion: {
    anotherTrial: {
      name: "anotherTrial",
      label: "Participation dans une autre étude clinique",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    age: {
      name: "age",
      label: "Âge <50 ans",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    distance: {
      name: "distance",
      label:
        "Habitation : Distance par rapport au centre investigateur > 30 km",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    dic: {
      name: "dic",
      label: `Personne ayant déjà reçu un diagnostic d'Insuffisance Cardiaque`,
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    ipe: {
      name: "ipe",
      label: `L'incapacité du participant à se conformer aux procédures de l'étude ou à
            fournir un consentement éclairé`,
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    ilFormulaireConsentement: {
      name: "ilFormulaireConsentement",
      label: `L'Incapacité à lire et à comprendre le formulaire de consentement éclairé ou à
            comprendre l'explication des investigateurs.`,
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    // pharmaceuticals: {
    //     name: 'pharmaceuticals',
    //     label: 'Prise concomitante de médicaments',
    //     strongLabel: true,
    //     style: { maxWidth: '80%', margin: 'auto' },
    //     rules: [''],
    //     type: 'radio',
    //     radioFields: [
    //         { label: 'Oui', value: 'Oui' },
    //         { label: 'Non', value: 'Non' },
    //     ],
    // },
    // chronicalDisease: {
    //     name: 'chronicalDisease',
    //     label:
    // 'Volontaires présentant des signes cliniques de maladies aiguës ou porteurs de maladies chroniques',
    //     strongLabel: true,
    //     style: { maxWidth: '80%', margin: 'auto' },
    //     rules: [''],
    //     type: 'radio',
    //     radioFields: [
    //         { label: 'Oui', value: 'Oui' },
    //         { label: 'Non', value: 'Non' },
    //     ],
    // },
    // allergy: {
    //     name: 'allergy',
    //     label:
    // "Allergies connues à la lévocétirizine ou à d'autres dérivés de la pipérazine",
    //     strongLabel: true,
    //     style: { maxWidth: '80%', margin: 'auto' },
    //     rules: [''],
    //     type: 'radio',
    //     radioFields: [
    //         { label: 'Oui', value: 'Oui' },
    //         { label: 'Non', value: 'Non' },
    //     ],
    // },
    // anomalyECG: {
    //     name: 'anomalyECG',
    //     label: "Une anomalie de l'ECG à 12 dérivations",
    //     strongLabel: true,
    //     style: { maxWidth: '80%', margin: 'auto' },
    //     rules: [''],
    //     type: 'radio',
    //     radioFields: [
    //         { label: 'Oui', value: 'Oui' },
    //         { label: 'Non', value: 'Non' },
    //     ],
    // },
    // HTA: {
    //     name: 'HTA',
    //     label:
    // 'Patients hypertendus (tension artérielle systolique en décubitus dorsal supérieure ou égale à 140 mmHg ou tension artérielle diastolique en décubitus dorsal supérieure ou égale à 90 mmHg)',
    //     strongLabel: true,
    //     style: { maxWidth: '80%', margin: 'auto' },
    //     rules: [''],
    //     type: 'radio',
    //     radioFields: [
    //         { label: 'Oui', value: 'Oui' },
    //         { label: 'Non', value: 'Non' },
    //     ],
    // },
    // antecedents: {
    //     name: 'antecedents',
    //     label:
    // "Antécédents de troubles cardiovasculaires, respiratoires, hépatiques, rénaux, gastro-intestinaux, endocriniens, hématologiques, psychiatriques ou neurologiques susceptibles d'altérer significativement l'absorption, le métabolisme ou l'élimination des médicaments ou de constituer un risque lors de la prise du médicament à l'étude ou d’interférer avec l'interprétation des données",
    //     strongLabel: true,
    //     style: { maxWidth: '80%', margin: 'auto' },
    //     rules: [''],
    //     type: 'radio',
    //     radioFields: [
    //         { label: 'Oui', value: 'Oui' },
    //         { label: 'Non', value: 'Non' },
    //     ],
    // },
    // drugs: {
    //     name: 'drugs',
    //     label: 'Consommation régulière des drogues ou des stupéfiants',
    //     strongLabel: true,
    //     style: { maxWidth: '80%', margin: 'auto' },
    //     rules: [''],
    //     type: 'radio',
    //     radioFields: [
    //         { label: 'Oui', value: 'Oui' },
    //         { label: 'Non', value: 'Non' },
    //     ],
    // },
    // usageDuringTrial: {
    //     name: 'usageDuringTrial',
    //     label:
    // "Utilisation ou l'intention d'utiliser des médicaments 7 et 14 jours respectivement avant l'administration",
    //     strongLabel: true,
    //     style: { maxWidth: '80%', margin: 'auto' },
    //     rules: [''],
    //     type: 'radio',
    //     radioFields: [
    //         { label: 'Oui', value: 'Oui' },
    //         { label: 'Non', value: 'Non' },
    //     ],
    // },
    // VIH: {
    //     name: 'VIH',
    //     label: 'Volontaires avec tests positifs au VIH, hépatite B ou C',
    //     strongLabel: true,
    //     style: { maxWidth: '80%', margin: 'auto' },
    //     rules: [''],
    //     type: 'radio',
    //     radioFields: [
    //         { label: 'Oui', value: 'Oui' },
    //         { label: 'Non', value: 'Non' },
    //     ],
    // },
    // pregnant: {
    //     name: 'pregnant',
    //     label: 'Femme enceinte ou allaitante',
    //     strongLabel: true,
    //     style: { maxWidth: '80%', margin: 'auto' },
    //     rules: [''],
    //     type: 'radio',
    //     radioFields: [
    //         { label: 'Oui', value: 'Oui' },
    //         { label: 'Non', value: 'Non' },
    //     ],
    // },
  },
  biologicalExamination: {
    HA: {
      name: "HA",
      label: "Hypertension artérielle",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    HAdateDebut: {
      name: "HAdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    HAdateFin: {
      name: "HAdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    DIABETE: {
      name: "DIABETE",
      label: "Diabète",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    DiabetedateDebut: {
      name: "DiabetedateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    DiabetedateFin: {
      name: "DiabetedateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    MC: {
      name: "MC",
      label: "Maladie cardiaque",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    MCdateDebut: {
      name: "MCdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    MCdateFin: {
      name: "MCdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    MR: {
      name: "MR",
      label: "Maladie rénale",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    MRdateDebut: {
      name: "MRdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    MRdateFin: {
      name: "MRdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    MF: {
      name: "MF",
      label: "Maladie du foie",
      strongLabel: true,
      style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    MFdateDebut: {
      name: "MFdateDebut",
      label: "Date debut",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    MFdateFin: {
      name: "MFdateFin",
      label: "Date fin",
      rules: [""],
      type: "input",
      inputStyle: { width: "min-content" },
      placeholder: "e.g: 01/01/2000",
      strongLabel: true,
    },
    "K+": {
      name: "K+",
      label: "potassium (K) :",
      rules: [""],
      type: "inputNumber",
      strongLabel: true,
      unity: "mmol/L",
    },
    "NA+": {
      name: "NA+",
      label: "NA+",
      rules: [""],
      type: "inputNumber",
      strongLabel: true,
      unity: "mmol/L",
    },
    "CL-": {
      name: "CL-",
      label: "Cl-",
      rules: [""],
      type: "inputNumber",
      strongLabel: true,
      unity: "mmol/L",
    },
    bicar: {
      name: "bicar",
      label: "Bicar",
      rules: [""],
      type: "inputNumber",
      strongLabel: true,
      unity: "mmol/L",
    },
    protides: {
      name: "protides",
      label: "Protides",
      rules: [""],
      type: "inputNumber",
      strongLabel: true,
      unity: "g/L",
    },
    Créatinine: {
      name: "Créatinine",
      label: "Créatinine",
      rules: [""],
      type: "inputNumber",
      unity: "umol/L",
      warning: false,
      placeholder: "[5-9]",
      strongLabel: true,
    },
    bilirubine: {
      name: "bilirubine",
      label: "Bilirubine",
      rules: [""],
      type: "inputNumber",
      strongLabel: true,
      unity: "mmol/L",
    },
    transmaniases: {
      name: "transmaniases",
      label: "Transmaniases",
      rules: [""],
      type: "inputNumber",
      strongLabel: true,
      unity: "UI/L",
    },
    gammaGT: {
      name: "gammaGT",
      label: "Gamma-GT",
      rules: [""],
      type: "inputNumber",
      strongLabel: true,
      unity: "UI/L",
    },
    phosphatases: {
      name: "phosphatases",
      label: "Phosphatases alcalines",
      rules: [""],
      type: "inputNumber",
      strongLabel: true,
      unity: "UI/L",
    },
    Cholesterole: {
      name: "Cholesterole",
      label: "Cholestérole total",
      rules: [""],
      type: "inputNumber",
      strongLabel: true,
      unity: "g/l",
    },
    triglycerides: {
      name: "triglycerides",
      label: "Triglycérides",
      rules: [""],
      type: "inputNumber",
      strongLabel: true,
      unity: "g/l",
    },
    acideUrique: {
      name: "acideUrique",
      label: "Acide urique",
      rules: [""],
      type: "inputNumber",
      strongLabel: true,
      unity: "mg/l",
    },
    albuminemie: {
      name: "albuminemie",
      label: "albuminémie",
      rules: [""],
      type: "inputNumber",
      strongLabel: true,
      unity: "mg/l",
    },
    GS: {
      name: ["GS"],
      label: "Groupe sanguin",
      rules: [""],
      type: "radio",
      radioFields: [
        { label: "A+", value: "A+" },
        { label: "A-", value: "A-" },
        { label: "B+", value: "B+" },
        { label: "B-", value: "B-" },
        { label: "AB+", value: "AB+" },
        { label: "AB-", value: "AB-" },
        { label: "O+", value: "O+" },
        { label: "O-", value: "O-" },
      ],
      strongLabel: true,
    },
    CRP: {
      name: "CRP",
      label: "CRP",
      rules: [""],
      type: "inputNumber",
      strongLabel: true,
      unity: "mg/l",
    },
    LDH: {
      name: "LDH",
      label: "LDH",
      rules: [""],
      type: "inputNumber",
      strongLabel: true,
      unity: "UI/l",
    },
    IL6: {
      name: "IL6",
      label: "IL6",
      rules: [""],
      type: "inputNumber",
      strongLabel: true,
      unity: "pg/ml",
    },
    //BILAN hémtologues
    NFS: {
        name: 'NFS',
        label: 'NFS',
        rules: [''],
        type: 'inputNumber',
        strongLabel: true,
    },
    HB: {
      name: "HB",
      label: "HB",
      rules: [""],
      type: "inputNumber",
      strongLabel: true,
      unity: "g/dl",
    },
    GR: {
      name: "GR",
      label: "Nombre de GR",
      rules: [""],
      type: "inputNumber",
      strongLabel: true,
      unity: "Millions par microlitre",
    },
    hématocrite: {
      name: "hématocrite",
      label: "Hématocrite",
      rules: [""],
      type: "inputNumber",
      strongLabel: true,
      unity: "%",
    },
    leucocytes: {
      name: "leucocytes",
      label: "Leucocytes",
      rules: [""],
      type: "inputNumber",
      unity: "Millions par microlitre",
      warning: false,
      // placeholder: '[150-400]',
      strongLabel: true,
    },
    PNP: {
      name: "PNP",
      label: "Polynucléaires neutrophiles PNL",
      rules: [""],
      type: "inputNumber",
      strongLabel: true,
      unity: "Mille par microlitre",
    },
    Plaquettes: {
      name: "Plaquettes",
      label: "Plaquettes",
      rules: [""],
      type: "inputNumber",
      unity: "10e3/µL",
      warning: false,
      placeholder: "[150-400]",
      strongLabel: true,
    },

    // NFS: {
    //     name: 'NFS',
    //     label: 'NFS',
    //     rules: [''],
    //     type: 'inputNumber',
    //     strongLabel: true,

    // },
    //K: {
  
      // K: {
      //   name: "K",
      //   label: "Potassium (K)",
      //   rules: [""],
      //   type: "inputNumber",
      //   palceholder: "[3.50-5.10]",
      //   strongLabel: true,
      //   unity: "mmol/L",
      // },
      // Kdate: {
      //   name: "Kdate",
      //   label: "Date calcul potassium (k)",
      //   rules: ["futurDate"],
      //   type: "input",
      //   inputStyle: { width: "min-content" },
      //   placeholder: "e.g: 01/01/2000",
      //   strongLabel: true,
      // },
      // },
      // NA: {
      NA: {
        name: "NA",
        label: "sodium (Na)",
        rules: [""],
        type: "inputNumber",
        palceholder: "[135-145]",
        strongLabel: true,
        unity: "mmol/L",
      },
      NAdate: {
        name: "NAdate",
        label: "Date calcul sodium (Na)",
        rules: ["validDate", "futurDate"],
        type: "input",
        inputStyle: { width: "min-content" },
        placeholder: "e.g: 01/01/2000",
        strongLabel: true,
      },
      // },
      // Ca: {
      Ca: {
        name: "Ca",
        label: "calcium (Ca)",
        rules: [""],
        type: "inputNumber",
        placeholder: "[86-102]",
        strongLabel: true,
        unity: "mmol/L",
      },
      Cadate: {
        name: "Cadate",
        label: "Date calcul calcium (Ca)",
        rules: ["validDate", "futurDate"],
        type: "input",
        inputStyle: { width: "min-content" },
        placeholder: "e.g: 01/01/2000",
        strongLabel: true,
      },
      // },
      // Mg: {
      Mg: {
        name: "Mg",
        label: "magnésium (Mg)",
        rules: [""],
        type: "inputNumber",
        strongLabel: true,
        unity: "mmol/L",
      },
      Mgdate: {
        name: "Mgdate",
        label: "Date calcul magnésium (Mg)",
        rules: ["validDate", "futurDate"],
        type: "input",
        inputStyle: { width: "min-content" },
        placeholder: "e.g: 01/01/2000",
        strongLabel: true,
      },
      // },
      // Cl: {
      Cl: {
        name: "Cl",
        label: "chlore (Cl)",
        rules: [""],
        type: "inputNumber",
        strongLabel: true,
        unity: "mmol/L",
      },
      Cldate: {
        name: "Cldate",
        label: "Date calcul chlore (Cl)",
        rules: ["validDate", "futurDate"],
        type: "input",
        inputStyle: { width: "min-content" },
        placeholder: "e.g: 01/01/2000",
        strongLabel: true,
      },
      // },
      // CO3: {
      CO3: {
        name: "CO3",
        label: "bicarbonates (CO3)",
        rules: [""],
        type: "inputNumber",
        strongLabel: true,
        unity: "mmol/L",
      },
      CO3date: {
        name: "CO3date",
        label: "Date calcul bicarbonates (CO3)",
        rules: ["validDate", "futurDate"],
        type: "input",
        inputStyle: { width: "min-content" },
        placeholder: "e.g: 01/01/2000",
        strongLabel: true,
      },
      // },
      // eGFR: {
      eGFR: {
        name: "eGFR",
        label: "glomérulaire estimé (eGFR)",
        rules: [""],
        type: "inputNumber",
        strongLabel: true,
        unity: "mL/min /1.73 m²",
      },
      eGFRdate: {
        name: "eGFRdate",
        label: "Date calcul glomérulaire",
        rules: ["validDate", "futurDate"],
        type: "input",
        inputStyle: { width: "min-content" },
        placeholder: "e.g: 01/01/2000",
        strongLabel: true,
      },
      // },
      // cholestérol: {
      cholestérol: {
        name: "cholestérol",
        label: "glomérulaire estimé (eGFR)",
        rules: [""],
        type: "inputNumber",
        strongLabel: true,
        unity: "g/l",
      },
      cholestéroldate: {
        name: "cholestéroldate",
        label: "Date calcul cholestérol :",
        rules: ["validDate", "futurDate"],
        type: "input",
        inputStyle: { width: "min-content" },
        placeholder: "e.g: 01/01/2000",
        strongLabel: true,
      },
      // },
      // Glycémie: {
      Glycémie: {
        name: "Glycémie",
        label: "Glycémie",
        rules: [""],
        type: "inputNumber",
        unity: "g/L",
        warning: false,
        palceholder: "[0.70-1.10]",
        strongLabel: true,
      },
      Glycémiedate: {
        name: "Glycémiedate",
        label: "Date calcul Glycémie",
        rules: ["validDate", "futurDate"],
        type: "input",
        inputStyle: { width: "min-content" },
        placeholder: "e.g: 01/01/2000",
        strongLabel: true,
      },
      // },
      // hemoglobine: {
      hemoglobine: {
        name: "hemoglobine",
        label: "glyquée (HbA1c)",
        rules: [""],
        type: "inputNumber",
        unity: "%",
        warning: false,
        // placeholder: '[150-400]',
        strongLabel: true,
      },
      hemoglobinedate: {
        name: "hemoglobinedate",
        label: "Date calcul hemoglobine ",
        rules: ["validDate", "futurDate"],
        type: "input",
        inputStyle: { width: "min-content" },
        placeholder: "e.g: 01/01/2000",
        strongLabel: true,
      },
      // },
      // hemoglobineHB: {
      hemoglobineHB: {
        name: "hemoglobineHB",
        //label: "glyquée (HbA1c)",
        rules: [""],
        type: "inputNumber",
        unity: "g/dl",
        warning: false,
        // placeholder: '[150-400]',
        strongLabel: true,
      },
      hemoglobineHBdate: {
        name: "hemoglobineHBdate",
        label: "Date calcul hemoglobine HB :",
        rules: ["validDate", "futurDate"],
        type: "input",
        inputStyle: { width: "min-content" },
        placeholder: "e.g: 01/01/2000",
        strongLabel: true,
      },
      // },
      // ProBNP: {
      ProBNP: {
        name: "ProBNP",
        label: "NT-ProBNP",
        rules: [""],
        type: "inputNumber",
        unity: "μg/L",
        warning: false,
        // placeholder: '[150-400]',
        strongLabel: true,
      },
      ProBNPdate: {
        name: "ProBNPdate",
        label: "Date calcul NT-ProBNP :",
        rules: ["validDate", "futurDate"],
        type: "input",
        inputStyle: { width: "min-content" },
        placeholder: "e.g: 01/01/2000",
        strongLabel: true,
      },
      // },
      // Troponine: {
      Troponine: {
        name: "Troponine",
        label: "Troponine (TnT)",
        rules: [""],
        type: "inputNumber",
        unity: "ng/L",
        warning: false,
        // placeholder: '[150-400]',
        strongLabel: true,
      },
      Troponinedate: {
        name: "Troponinedate",
        label: "Date calcul Troponine :",
        rules: ["validDate", "futurDate"],
        type: "input",
        inputStyle: { width: "min-content" },
        placeholder: "e.g: 01/01/2000",
        strongLabel: true,
      },
      // },
      Plaquettes: {
        name: "Plaquettes",
        label: "Plaquettes",
        rules: [""],
        type: "inputNumber",
        unity: "10e3/µL",
        warning: false,
        placeholder: "[150-400]",
        strongLabel: true,
      },
      VS: {
        name: "VS",
        label: "VS",
        rules: [""],
        type: "inputNumber",
        unity: "mm/h",
        strongLabel: true,
      },
      CRP: {
        name: "CRP",
        label: "CRP",
        rules: [""],
        type: "inputNumber",
        unity: "mg/L",
        strongLabel: true,
      },
      ASAT: {
        name: "ASAT",
        label: "ASAT",
        rules: [""],
        type: "inputNumber",
        unity: "UI/L",
        warning: false,
        placeholder: "[0-32]",
        strongLabel: true,
      },
      ALAT: {
        name: "ALAT",
        label: "ALAT",
        rules: [""],
        type: "inputNumber",
        unity: "UI/L",
        warning: false,
        placeholder: "[10-33]",
        strongLabel: true,
      },
      "Bilirubine totale": {
        name: "Bilirubine totale",
        label: "Bilirubine totale",
        rules: [""],
        type: "inputNumber",
        placeholder: "[0-10]",
        strongLabel: true,
      },
      "Bilirubine conjuguée": {
        name: "Bilirubine conjuguée",
        label: "Bilirubine conjuguée",
        rules: [""],
        type: "inputNumber",
        placeholder: "[0-2]",
        strongLabel: true,
      },
      // 'Phosphatase alcaline': {
      //     name: 'Phosphatase alcaline',
      //     label: 'Réserves alcalines',
      //     rules: [''],
      //     type: 'inputNumber',
      //     palceholder: '[35-104]',
      //     strongLabel: true,
      //     unity: 'mmol/L',
      // },

      "Phosphatase alcaline": {
        name: "Phosphatase alcaline",
        label: "Phosphatase alcaline",
        rules: [""],
        type: "inputNumber",
        palceholder: "[35-104]",
        strongLabel: true,
        unity: "U/L",
      },
      "Réserves alcalines": {
        name: "Réserves alcalines",
        label: "Réserves alcalines",
        rules: [""],
        type: "inputNumber",
        palceholder: "",
        strongLabel: true,
        unity: "mmol/L",
      },
      // 'K+': {
      //     name: 'K+',
      //     label: 'potassium (K) :',
      //     rules: [''],
      //     type: 'inputNumber',
      //     palceholder: '[3.50-5.10]',
      //     strongLabel: true,
      //     unity: 'mmol/L',
      // },
      // 'NA+': {
      //     name: 'NA+',
      //     label: 'NA+',
      //     rules: [''],
      //     type: 'inputNumber',
      //     palceholder: '[135-145]',
      //     strongLabel: true,
      //     unity: 'mmol/L',
      // },
      // "Ca++": {
      //   name: "Ca++",
      //   label: "calcium (Ca):",
      //   rules: [""],
      //   type: "inputNumber",
      //   placeholder: "[86-102]",
      //   strongLabel: true,
      //   unity: "mmol/L",
      // },

      Urée: {
        name: "Urée",
        label: "Urée",
        rules: [""],
        type: "inputNumber",
        unity: "g/L",
        warning: false,
        palceholder: "[0.16-0.48]",
        strongLabel: true,
      },
      // Glycémie: {
      //   name: "Glycémie",
      //   label: "Glycémie",
      //   rules: [""],
      //   type: "inputNumber",
      //   unity: "g/L",
      //   warning: false,
      //   palceholder: "[0.70-1.10]",
      //   strongLabel: true,
      // },
      // Protides: {
      //   name: "Protides",
      //   label: "Protides totaux",
      //   rules: [""],
      //   type: "inputNumber",
      //   unity: "g/L",
      //   warning: false,
      //   palceholder: "[65-80]",
      //   strongLabel: true,
      // },
      // pregnancyTest: {
      //   name: ["pregnancyTest"],
      //   label: "Test de grossesse",
      //   rules: [""],
      //   type: "radio",
      //   radioFields: [
      //     { label: "Négatif", value: "Négatif" },
      //     { label: "Positif", value: "Positif" },
      //   ],
      //   strongLabel: true,
      // },
    },
    radiologicalExamination: {
      thorax: {
        name: "thorax",
        label: "Radio thorax : normale",
        strongLabel: true,
        rules: [""],
        type: "radio",
        radioFields: [
          { label: "Oui", value: "Oui" },
          { label: "Non", value: "Non" },
        ],
      },
      ECG: {
        name: "ECG",
        label: "ECG : normal",
        strongLabel: true,
        rules: [""],
        type: "radio",
        radioFields: [
          { label: "Oui", value: "Oui" },
          { label: "Non", value: "Non" },
        ],
      },
    },
    weight: {
      name: "weight",
      label: "Poids",
      rules: ["required"],
      type: "inputNumber",
      minNumber: 1,
      unity: "Kg",
      strongLabel: true,
    },
    height: {
      name: "height",
      label: "Taille",
      rules: ["required"],
      type: "inputNumber",
      minNumber: 10,
      unity: "Cm",
      strongLabel: true,
    },
    beats: {
      name: "beats",
      label: "Battements ",
      rules: ["required"],
      type: "inputNumber",
      minNumber: 10,
      unity: "/ minute",
      strongLabel: true,
    },
    EchocardiographieDebut: {
      name: "EchocardiographieDebut",
      rules: ["required"],
      type: "timepicker",
      label: "Heure du début de l’examen",
      strongLabel: true,
    },
    EchocardiographieFin: {
      name: "EchocardiographieFin",
      rules: ["required"],
      type: "timepicker",
      label: "Heure de fin de l’examen",
      strongLabel: true,
    },
    nexus: {
      name: "nexus",
      rules: ["required"],
      type: "input",
      label: "Mesure NT-proBNP",
      strongLabel: true,
      unity: "ng/L",
    },
    remis: {
      name: "remis",
      label: "Remis au patient ?",
      strongLabel: true,
      //style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    finished: {
      name: "finished",
      label: "Complété par le patient ?",
      strongLabel: true,
      //style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    recover: {
      name: "recover",
      label: "Récupéré ?",
      strongLabel: true,
      //style: { maxWidth: "80%", margin: "auto" },
      rules: ["required"],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    dateInvisigateur: {
      name: "dateInvisigateur",
      label: "Date de la prochaine visite V2 au centre investigateur",
      type: "datetime",
      rules: ["required"],
      strongLabel: true,
    },
    InsuffisanceCardiaque: {
      name: "InsuffisanceCardiaque",
      label: "NYHA class",
      strongLabel: true,
      //style: { maxWidth: "80%", margin: "auto" },
      rules: [""],
      type: "radio",
      radioFields: [
        { label: "classe I", value: "classe I" },
        { label: "classe II", value: "classe II" },
        { label: "classe III", value: "classe III" },
        { label: "classe IV", value: "classe IV" },
      ],
    },
    HasInsuffisanceCardiaque: {
      name: "HasInsuffisanceCardiaque",
      label: "NYHA",
      strongLabel: true,
      //style: { maxWidth: "80%", margin: "auto" },
      rules: [""],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    // rapportLVEF: {
    //   name: "rapportLVEF",
    //   label: "Rapport sur les résultats",
    //   strongLabel: true,
    //   //style: { maxWidth: "80%", margin: "auto" },
    //   rules: [""],
    //   type: "radio",
    //   radioFields: [{ label: "a une LVEF ≤ 50%", value: "a une LVEF ≤ 50%" }],
    // },
    cliniques: {
      name: "cliniques",
      label: "Le patient :",
      strongLabel: true,
      //style: { maxWidth: "80%", margin: "auto" },
      rules: [""],
      type: "checkbox",
      radioFields: [
        // { label: "a une LVEF ≤ 50%", value: "a une LVEF ≤ 50%" },
        // {
        //   label: "a un indice de volume auriculaire (LAVI) >34 mL/m",
        //   value: "a un indice de volume auriculaire (LAVI) >34 mL/m",
        // },
        // {
        //   label:
        //     "a un indice de masse ventriculaire gauche ≥95 g/m2 (Femme) ou ≥115 g/m2 (Homme)",
        //   value:
        //     "a un indice de masse ventriculaire gauche ≥95 g/m2 (Femme) ou ≥115 g/m2 (Homme)",
        // },
        // {
        //   label: "a une épaisseur de paroi relative > 0,42",
        //   value: "a une épaisseur de paroi relative > 0,42",
        // },
        // { label: "Mitral E/e’  >9", value: "Mitral E/e’  >9" },
        // {
        //   label: "a une vitesse de régurgitation tricuspide >2,8 m/s",
        //   value: "a une vitesse de régurgitation tricuspide >2,8 m/s",
        // },
        // { label: "a une HFrEF", value: "a une HFrEF" },
        // {
        //   label:
        //     "a une autre anomalie cardiaque qui nécessite une investigation/prise en charge plus approfondie, mais pas d'Insuffisance cardiaque",
        //   value:
        //     "a une autre anomalie cardiaque qui nécessite une investigation/prise en charge plus approfondie, mais pas d'Insuffisance cardiaque",
        // },
        // {
        //   label: "ne présente aucun signe d'anomalie cardiaque.",
        //   value: "ne présente aucun signe d'anomalie cardiaque.",
        // },
        "a une LVEF ≤ 50%",
        "a un indice de volume auriculaire (LAVI) >34 mL/m",
        "a un indice de masse ventriculaire gauche ≥95 g/m2 (Femme) ou ≥115 g/m2 (Homme)",
        "a une épaisseur de paroi relative > 0,42",
        "Mitral E/e’  >9",
        "a une vitesse de régurgitation tricuspide >2,8 m/s",
        "a une HFrEF",
        "a une autre anomalie cardiaque qui nécessite une investigation/prise en charge plus approfondie, mais pas d'Insuffisance cardiaque",
        "ne présente aucun signe d'anomalie cardiaque.",
      ],
    },
    finishedDoctor: {
      name: "finishedDoctor",
      label: "Complété par le médecin ?",
      strongLabel: true,
      //style: { maxWidth: "80%", margin: "auto" },
      rules: [""],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
    recoverDoctor: {
      name: "recoverDoctor",
      label: "Récupéré par le médecin ?",
      strongLabel: true,
      //style: { maxWidth: "80%", margin: "auto" },
      rules: [""],
      type: "radio",
      radioFields: [
        { label: "Oui", value: "Oui" },
        { label: "Non", value: "Non" },
      ],
    },
};

import React from "react";
import PropTypes from "prop-types";
import { Form, Card, Typography, Divider } from "antd";
import DynamicField from "../DynamicField";
import patientAdditionEmptyVals from "../../sections/Patients/add/emptyValues";
import emptyValues from "../../sections/Visit/add/emptyValues";

const { Title } = Typography;

const AntecedentsExams = (props) => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const verifBilanHématologie =
    props.visit === "j0"
      ? ["NFS", "HB", "GR", "hématocrite", "leucocytes", "PNP", "Plaquettes"]
      : ["HB", "GR", "hématocrite", "leucocytes", "PNP", "Plaquettes"];

  console.log(
    "DEBUG VALE echoMam",
    props["echographieMammaire"],
    props.values.biologicalExamination["echographieMammaire"]
  );
  return (
    <>
      {props.visit === "j4" && (
        <Form
          style={{ marginTop: 30 }}
          form={props.dateVisiteForm}
          className="dateVisite-form"
        >
          <DynamicField
            key={"dateVisite"}
            field={props.values["dateVisite"]}
            onUpdate={props.onUpdate}
            fieldFamily="dateVisiteInfos"
          />
        </Form>
      )}
      <Form
        style={{ marginTop: 30 }}
        form={props.form}
        className="biological-examination-form"
      >
        {props.isAdd ? (
          <>
            {props.visit === "j0" && (
              <Card
                title="Bilan Biochimique standard"
                className="add-antecedent-card"
              >
                <div style={{ marginLeft: "4%" }}>
                  <div>
                    {[
                      "GS",
                      "Créatinine",
                      "NA+",
                      "K+",
                      "CL-",
                      "bicar",
                      "protides",
                      "calcemie",
                      "Glycémie",
                      "bilirubine",
                      "transmaniases",
                      "gammaGT",
                      "phosphatases",
                      "Cholesterole",
                      "triglycerides",
                      "acideUrique",
                      "albuminemie",
                    ].map((k) => (
                      <DynamicField
                        key={k}
                        field={props.values.biologicalExamination[k]}
                        onUpdate={props.onUpdate}
                        fieldFamily="biologicalExamination"
                      />
                    ))}
                  </div>
                </div>
              </Card>
            )}
            {(props.visit === "j0" || props.visit === "j2") && (
              <Card title="Bilan inflammatoire" className="add-antecedent-card">
                <div style={{ marginLeft: "4%" }}>
                  <div>
                    {["CRP", "LDH", "IL6"].map((k) => (
                      <DynamicField
                        key={k}
                        field={props.values.biologicalExamination[k]}
                        onUpdate={props.onUpdate}
                        fieldFamily="biologicalExamination"
                      />
                    ))}
                  </div>
                </div>
              </Card>
            )}
            {(props.visit === "j0" ||
              props.visit === "j1" ||
              props.visit === "j2") && (
              <Card
                title={`${
                  props.visit === "j0"
                    ? "Bilan hématologique"
                    : "Bilan hématologique NFS"
                }`}
                className="add-antecedent-card"
              >
                <div style={{ marginLeft: "4%" }}>
                  <div>
                    {verifBilanHématologie.map((k) => (
                      <DynamicField
                        key={k}
                        field={props.values.biologicalExamination[k]}
                        onUpdate={props.onUpdate}
                        fieldFamily="biologicalExamination"
                      />
                    ))}
                  </div>
                </div>
              </Card>
            )}
            <Card title="Marqueurs tumoraux" className="add-antecedent-card">
              <div style={{ marginLeft: "4%" }}>
                <div>
                  {["ACE", "CA153"].map((k) => (
                    <DynamicField
                      key={k}
                      field={props.values.biologicalExamination[k]}
                      onUpdate={props.onUpdate}
                      fieldFamily="biologicalExamination"
                    />
                  ))}
                </div>
              </div>
            </Card>

            {(props.visit === "j1" || props.visit === "j2") && (
              <Card
                title="Evaluation radiologique"
                className="add-antecedent-card"
              >
                <div style={{ marginLeft: "4%" }}>
                  <div>
                    {[
                      "echographieMammaire",
                      // "echographieMammaireTaille",
                      // "echographieMammaireTailleSecond",
                      "MammographieEvaluation",
                      // "MammographieEvaluationTaille",
                      // "MammographieEvaluationTailleSecond",
                      "IRMmammaire",
                      // "IRMmammaireTaille",
                      // "IRMmammaireTailleSecond",
                      "Mammographie",
                      "Scannerthoraco",
                    ].map((k, indice) => (
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.MammographieEvaluation !==
                            curValues.MammographieEvaluation ||
                          prevValues.IRMmammaire !== curValues.IRMmammaire
                        }
                      >
                        {(form) => {
                          return (
                            <>
                              <DynamicField
                                key={k}
                                field={props.values.biologicalExamination[k]}
                                onUpdate={props.onUpdate}
                                fieldFamily="biologicalExamination"
                                radio={props.isAdd}
                              />
                              {[
                                "echographieMammaire",
                                "MammographieEvaluation",
                                "IRMmammaire",
                              ].includes(k) &&
                              (props[k] === "Oui" ||
                                props.values.biologicalExamination[k].value ===
                                  "Oui" ||
                                form.getFieldValue(k) === "Oui") ? (
                                <>
                                  <h1>Taille de l'opacité</h1>
                                  <div
                                    style={{
                                      width: "40%",
                                      display: "inline-block",
                                    }}
                                  >
                                    <DynamicField
                                      key={
                                        props.values.biologicalExamination[
                                          `${k}Taille`
                                        ].name
                                      }
                                      field={
                                        props.values.biologicalExamination[
                                          `${k}Taille`
                                        ]
                                      }
                                      onUpdate={props.onUpdate}
                                      fieldFamily="biologicalExamination"
                                    />
                                  </div>
                                  <div
                                    style={{
                                      width: "40%",
                                      display: "inline-block",
                                    }}
                                  >
                                    <DynamicField
                                      key={
                                        props.values.biologicalExamination[
                                          `${k}TailleSecond`
                                        ].name
                                      }
                                      field={
                                        props.values.biologicalExamination[
                                          `${k}TailleSecond`
                                        ]
                                      }
                                      onUpdate={props.onUpdate}
                                      fieldFamily="biologicalExamination"
                                    />
                                  </div>
                                </>
                              ) : null}
                            </>
                          );
                        }}
                      </Form.Item>
                    ))}
                  </div>
                </div>
              </Card>
            )}
            {props.visit === "j2" ? (
              <Card
                title="Indication chirurgie"
                className="add-antecedent-card"
              >
                <div style={{ marginLeft: "4%" }}>
                  <div>
                    {["indiceChirurgie"].map((k) => (
                      <>
                        <DynamicField
                          key={k}
                          field={props.values.biologicalExamination[k]}
                          onUpdate={props.onUpdate}
                          fieldFamily="biologicalExamination"
                          radio={true}
                        />
                        {props[k] === "Oui" ||
                        props.values.biologicalExamination[k].value ===
                          "Oui" ? (
                          <>
                            <DynamicField
                              key={props.values.biologicalExamination[k].name}
                              field={
                                props.values.biologicalExamination[`${k}Rep`]
                              }
                              onUpdate={props.onUpdate}
                              fieldFamily="biologicalExamination"
                            />
                          </>
                        ) : null}
                      </>
                    ))}
                  </div>
                </div>
              </Card>
            ) : null}
          </>
        ) : (
          <>
            {props.visit === "j0" && (
              <Card
                title="Bilan Biochimique standard"
                className="add-antecedent-card"
              >
                <div style={{ marginLeft: "4%" }}>
                  <div>
                    {[
                      "GS",
                      "Créatinine",
                      "NA+",
                      "K+",
                      "CL-",
                      "bicar",
                      "protides",
                      "calcemie",
                      "Glycémie",
                      "bilirubine",
                      "transmaniases",
                      "gammaGT",
                      "phosphatases",
                      "Cholesterole",
                      "triglycerides",
                      "acideUrique",
                      "albuminemie",
                    ].map((k) => (
                      <DynamicField
                        key={k}
                        field={props.values.biologicalExamination[k]}
                        onUpdate={props.onUpdate}
                        fieldFamily="biologicalExamination"
                      />
                    ))}
                  </div>
                </div>
              </Card>
            )}
            {(props.visit === "j0" || props.visit === "j2") && (
              <Card title="Bilan inflammatoire" className="add-antecedent-card">
                <div style={{ marginLeft: "4%" }}>
                  <div>
                    {["CRP", "LDH", "IL6"].map((k) => (
                      <DynamicField
                        key={k}
                        field={props.values.biologicalExamination[k]}
                        onUpdate={props.onUpdate}
                        fieldFamily="biologicalExamination"
                      />
                    ))}
                  </div>
                </div>
              </Card>
            )}
            {(props.visit === "j0" ||
              props.visit === "j1" ||
              props.visit === "j2") && (
              <Card
                title={`${
                  props.visit === "j0"
                    ? "Bilan hématologique"
                    : "Bilan hématologique NFS"
                }`}
                className="add-antecedent-card"
              >
                <div style={{ marginLeft: "4%" }}>
                  <div>
                    {verifBilanHématologie.map((k) => (
                      <DynamicField
                        key={k}
                        field={props.values.biologicalExamination[k]}
                        onUpdate={props.onUpdate}
                        fieldFamily="biologicalExamination"
                      />
                    ))}
                  </div>
                </div>
              </Card>
            )}
            <Card title="Marqueurs tumoraux" className="add-antecedent-card">
              <div style={{ marginLeft: "4%" }}>
                <div>
                  {["ACE", "CA153"].map((k) => (
                    <DynamicField
                      key={k}
                      field={props.values.biologicalExamination[k]}
                      onUpdate={props.onUpdate}
                      fieldFamily="biologicalExamination"
                    />
                  ))}
                </div>
              </div>
            </Card>

            {(props.visit === "j1" || props.visit === "j2") && (
              <Card
                title="Evaluation radiologique"
                className="add-antecedent-card"
              >
                <div style={{ marginLeft: "4%" }}>
                  <div>
                    {[
                      "echographieMammaire",
                      // "echographieMammaireTaille",
                      // "echographieMammaireTailleSecond",
                      "MammographieEvaluation",
                      // "MammographieEvaluationTaille",
                      // "MammographieEvaluationTailleSecond",
                      "IRMmammaire",
                      // "IRMmammaireTaille",
                      // "IRMmammaireTailleSecond",
                      "Mammographie",
                      "Scannerthoraco",
                    ].map((k, indice) => (
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.MammographieEvaluation !==
                            curValues.MammographieEvaluation ||
                          prevValues.IRMmammaire !== curValues.IRMmammaire
                        }
                      >
                        {(form) => {
                          return (
                            <>
                              <DynamicField
                                key={k}
                                field={props.values.biologicalExamination[k]}
                                onUpdate={props.onUpdate}
                                fieldFamily="biologicalExamination"
                              />
                              {[
                                "echographieMammaire",
                                "MammographieEvaluation",
                                "IRMmammaire",
                              ].includes(k) &&
                              (props[k] === "Oui" ||
                                props.values.biologicalExamination[k].value ===
                                  "Oui" ||
                                form.getFieldValue(k) === "Oui") ? (
                                <>
                                  <h1>Taille de l'opacité</h1>
                                  <div
                                    style={{
                                      width: "40%",
                                      display: "inline-block",
                                    }}
                                  >
                                    <DynamicField
                                      key={
                                        props.values.biologicalExamination[
                                          `${k}Taille`
                                        ]
                                          ? props.values.biologicalExamination[
                                              `${k}Taille`
                                            ].name
                                          : `${k}Taille`
                                      }
                                      field={
                                        props.values.biologicalExamination[
                                          `${k}Taille`
                                        ]
                                      }
                                      onUpdate={props.onUpdate}
                                      fieldFamily="biologicalExamination"
                                    />
                                  </div>
                                  <div
                                    style={{
                                      width: "40%",
                                      display: "inline-block",
                                    }}
                                  >
                                    <DynamicField
                                      key={
                                        props.values.biologicalExamination[
                                          `${k}TailleSecond`
                                        ]
                                          ? props.values.biologicalExamination[
                                              `${k}TailleSecond`
                                            ].name
                                          : `${k}TailleSecond`
                                      }
                                      field={
                                        props.values.biologicalExamination[
                                          `${k}TailleSecond`
                                        ]
                                      }
                                      onUpdate={props.onUpdate}
                                      fieldFamily="biologicalExamination"
                                    />
                                  </div>
                                </>
                              ) : null}
                            </>
                          );
                        }}
                      </Form.Item>
                    ))}
                  </div>
                </div>
              </Card>
            )}
            {props.visit === "j2" ? (
              <Card
                title="Indication chirurgie"
                className="add-antecedent-card"
              >
                <div style={{ marginLeft: "4%" }}>
                  <div>
                    {["indiceChirurgie"].map((k) => (
                      <>
                        <DynamicField
                          key={k}
                          field={props.values.biologicalExamination[k]}
                          onUpdate={props.onUpdate}
                          fieldFamily="biologicalExamination"
                          radio={false}
                        />
                        {props[k] === "Oui" ||
                        props.values.biologicalExamination[k].value ===
                          "Oui" ? (
                          <>
                            <DynamicField
                              key={props.values.biologicalExamination[k].name}
                              field={
                                props.values.biologicalExamination[`${k}Rep`]
                              }
                              onUpdate={props.onUpdate}
                              fieldFamily="biologicalExamination"
                            />
                          </>
                        ) : null}
                      </>
                    ))}
                  </div>
                </div>
              </Card>
            ) : null}
          </>
        )}
      </Form>
    </>
  );
};
AntecedentsExams.propTypes = {
  form: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
};

AntecedentsExams.defaultProps = {
  onUpdate: () => {},
};

export default AntecedentsExams;

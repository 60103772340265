import React, { useState, useEffect } from "react";
import { Form, Button, Typography, Divider, Tooltip } from "antd";
import PropTypes from "prop-types";
import "./styles.css";
import DynamicField from "../../components/DynamicField/index";

const { Title } = Typography;

const TopText = [
  "Si l'événement est grave, veuillez remplir l'annexe de déclaration d'EIG",
  `Si un traitement correcteur a été introduit, veuillez remplir le tableau récapitulatif des "Traitements
concomitants"`,
  `Si l'événement implique une sortie d'étude, veuillez remplir la page de "Fin de participation à l'étude"`,
];

const AdverseForm = ({
  form,
  values,
  onFinish,
  onUpdate,
  adverseEvent,
  ...props
}) => {
  const [extraIntensity, setExtraIntensity] = useState(false);
  const [isDeath, setIsDeath] = useState(false);
  const [requiresTreatment, setRequiresTreatment] = useState(
    values["TraitementCorrecteur"].value === "Oui"
  );

  const handleValuesChange = (val) => {
    if (val.evolution && val.evolution === "décès") {
      setIsDeath(true);
    } else {
      setIsDeath(false);
    }
    if (val.intensity && val.intensity === "Grave") setExtraIntensity(true);
    if (val.intensity && val.intensity === "Non grave")
      setExtraIntensity(false);
    if (val["TraitementCorrecteur"])
      setRequiresTreatment(val["TraitementCorrecteur"] === "Oui");
  };

  useEffect(() => {
    setRequiresTreatment(values["TraitementCorrecteur"].value === "Oui");
  }, [values]);

  useEffect(() => {
    if (adverseEvent) {
      //if (adverseEvent.evolution.value === "décès") setIsDeath(true);
      if (adverseEvent.intensity.value === "Grave") {
        setIsDeath(true);
        setExtraIntensity(true);
      }
      if (adverseEvent.intensity.value === "Non grave")
        setExtraIntensity(false);
    }
  }, [adverseEvent]);

  return (
    <Form
      style={{ marginTop: 30, display: "flex" }}
      onFinish={onFinish}
      form={form}
      onValuesChange={handleValuesChange}
    >
      <div style={{ padding: "0 34px", marginRight: "10%" }}>
        {[
          "dateVisite",
          "description",
          "lien",
          "soriteEtude",
          "TraitementCorrecteur",
          "startDate",
          "endDate",
          "intensity",
          "commentaire",
        ].map((k, idc) => {
          return (
            <>
              {idc === 3 ? (
                <>
                  <Tooltip placement="topLeft" title={TopText[1]}>
                    <span>Sortie d'étude :</span>
                  </Tooltip>
                  <DynamicField
                    key={k}
                    field={values[k]}
                    fieldFamily="adverse"
                    onUpdate={onUpdate}
                    radio={adverseEvent ? false : true}
                  />
                </>
              ) : idc === 4 ? (
                <>
                  <Tooltip placement="topLeft" title={TopText[1]}>
                    <span>Traitement correcteur :</span>
                  </Tooltip>
                  <DynamicField
                    key={k}
                    field={values[k]}
                    fieldFamily="adverse"
                    onUpdate={onUpdate}
                    radio={adverseEvent ? false : true}
                  />
                  {requiresTreatment ? (
                    <>
                      <DynamicField
                        key={"TraitementCorrecteurName"}
                        field={values["TraitementCorrecteurName"]}
                        fieldFamily="adverse"
                        onUpdate={onUpdate}
                        radio={adverseEvent ? false : true}
                      />
                      <DynamicField
                        key={"TraitementCorrecteurPosology"}
                        field={values["TraitementCorrecteurPosology"]}
                        fieldFamily="adverse"
                        onUpdate={onUpdate}
                        radio={adverseEvent ? false : true}
                      />
                    </>
                  ) : null}
                </>
              ) : idc === 7 ? (
                <>
                  <Tooltip placement="topLeft" title={TopText[0]}>
                    <span>Evénement Indésirable Grave :</span>
                  </Tooltip>
                  <DynamicField
                    key={k}
                    field={values[k]}
                    fieldFamily="adverse"
                    onUpdate={onUpdate}
                    radio={adverseEvent ? false : true}
                  />
                </>
              ) : (
                <DynamicField
                  key={k}
                  field={values[k]}
                  fieldFamily="adverse"
                  onUpdate={onUpdate}
                  radio={adverseEvent ? false : true}
                />
              )}
            </>
          );
        })}
        {(props["intensity"] === "Grave" ||
          ((adverseEvent || {}).intensity || {}).value === "Grave") && (
          <>
            <DynamicField
              key={"deathDescription"}
              field={values["deathDescription"]}
              fieldFamily="adverse"
              onUpdate={onUpdate}
              radio={false}
            />
            {[
              "CINom",
              "CIInvestigateur",
              "IPInclusion",
              "IPDateBirth",
              "IPDateInclusion",
              "IPSexe",
              "EIGDeclaration",
              "EIGDeclarationIssue",
            ].map((item, idx) => {
              return (
                <>
                  <DynamicField
                    key={item}
                    field={values[item]}
                    fieldFamily="adverse"
                    onUpdate={onUpdate}
                    radio={adverseEvent ? false : true}
                  />
                  {(props["EIGDeclaration"] === "suivi" ||
                    ((adverseEvent || {}).EIGDeclaration || {}).value ===
                      "suivi") &&
                    item === "EIGDeclaration" && (
                      <DynamicField
                        key={"NumSuivi"}
                        field={values["NumSuivi"]}
                        fieldFamily="adverse"
                        onUpdate={onUpdate}
                        radio={true}
                      />
                    )}
                  {(props["EIGDeclarationIssue"] === "Décès inattendu" ||
                    ((adverseEvent || {}).EIGDeclarationIssue || {}).value ===
                      "Décès inattendu") &&
                    item === "EIGDeclarationIssue" && (
                      <DynamicField
                        key={"EIGDead"}
                        field={values["EIGDead"]}
                        fieldFamily="adverse"
                        onUpdate={onUpdate}
                        radio={true}
                      />
                    )}
                  {((props["EIGDeclarationIssue"] ===
                    "Autre(s) critère(s) médicalement significatif(s)" &&
                    item === "EIGDeclarationIssue") ||
                    ((adverseEvent || {}).EIGDeclarationIssue || {}).value ===
                      "Autre(s) critère(s) médicalement significatif(s)") && (
                    <DynamicField
                      key={"autreMedRep"}
                      field={values["autreMedRep"]}
                      fieldFamily="adverse"
                      onUpdate={onUpdate}
                    />
                  )}
                  {idx === 7 &&
                    ((props["EIGDeclarationIssue"] ===
                      "Nécessite ou prolonge l'hospitalisation" &&
                      item === "EIGDeclarationIssue") ||
                      ((adverseEvent || {}).EIGDeclarationIssue || {}).value ===
                        "Nécessite ou prolonge l'hospitalisation") && (
                      <>
                        <DynamicField
                          key={"EIGNPHStart"}
                          field={values["EIGNPHStart"]}
                          fieldFamily="adverse"
                          onUpdate={onUpdate}
                        />
                        <DynamicField
                          key={"EIGNPHEnd"}
                          field={values["EIGNPHEnd"]}
                          fieldFamily="adverse"
                          onUpdate={onUpdate}
                        />
                      </>
                    )}
                </>
              );
            })}
          </>
        )}
        {adverseEvent ? null : (
          <Form.Item>
            <Button type="primary" className="center-button" htmlType="submit">
              Envoyer
            </Button>
          </Form.Item>
        )}
      </div>
    </Form>
  );
};

AdverseForm.propTypes = {
  form: PropTypes.object.isRequired,
  onFinish: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  onUpdate: PropTypes.func.isRequired,
  adverseEvent: PropTypes.object.isRequired,
};

export default AdverseForm;

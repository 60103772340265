import React, { useState, useEffect } from "react";
import { Steps, Card, Spin, Form, Row, Col, Tree } from "antd";
import PropTypes from "prop-types";
import moment from "moment";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import HeadBreadCrumb from "../../components/HeadBreadCrumb";
import emptyValues from "./add/emptyValues";
import VisitInformations from "./VisitInformations";
import {
  updateVisitAsync,
  getAllVisitsByPatientAsync,
  selectVisitAsync,
} from "../../redux/visits/actions";
import { parseVisitName } from "../../helpers/functions";
import QueryModal from "../../components/QueryModal/index";
//import FluidsNumber from "./add/FluidsNumber";
import "./styles.css";
import Inclusion from "../../components/medicalRecord/inclusion";
import AntecedentsExams from "../../components/medicalRecord/antecedents";
import Treatments from "../../components/medicalRecord/treatments";
import { updatePatientAsync } from "../../redux/patients/actions";
import DrugForm from "./add/drugForm";
import AntecedentsMed from "../../components/medicalRecord/antecedentMed";
import ExamenMleculaire from "../../components/medicalRecord/examenMoluculaire";
import ExamenHistologique from "../../components/medicalRecord/examenHistologique";
import ClassificationTNM from "../../components/medicalRecord/classificationTNM";
import ExamenRadiologique from "../../components/medicalRecord/examenRadiologique";
import ExamenClinique from "../../components/medicalRecord/examenClinique";
import history from "../../history";

const { Step } = Steps;

const SelectedVisit = ({
  contentIsLoading,
  visit,
  selectedVisit,
  handleGoBack,
  updateVisitAsync,
  user,
  listVisitsByPatient,
  getAllVisitsByPatientAsync,
  updatePatientAsync,
  selectVisitAsync,
}) => {
  const [current, setCurrent] = useState(0);
  const [values, setValues] = useState(emptyValues);
  const [openQueryModal, setOpenQueryModal] = useState(false);
  const [selectedField, selectField] = useState();
  const [clinicalForm] = Form.useForm();
  const [visitInformationsForm] = Form.useForm();
  const [bloodSampleForm] = Form.useForm();
  const [bloodSamples, setBloodSamples] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [showAddSample, setShowAddSample] = useState(false);
  const [selectedBloodSample, setSelectedBloodSample] = useState("");
  const [questionnaireForm] = Form.useForm();
  const [measureForm] = Form.useForm();
  const [examenCliniqueForm] = Form.useForm();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [antecedentsMedForm] = Form.useForm();
  const [examnMoleclulaireForm] = Form.useForm();
  const [examinationsForm] = Form.useForm();
  const [antecedentsForm] = Form.useForm();
  const [signesForm] = Form.useForm();
  const [traitementsForm] = Form.useForm();
  const [dateVisiteForm] = Form.useForm();
  const [inclusionForm] = Form.useForm();
  const [drugForm] = Form.useForm();
  const [exclusionForm] = Form.useForm();
  const [examnHistologiqueForm] = Form.useForm();
  const [examnRadiologiqueForm] = Form.useForm();
  const [ClassificationTNMForm] = Form.useForm();
  
  const [hasNoTreatmentsForm] = Form.useForm();

  const [transport, setTransport] = useState(null);
  const [MC, setMC] = useState(null);
  const [PCI, setPCI] = useState(null);
  const [IDM, setIDM] = useState(null);
  const [CABG, setCABG] = useState(null);
  const [PC, setPC] = useState(null);
  const [ICP, setICP] = useState(null);
  const [AFA, setAFA] = useState(null);
  const [AVC, setAVC] = useState(null);
  const [MVAP, setMVAP] = useState(null);
  const [DIABETE, setDIABETE] = useState(null);
  const [HA, setHA] = useState(null);
  const [CANCER, setCANCER] = useState(null);
  const [BPCO, setBPCO] = useState(null);
  const [MRC, setMRC] = useState(null);
  const [MDF, setMDF] = useState(null);
  const [DEPRESSION, setDEPRESSION] = useState(null);
  const [DT, setDT] = useState(null);
  /** SIGNES */
  const [RP, setRP] = useState(null);
  const [ER, setER] = useState(null);
  const [EE, setEE] = useState(null);
  const [TN, setTN] = useState(null);
  const [RTE, setRTE] = useState(null);
  const [ANG, setANG] = useState(null);
  const [JPV, setJPV] = useState(null);
  const [TBC, setTBC] = useState(null);
  const [RS, setRS] = useState(null);
  const [OP, setOP] = useState(null);
  const [DPN, setDPN] = useState(null);
  const [ORT, setORT] = useState(null);
  const [CXR, setCXR] = useState(null);
  /** END SIGNES */
  /**TREATEMENTS */
  const [ICD, setICD] = useState(null);
  const [BB, setBB] = useState(null);
  const [ACE, setACE] = useState(null);
  const [ARB, setARB] = useState(null);
  const [ARN, setARN] = useState(null);
  const [ARM, setARM] = useState(null);
  const [IV, setIV] = useState(null);
  const [SGL, setSGL] = useState(null);
  const [DA, setDA] = useState(null);
  const [DTH, setDTH] = useState(null);
  const [ST, setST] = useState(null);
  const [TA, setTA] = useState(null);

  const [isAdd, setIsAdd] = useState(false);

  /** BIOLOGICAL VALS */
  const [mamoTaille, setMamoTaille] = useState(null);
  const [mamoTailleSecond, setMamoTailleSecond] = useState(null);
  const [Mammographie, setMammographie] = useState(null);
  const [thoraco, setThoraco] = useState(null);
  const [scintigraphie, setScintigraphie] = useState(null);
  const [echographie, setEchographie] = useState(null);
  const [Her2Neu, setHer2Neu] = useState(null);
  const [MF, setMF] = useState(null);
  const [MR, setMR] = useState(null);
  const [indiceChirurgie, setIndiceChirurgie] = useState(null);

  const [squelette, setSquelette] = useState(null);
  const [auscultation, setAuscultation] = useState(null);
  const [abdominal, setAbdominal] = useState(null);
  const [ganglionnaires, setGanglionnaires] = useState(null);
  const [ganglionnairesSus, setGanglionnairesSus] = useState(null);

  const [echographieMammaire, setechographieMammaire] = useState(null);

  /** END TREATEMENTS */
  const onUpdateValidation = (name, value) => {
    switch (name) {
      case "echographieMammaire":
        setechographieMammaire(value);
        break;
      case "Her2Neu":
        setHer2Neu(value);
        break;
      case "mamoTaille":
        setMamoTaille(value);
        break;
      case "mamoTailleSecond":
        setMamoTailleSecond(value);
        break;
      case "Mammographie":
        setMammographie(value);
        break;
      case "thoraco":
        setThoraco(value);
        break;
      case "scintigraphie":
        setScintigraphie(value);
        break;
      case "echographie":
        setEchographie(value);
        break;
      case "ICD":
        setICD(value);
        break;
      case "BB":
        setBB(value);
        break;
      case "ACE":
        setACE(value);
        break;
      case "ARB":
        setARB(value);
        break;
      case "ARN":
        setARN(value);
        break;
      case "ARM":
        setARM(value);
        break;
      case "IV":
        setIV(value);
        break;
      case "SGL":
        setSGL(value);
        break;
      case "TA":
        setTA(value);
        break;
      case "DA":
        setDA(value);
        break;
      case "DTH":
        setDTH(value);
        break;
      case "ST":
        setST(value);
        break;
      case "ORT":
        setORT(value);
        break;
      case "CXR":
        setCXR(value);
        break;
      case "JPV":
        setJPV(value);
        break;
      case "TBC":
        setTBC(value);
        break;
      case "RS":
        setRS(value);
        break;
      case "OP":
        setOP(value);
        break;
      case "DPN":
        setDPN(value);
        break;
      case "TN":
        setTN(value);
        break;
      case "RTE":
        setRTE(value);
        break;
      case "ANG":
        setANG(value);
        break;
      case "RP":
        setRP(value);
        break;
      case "ER":
        setER(value);
        break;
      case "EE":
        setEE(value);
        break;
      case "PCI":
        setPCI(value);
        break;
      case "IDM":
        setIDM(value);
        break;
      case "CABG":
        setCABG(value);
        break;
      case "transport":
        setTransport(value);
        break;
      case "MC":
        if (value === "Oui") {
          setIsAdd(true);
        }
        setMC(value);
        break;
      case "PC":
        setPC(value);
        break;
      case "ICP":
        setICP(value);
        break;
      case "AFA":
        setAFA(value);
        break;
      case "AVC":
        setAVC(value);
        break;
      case "MVAP":
        setMVAP(value);
        break;
      case "DIABETE":
        setDIABETE(value);
        break;
      case "HA":
        setHA(value);
        break;
      case "CANCER":
        setCANCER(value);
        break;
      case "BPCO":
        setBPCO(value);
        break;
      case "MRC":
        setMRC(value);
        break;
      case "MDF":
        setMDF(value);
        break;
      case "DEPRESSION":
        setDEPRESSION(value);
        break;
      case "DT":
        setDT(value);
        break;
      // case "goodHealth":
      // case "contraception":
      // case "signedConsent":
      // case "smoker":
      // case "alcohol":
      // case "biology":
      // case "followProcedure":
      //   setInclusion({ ...inclusion, [name]: value });
      //   break;
      // case "anotherTrial":
      // case "pharmaceuticals":
      // case "chronicalDisease":
      // case "allergy":
      // case "anomalyECG":
      // case "HTA":
      // case "antecedents":
      // case "drugs":
      // case "usageDuringTrial":
      // case "VIH":
      //   setExclusion({ ...exclusion, [name]: value });
      //   break;
      default:
        break;
    }
  };

  useEffect(() => {}, [values]);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  useEffect(() => {
    setIsModalVisible(true);
  }, [selectedBloodSample]);

  const mapFiedFamilyToForm = {
    visitInformations: visitInformationsForm,
    dateVisite: dateVisiteForm,
    biologicalExamination: examinationsForm,
    antecedents: antecedentsForm,
    signes: signesForm,
    traitements: traitementsForm,
    clinical: clinicalForm,
    questionnaire: questionnaireForm,
    measures: measureForm,
    drug: drugForm,
    classificationTNM: ClassificationTNMForm,
    examenHistologique: examnHistologiqueForm,
    examenMolelculaire: examnMoleclulaireForm,
    examenRadiologique: examnRadiologiqueForm,
    examenClinique: examenCliniqueForm,
    antecedentMed: antecedentsMedForm,
    hasNoTreatments: hasNoTreatmentsForm
  };

  useEffect(() => {
    getAllVisitsByPatientAsync(selectedVisit.patient._id);
  }, [selectedVisit.patient._id]);

  useEffect(() => {
    if (current === 2) {
      setSelectedBloodSample("firstTube");
    }
    return () => {
      setSelectedBloodSample("");
    };
  }, [current]);

  useEffect(() => {
    [
      "ganglionnaires",
      "ganglionnairesSus",
      "abdominal",
      "auscultation",
      "squelette",
    ].map((k) => {
      if (values.examenClinique[k].value === "Anormal") {
        switch (k) {
          case "ganglionnaires":
            setGanglionnaires("Anormal");
            break;
          case "ganglionnairesSus":
            setGanglionnairesSus("Anormal");
            break;
          case "abdominal":
            setAbdominal("Anormal");
            break;
          case "auscultation":
            setAuscultation("Anormal");
            break;
          case "squelette":
            setSquelette("Anormal");
            break;
          default:
            break;
        }

        if (values.examenClinique.hasOwnProperty(`${k}Rep`) === false) {
          setValues({
            ...values,
            examenClinique: {
              ...values.examenClinique,
              [`${k}Rep`]: {
                ...emptyValues.examenClinique[`${k}Rep`],
                readOnly: true,
              },
            },
          });
        }
      }
    });

    if(visit ==="j1" || visit === "j2"){
      ["echographieMammaire"].map((k) => {
        console.log('values.biologicalExamination[k].value',values.biologicalExamination[k].value)
        if (values.biologicalExamination[k].value === "Oui") {
          console.log('Mamographie value',values.biologicalExamination[k].value)
          switch (k) {
            case "echographieMammaire":
              setechographieMammaire("Oui");
              break;
            default:
          }
          console.log('DEBUG values echographie',{hasPro: values.biologicalExamination.hasOwnProperty(`echographieMammaireTaille`),
          echographieMammaireTaille:values.biologicalExamination['echographieMammaireTaille'],
          echographieMammaireTailleSecond:values.biologicalExamination['echographieMammaireTailleSecond']})

              if (values.biologicalExamination.hasOwnProperty(`echographieMammaireTaille`) === false ) {
                setValues({
                  ...values,
                  biologicalExamination: {
                    ...values.biologicalExamination,
                    [`echographieMammaireTaille`]: {
                      ...emptyValues.biologicalExamination[`echographieMammaireTaille`],
                      readOnly: true,
                    },
                    [`echographieMammaireTailleSecond`]: {
                      ...emptyValues.biologicalExamination[`echographieMammaireTailleSecond`],
                      readOnly: true,
                    },
                  },
                });
              }else if(values.biologicalExamination.hasOwnProperty(`echographieMammaireTailleSecond`) === false){
                setValues({
                  ...values,
                  biologicalExamination: {
                    ...values.biologicalExamination,
                    [`echographieMammaireTailleSecond`]: {
                      ...emptyValues.biologicalExamination[`echographieMammaireTailleSecond`],
                      readOnly: true,
                    },
                  },
                });
              }
    }
  })
}
    if (visit === "j0" || visit === "j4" || visit === "j3") {
      ["thoraco", "scintigraphie", "echographie"].map((k) => {
        if (values.examenRadiologique[k].value === "Anormal") {
          switch (k) {
            case "echographie":
              setEchographie("Anormal");
              break;
            case "scintigraphie":
              setScintigraphie("Anormal");
              break;
            case "thoraco":
              setThoraco("Anormal");
              break;
            default:
              break;
          }

          if (values.examenRadiologique.hasOwnProperty(`${k}Rep`) === false) {
            setValues({
              ...values,
              examenRadiologique: {
                ...values.examenRadiologique,
                [`${k}Rep`]: {
                  ...emptyValues.examenRadiologique[`${k}Rep`],
                  readOnly: true,
                },
              },
            });
          } else {
          }
        }
      });
    }
    if (visit === "j0") {
      values.examenHistologique["Her2Neu"] !== undefined && ( values.examenHistologique["Her2Neu"].value === "Score 2"
        ? setHer2Neu("Score 2")
        : setHer2Neu(values.examenHistologique["Her2Neu"].value));
      if (!values.examenHistologique.hasOwnProperty(`Her2NeuCheck`)) {
        setValues({
          ...values,
          examenHistologique: {
            ...values.examenHistologique,
            [`Her2NeuCheck`]: {
              ...emptyValues.examenHistologique[`Her2NeuCheck`],
              readOnly: true,
            },
          },
        });
      }

      ["HA", "DIABETE", "MC", "MR", "MF"].map((k) => {
        if (values.antecedentMed[k].value === "Oui") {
          switch (k) {
            case "MC":
              setMC("Oui");
              break;
            case "MR":
              setMR("Oui");
              break;
            case "MF":
              setMF("Oui");
              break;
            case "DIABETE":
              setDIABETE("Oui");
              break;
            case "HA":
              setHA("Oui");
              break;
            default:
              break;
          }

          if (values.antecedentMed.hasOwnProperty(`${k}dateDebut`) === false) {
            setValues({
              ...values,
              antecedentMed: {
                ...values.antecedentMed,
                [`${k}dateDebut`]: {
                  ...emptyValues.antecedentMed[`${k}dateDebut`],
                  readOnly: true,
                },
              },
            });
          } else if (
            values.antecedentMed.hasOwnProperty(`${k}dateFin`) === false
          ) {
            setValues({
              ...values,
              antecedentMed: {
                ...values.antecedentMed,
                [`${k}dateFin`]: {
                  ...emptyValues.antecedentMed[`${k}dateFin`],
                  readOnly: true,
                },
              },
            });
          } else {
          }
        }
      });
    }
    if (visit === "j2") {
      values.biologicalExamination["indiceChirurgie"] !==undefined && (values.biologicalExamination["indiceChirurgie"].value === "Oui"
        ? setIndiceChirurgie("Oui")
        : setIndiceChirurgie("Non"));
      if (
        values.biologicalExamination.hasOwnProperty(`indiceChirurgieRep`) ===
        false
      ) {
        setValues({
          ...values,
          biologicalExamination: {
            ...values.biologicalExamination,
            [`indiceChirurgieRep`]: {
              ...emptyValues.biologicalExamination[`indiceChirurgieRep`],
              readOnly: true,
            },
          },
        });
      }
    }
    if(visit === "j4" || visit === "j0" || visit==="j3"){
      ["Mammographie"].map((k) => {
        if (values.examenRadiologique[k].value === "Oui") {
          switch (k) {
            case "Mammographie":
              setMammographie("Oui");
              break;
            default:
              break;
          }
          console.log('values.examenRadiologique.hasOwnProperty(`mamoTaille`)',values.examenRadiologique.hasOwnProperty(`mamoTaille`))
          console.log('values.examenRadiologique.hasOwnProperty(`mamoTailleSecond`)',values.examenRadiologique.hasOwnProperty(`mamoTailleSecond`))

          if (values.examenRadiologique.hasOwnProperty(`mamoTaille`) === false) {
            setValues({
              ...values,
              examenRadiologique: {
                ...values.examenRadiologique,
                [`mamoTaille`]: {
                  ...emptyValues.examenRadiologique[`mamoTaille`],
                  readOnly: true,
                },
                [`mamoTailleSecond`]: {
                  ...emptyValues.examenRadiologique[`mamoTailleSecond`],
                  readOnly: true,
                },
              },
            });
          }else if(values.examenRadiologique.hasOwnProperty(`mamoTailleSecond`) === false){
            setValues({
              ...values,
              examenRadiologique: {
                ...values.examenRadiologique,
                [`mamoTailleSecond`]: {
                  ...emptyValues.examenRadiologique[`mamoTailleSecond`],
                  readOnly: true,
                },
              },
            });
          } else {
          }
        }
      });
    }
  }, [values]);

  const changeProp = (fieldFamily, fieldName, prop, value) => {
    if (
      fieldFamily === "inclusion" ||
      fieldFamily === "exclusion" ||
      fieldFamily === "biologicalExamination" ||
      fieldFamily === "examenHistologique" ||
      fieldFamily === "examenMolelculaire" ||
      fieldFamily === "antecedentMed" ||
      fieldFamily === "classificationTNM" ||
      fieldFamily === "examenRadiologique" ||
      fieldFamily === "examenClinique"
    ) {
      setValues({
        ...values,
        [fieldFamily]: {
          ...values[fieldFamily],
          [fieldName]: {
            ...values[fieldFamily][fieldName],
            [prop]: value,
          },
        },
      });
    } else {
      setValues({
        ...values,
        [fieldName]: {
          ...values[fieldName],
          [prop]: value,
        },
      });
    }
  };

  const prepareTreeData = (raw) =>
    raw.map((visit) => {
      const children = [];
      const splitVisitNumber = visit.visitNumber.split("");
      return {
        title: `Visite ${parseInt(splitVisitNumber[1]) + 1}`,
        key: visit._id,
        children,
        visit,
      };
    });

  const handleUpdateField = (fieldFamily, fieldName, prop, value, field) => {
    console.log(
      "fieldFamily, fieldName, prop, value",
      fieldFamily,
      fieldName,
      prop,
      value,
      field,
      visit
    );

    let currentForm;
    if (visit === "j0") {
      switch (current) {
        case 0:
          currentForm = fieldFamily.includes("date")
            ? "dateVisite"
            : "visitInformations";
          break;
        case 1:
          currentForm = "antecedentMed";
          break;
        case 2:
          currentForm = "examenClinique";
          break;
        case 3:
          currentForm = "examenRadiologique";
          break;
        case 4:
          currentForm = "classificationTNM";
          break;
        case 5:
          currentForm =
            fieldFamily === "AntecedentExamination"
              ? "antecedents"
              : "biologicalExamination";
          break;
        case 6:
          currentForm = "examenHistologique";
          break;
        case 7:
          currentForm = "examenMolelculaire";
          break;
        default:
          currentForm = "general";
          break;
      }
    } else if (visit === "j2") {
      switch (current) {
        case 0:
          currentForm = "examenClinique";
          break;
        case 1:
          currentForm = "biologicalExamination";
          break;
        case 2:
          currentForm = "examenMolelculaire";
          break;
        case 3:
          currentForm = "examenHistologique";
          break;
        case 4:
          currentForm = "classificationTNM";
          break;
        default:
          currentForm = "general";
          break;
      }
    } else if (visit === "j3") {
      switch (current) {
        case 0:
          currentForm = "examenClinique";
          break;
        case 1:
          currentForm = "biologicalExamination";
          break;
        case 2:
          currentForm = "examenRadiologique";
          break;
        default:
          currentForm = "general";
          break;
      }
    } else if (visit === "j4") {
      switch (current) {
        case 0:
          currentForm = "examenClinique";
          break;
        case 1:
          currentForm = "examenRadiologique";
          break;
        case 2:
          currentForm = fieldFamily.includes("date")
            ? "dateVisite"
            : "biologicalExamination";
          break;
        default:
          currentForm = "general";
          break;
      }
    } else {
      switch (current) {
        case 0:
          currentForm = "examenClinique";
          break;
        case 1:
          currentForm = "biologicalExamination";
          break;
        default:
          currentForm = "general";
          break;
      }
    }

    const form = mapFiedFamilyToForm[fieldFamily]
      ? mapFiedFamilyToForm[fieldFamily]
      : mapFiedFamilyToForm[currentForm];

    console.log("[debug] undefined form ? - ", {
      mapFiedFamilyToForm,
      fieldName,
      currentForm,
      form,
    });

    switch (prop) {
      case "frozen":
      case "sdv":
        if (
          fieldFamily === "inclusion" ||
          fieldFamily === "exclusion" ||
          fieldFamily === "biologicalExamination" ||
          fieldFamily === "AntecedentExamination" ||
          fieldFamily === "biologicalExamination" ||
          fieldFamily === "examenHistologique" ||
          fieldFamily === "examenMolelculaire" ||
          fieldFamily === "antecedentMed" ||
          fieldFamily === "classificationTNM" ||
          fieldFamily === "examenRadiologique" ||
          fieldFamily === "examenClinique"
        ) {
          let fieldToUpdate = {};
          if (values[fieldFamily] !== undefined) {
            fieldToUpdate = {
              ...values[fieldFamily][fieldName],
              [prop]: true,
            };
          } else {
            fieldToUpdate = {
              ...values[currentForm][fieldName],
              [prop]: true,
            };
          }
          const body = {
            field: fieldToUpdate,
            visitNumber: visit,
          };
          body.fieldFamily = fieldName;
          body.fieldCategorie = fieldFamily;

          updateVisitAsync(selectedVisit._id, body);
        } else if (
          currentForm === "dateVisite" ||
          currentForm === "visitInformations"
        ) {
          const fieldToUpdate = {
            ...values[fieldName],
            [prop]: true,
          };
          const body = {
            field: fieldToUpdate,
            visitNumber: visit,
          };
          body.fieldFamily = fieldName;

          updateVisitAsync(selectedVisit._id, body);
        } else {
          const fieldToUpdate = {
            ...values[fieldName],
            [prop]: true,
          };
          const body = {
            field: fieldToUpdate,
            visitNumber: visit,
          };
          body.fieldFamily = fieldName;

          updateVisitAsync(selectedVisit._id, body);
        }
        break;
      case "value":
        setIsModalVisible(false);

        const val = form.getFieldValue(fieldName);

        let field = {};
        if (
          fieldFamily === "biologicalExamination" ||
          fieldFamily === "examenHistologique" ||
          fieldFamily === "examenMolelculaire" ||
          fieldFamily === "antecedentMed" ||
          fieldFamily === "classificationTNM" ||
          fieldFamily === "examenRadiologique" ||
          fieldFamily === "examenClinique"
        ) {
          field = {
            ...values[currentForm][fieldName],
            value: val,
            readOnly: true,
          };
          const body = {
            field,
            visitNumber: visit,
          };
          body.fieldFamily = fieldName;
          body.fieldCategorie = currentForm;
          updateVisitAsync(selectedVisit._id, body);
        } else if (fieldFamily === "AntecedentExamination") {
        } else {
          let fieldToUpdate = {};
          if (values[fieldName] === null) {
            fieldToUpdate = {
              ...emptyValues[fieldName],
              value: val,
              readOnly: true,
            };
          } else if (values[fieldName]) {

            fieldToUpdate = {
              ...values[fieldName],
              value: val,
              readOnly: true,
            };
          } else if (values[fieldFamily]) {
            fieldToUpdate = {
              ...values[fieldFamily][fieldName],
              value:
                values[fieldFamily][fieldName].value === "Non" ? "Oui" : "Non",
              readOnly: true,
            };
          } else {

            fieldToUpdate = {
              ...values[currentForm][fieldName],
              value: val,
              readOnly: true,
            };
          }
          const body = {
            field: fieldToUpdate,
            visitNumber: visit,
          };
          body.fieldFamily = fieldName;
          body.fieldCategorie = fieldFamily;
          updateVisitAsync(selectedVisit._id, body);
        }
        break;
      case "cancel":
        // NOTE FIRST 2 TESTS ARE FOR TIMEPICKER && datetime picker (They accept moment DateTime as a value)
        switch (fieldName) {
          case "sampleTime":
            const newVals = values.bloodSamples.map((e) => {
              if (e[selectedBloodSample]) {
                e[fieldName].readOnly = true;
              }
              return e;
            });
            setValues({ ...values, [bloodSamples]: newVals });
            break;
          default:
            if (fieldFamily === "visitInformations") {
              if (fieldName === "transport") {
                changeProp(fieldFamily, fieldName, "readOnly", !value);
              } else {
                form.setFieldsValue({
                  [fieldName]: {
                    date: selectedVisit[fieldName].value.date,
                    time: moment(selectedVisit[fieldName].value.time),
                  },
                });
              }
            } else if (
              fieldFamily === "biologicalExamination" ||
              fieldFamily === "TraitementsExamination" ||
              fieldFamily === "SignesExamination" ||
              fieldFamily === "AntecedentExamination"
            ) {
              const newValsToArray = Object.keys(values[currentForm]).map(
                (key) => {
                  return values[currentForm][key];
                }
              );
              const newVals = newValsToArray.map((e) =>
                e.name === fieldName
                  ? { ...e, readOnly: true, value: "Non" }
                  : e
              );

              const newValusTwo = (array, key) => {
                return array.reduce((obj, item) => {
                  obj[item[key]] = item;
                  return obj;
                }, {});
              };
              onUpdateValidation(fieldName, value === false ? "Non" : "Oui");
              setValues({
                ...values,
                [currentForm]: newValusTwo(newVals, "name"),
              });
            } else {
              changeProp(fieldFamily, fieldName, "readOnly", !value);
            }
        }
        // if (fieldFamily !== "bloodSample") {
        //   changeProp(fieldFamily, fieldName, "readOnly", !value);
        // }
        break;
      case "readOnly":

        if (
          fieldFamily === "TraitementsExamination" ||
          fieldFamily === "SignesExamination" ||
          fieldFamily === "AntecedentExamination"
        ) {
          const newValsToArray = Object.keys(values[currentForm]).map((key) => {
            return values[currentForm][key];
          });
          const newVals = newValsToArray.map((e) =>
            e.name === fieldName ? { ...e, readOnly: false } : e
          );

          const newValusTwo = (array, key) => {
            return array.reduce((obj, item) => {
              obj[item[key]] = item;
              return obj;
            }, {});
          };
          setValues({ ...values, [currentForm]: newValusTwo(newVals, "name") });
        } else {
          changeProp(fieldFamily, fieldName, prop, value);
        }
        break;
      case "query":
        let fieldToSelect = selectedVisit[fieldName];
        if (
          fieldFamily === "biologicalExamination" ||
          fieldFamily === "examenHistologique" ||
          fieldFamily === "examenMolelculaire" ||
          fieldFamily === "antecedentMed" ||
          fieldFamily === "classificationTNM" ||
          fieldFamily === "examenRadiologique" ||
          fieldFamily === "examenClinique"
        ) {
          fieldToSelect = selectedVisit[currentForm][fieldName];
        } else if (fieldFamily === "inclusion" || fieldFamily === "exclusion") {
          fieldToSelect = selectedVisit[fieldFamily][fieldName];
        }
        selectField({ ...fieldToSelect, fieldFamily });
        setOpenQueryModal(true);
        break;
      default:
        if (
          currentForm === "antecedents" ||
          currentForm === "signes" ||
          currentForm === "traitements"
        ) {
          onUpdateValidation(fieldFamily, fieldName);
          const newValsToArray = Object.keys(values[currentForm]).map((key) => {
            return values[currentForm][key];
          });
          console.log("newValsToArray", newValsToArray);
          const newVals = newValsToArray.map((e) =>
            e.name === fieldFamily ? { ...e, value: fieldName } : e
          );
          console.log("newVals", newVals);
          const newValusTwo = (array, key) => {
            return array.reduce((obj, item) => {
              obj[item[key]] = item;
              return obj;
            }, {});
          };
          console.log(values[currentForm][`${fieldFamily}dateDebut`]);
          setValues({
            ...values,
            [currentForm]: {
              ...newValusTwo(newVals, "name"),
              [`${fieldFamily}dateDebut`]: emptyValues[currentForm][
                `${fieldFamily}dateDebut`
              ],
              [`${fieldFamily}dateFin`]: emptyValues[currentForm][
                `${fieldFamily}dateFin`
              ],
            },
          });
        } else {
          changeProp(fieldFamily, fieldName, prop, value);
          updateVisitAsync(selectedVisit._id, {
            field: {
              ...values[fieldName],
              [prop]: value,
            },
            // bloodSampleKey: values.bloodSamples[0]
            //   ? values.bloodSamples[0].key
            //   : "",
            fieldFamily,
            visitNumber: visit,
          });
        }
    }
  };

  const showStep = () => {
    if (visit === "j0" || visit === "j7") {
      switch (current) {
        case 0:
          return (
            <Inclusion
              inclusionForm={inclusionForm}
              exclusionForm={exclusionForm}
              dateVisiteForm={dateVisiteForm}
              values={values}
              onUpdate={handleUpdateField}
            />
          );
        case 1:
          return (
            <div>
              <AntecedentsMed
                values={values}
                form={antecedentsMedForm}
                onUpdate={handleUpdateField}
                visit={visit}
                MC={MC}
                DIABETE={DIABETE}
                HA={HA}
                MF={MF}
                MR={MR}
              />
            </div>
          );
        case 2:
          return (
            <ExamenClinique
              values={values}
              form={examenCliniqueForm}
              onUpdate={handleUpdateField}
              isAdd={false}
              visit={visit}
            />
          );
        case 3:
          return (
            <ExamenRadiologique
              values={values}
              form={examnRadiologiqueForm}
              onUpdate={handleUpdateField}
              isAdd={false}
              visit={visit}
              mamoTaille={mamoTaille}
              mamoTailleSecond={mamoTailleSecond}
              Mammographie={Mammographie}
              thoraco={thoraco}
              scintigraphie={scintigraphie}
              echographie={echographie}
            />
          );
        case 4:
          return (
            <ClassificationTNM
              values={values}
              form={ClassificationTNMForm}
              onUpdate={handleUpdateField}
              visit={visit}
            />
          );
        case 5:
          return (
            <AntecedentsExams
              values={values}
              form={examinationsForm}
              onUpdate={handleUpdateField}
              visit={visit}
              mamoTaille={mamoTaille}
              mamoTailleSecond={mamoTailleSecond}
              Mammographie={Mammographie}
              thoraco={thoraco}
              scintigraphie={scintigraphie}
              echographie={echographie}
              Her2Neu={Her2Neu}
              indiceChirurgie={indiceChirurgie}
            />
          );
        case 6:
          return (
            <ExamenHistologique
              values={values}
              form={examnHistologiqueForm}
              onUpdate={handleUpdateField}
              isAdd={false}
              visit={visit}
            />
          );
        case 7:
          return (
            <ExamenMleculaire
              values={values}
              form={examnMoleclulaireForm}
              onUpdate={handleUpdateField}
              isAdd={false}
              visit={visit}
            />
          );
        case 8:
          return (
            <Treatments
              onChange={(name, value) => {
                setValues({ ...values, [name]: value });
              }}
              onFormUpdate={handleUpdateField}
              values={values}
              isAdd={false}
              form={hasNoTreatmentsForm}
            />
          );
        case 9:
          return (
            <DrugForm
              form={visitInformationsForm}
              visit={visit}
              //patient={patient}
              values={values}
              onUpdate={handleUpdateField}
            />
          );
        default:
          return (
            <div>
              {" "}
              Une erreur inconnue est survenue ! Merci de rafraichir la page. Si
              le problème persiste veuillez contacter le support.
            </div>
          );
      }
    } else if (visit === "j2") {
      switch (current) {
        case 0:
          return (
            <ExamenClinique
              values={values}
              form={examenCliniqueForm}
              onUpdate={handleUpdateField}
              isAdd={false}
              visit={visit}
            />
          );
        case 1:
          return (
            <AntecedentsExams
              values={values}
              form={examinationsForm}
              onUpdate={handleUpdateField}
              visit={visit}
              MC={MC}
              DIABETE={DIABETE}
              HA={HA}
              MF={MF}
              MR={MR}
              mamoTaille={mamoTaille}
              mamoTailleSecond={mamoTailleSecond}
              Mammographie={Mammographie}
              thoraco={thoraco}
              scintigraphie={scintigraphie}
              echographie={echographie}
              Her2Neu={Her2Neu}
              indiceChirurgie={indiceChirurgie}
              echographieMammaire={echographieMammaire}
            />
          );
        case 2:
          return (
            <ExamenMleculaire
              values={values}
              form={examnMoleclulaireForm}
              onUpdate={handleUpdateField}
              isAdd={false}
              visit={visit}
            />
          );
        case 3:
          return (
            <ExamenHistologique
              values={values}
              form={examnHistologiqueForm}
              onUpdate={handleUpdateField}
              isAdd={false}
              visit={visit}
            />
          );
        case 4:
          return (
            <ClassificationTNM
              values={values}
              form={ClassificationTNMForm}
              onUpdate={handleUpdateField}
              visit={visit}
            />
          );
        case 5:
          return (
            <DrugForm
              form={visitInformationsForm}
              visit={visit}
              //patient={patient}
              values={values}
              onUpdate={handleUpdateField}
            />
            // <VisitInformations
            //   onUpdate={handleUpdateField}
            //   values={values}
            //   form={visitInformationsForm}
            //   visit={visit}
            // />
          );
        default:
          return <div />;
      }
    } else if (visit === "j3") {
      switch (current) {
        case 0:
          return (
            <ExamenClinique
              values={values}
              form={examenCliniqueForm}
              onUpdate={handleUpdateField}
              isAdd={false}
              visit={visit}
            />
          );
        case 1:
          return (
            <AntecedentsExams
              values={values}
              form={examinationsForm}
              onUpdate={handleUpdateField}
              visit={visit}
              MC={MC}
              DIABETE={DIABETE}
              HA={HA}
              MF={MF}
              MR={MR}
              mamoTaille={mamoTaille}
              mamoTailleSecond={mamoTailleSecond}
              Mammographie={Mammographie}
              thoraco={thoraco}
              scintigraphie={scintigraphie}
              echographie={echographie}
              Her2Neu={Her2Neu}
              indiceChirurgie={indiceChirurgie}
            />
          );
        case 2:
          return (
            <ExamenRadiologique
              values={values}
              form={examnRadiologiqueForm}
              onUpdate={handleUpdateField}
              isAdd={false}
              visit={visit}
              mamoTaille={mamoTaille}
              mamoTailleSecond={mamoTailleSecond}
              Mammographie={Mammographie}
              thoraco={thoraco}
              scintigraphie={scintigraphie}
              echographie={echographie}
            />
          );
        case 3:
          return (
            <DrugForm
              form={visitInformationsForm}
              visit={visit}
              //patient={patient}
              values={values}
              onUpdate={handleUpdateField}
            />
            // <VisitInformations
            //   onUpdate={handleUpdateField}
            //   values={values}
            //   form={visitInformationsForm}
            //   visit={visit}
            // />
          );
        default:
          return <div />;
      }
    } else if (visit === "j4") {
      switch (current) {
        case 0:
          return (
            <ExamenClinique
              values={values}
              form={examenCliniqueForm}
              onUpdate={handleUpdateField}
              isAdd={false}
              visit={visit}
            />
          );
        case 1:
          return (
            <ExamenRadiologique
              values={values}
              form={examnRadiologiqueForm}
              onUpdate={handleUpdateField}
              isAdd={false}
              visit={visit}
              mamoTaille={mamoTaille}
              mamoTailleSecond={mamoTailleSecond}
              Mammographie={Mammographie}
              thoraco={thoraco}
              scintigraphie={scintigraphie}
              echographie={echographie}
            />
          );
        case 2:
          return (
            <AntecedentsExams
              values={values}
              form={examinationsForm}
              dateVisiteForm={dateVisiteForm}
              onUpdate={handleUpdateField}
              visit={visit}
              MC={MC}
              DIABETE={DIABETE}
              HA={HA}
              MF={MF}
              MR={MR}
              mamoTaille={mamoTaille}
              mamoTailleSecond={mamoTailleSecond}
              Mammographie={Mammographie}
              thoraco={thoraco}
              scintigraphie={scintigraphie}
              echographie={echographie}
              Her2Neu={Her2Neu}
              indiceChirurgie={indiceChirurgie}
            />
          );

        default:
          return <div />;
      }
    } else {
      switch (current) {
        case 0:
          return (
            <ExamenClinique
              values={values}
              form={examenCliniqueForm}
              onUpdate={handleUpdateField}
              isAdd={false}
              visit={visit}
            />
          );
        case 1:
          return (
            <AntecedentsExams
              values={values}
              form={examinationsForm}
              onUpdate={handleUpdateField}
              visit={visit}
              MC={MC}
              DIABETE={DIABETE}
              HA={HA}
              MF={MF}
              MR={MR}
              mamoTaille={mamoTaille}
              mamoTailleSecond={mamoTailleSecond}
              Mammographie={Mammographie}
              thoraco={thoraco}
              scintigraphie={scintigraphie}
              echographie={echographie}
              Her2Neu={Her2Neu}
              indiceChirurgie={indiceChirurgie}
              echographieMammaire={echographieMammaire}
            />
          );
        case 2:
          return (
            <DrugForm
              form={visitInformationsForm}
              visit={visit}
              values={values}
              onUpdate={handleUpdateField}
            />
          );
        default:
          return <div />;
      }
    }
  };
  const onChange = (name, value) => {
    console.log("DEBUG name, value", name, value);
    // setValues({ ...values, [name]: value });
    updateVisitAsync(selectedVisit._id, {
      fieldFamily: name,
      field: {
        name,
        value,
      },
      visit,
    });
  };
  useEffect(() => {
    console.log("DEBUG SELCtED VISIET", selectedVisit);
    if (selectedVisit) {
      if (visit === "j0" || visit === "j7") {
        setValues({
          ...selectedVisit,
        });
        const keys = Object.keys(selectedVisit);
        // eslint-disable-next-line array-callback-return
        keys.map((key) => {
          const field = selectedVisit[key];
          switch (key) {
            // case "consentDate":
            // case "inclusionDate":
            case "medicalAntecedentsProps":
            case "treatmentsProps":
              break;

            case "transport":
            case "transportAutre":
            case "initial":
              visitInformationsForm.setFieldsValue({
                [key]: selectedVisit[key].value,
              });
              break;
            // visitInformationsForm.setFieldsValue({
            //   [key]: {
            //     date: selectedVisit[key].value
            //       ? selectedVisit[key].value.date
            //       : null,
            //     time: selectedVisit[key].value
            //       ? moment(selectedVisit[key].value.time)
            //       : null,
            //   },
            // });
            // break;
            case "inclusion":
              Object.keys(field).forEach((key) =>
                inclusionForm.setFieldsValue({ [key]: field[key].value })
              );
              break;
            case "dateVisite":
            case "consentDate":
              dateVisiteForm.setFieldsValue({ [key]: field.value });
              break;
            case "exclusion":
              Object.keys(field).forEach((key) =>
                exclusionForm.setFieldsValue({ [key]: field[key].value })
              );
              break;
            case "biologicalExamination":
              Object.keys(field).forEach((key) => {
                examinationsForm.setFieldsValue({
                  [key]: field[key].value,
                });
              });
              break;
            case "antecedents":
              Object.keys(field).forEach((key) => {
                if (field[key].value === "Oui") {
                  onUpdateValidation(key, field[key].value);
                }
                antecedentsForm.setFieldsValue({ [key]: field[key].value });
              });
              break;
            // case "signes":
            //   Object.keys(field).forEach((key) => {
            //     if (field[key].value === "Oui") {
            //       onUpdateValidation(key, field[key].value);
            //     }
            //     signesForm.setFieldsValue({ [key]: field[key].value });
            //   });

            //   break;
            case "traitements":
              Object.keys(field).forEach((key) => {
                if (field[key].value === "Oui") {
                  onUpdateValidation(key, field[key].value);
                }
                traitementsForm.setFieldsValue({
                  [key]: field[key].value,
                });
              });
              break;
            // case "bloodSamples":
            //   console.log(
            //     "sampleTime.value",
            //     bloodSampleForm.getFieldValue("sampleTime")
            //   );
            //   bloodSampleForm.setFieldsValue({
            //     sampleTime: sampleTime ? moment(sampleTime.value) : null,
            //     firstTube: firstTube.value,
            //     secondTube: secondTube ? secondTube.value : "",
            //   });
            //   break;
            // case "dateNextRDVCardio":
            //   drugForm.setFieldsValue({ [key]: field.value });
            //   break;
            // case "dateNextRDVCardioHeure":
            //   drugForm.setFieldsValue({ [key]: field.value });
            //   break;
            //   case "dateNextVisitV1":
            //     drugForm.setFieldsValue({ [key]: field.value });
            //     break;
            case "consentDate":
            case "inclusionDate":
            case "dateVisite":
              visitInformationsForm.setFieldsValue({ [key]: field.value });
              break;
            case "dateNextVisitHeure":
              visitInformationsForm.setFieldsValue({ [key]: field.value });
              break;
            case "dateNextVisit":
              visitInformationsForm.setFieldsValue({ [key]: field.value });
              break;
            default:
              break;
          }
        });
        // } else if (visit === "j2") {
        //   setValues({ ...selectedVisit });
        //   const keys = Object.keys(selectedVisit);
        //   // eslint-disable-next-line array-callback-return
        //   keys.map((key) => {
        //     const field = selectedVisit[key];
        //     switch (key) {
        //       case "dateVisite":
        //         clinicalForm.setFieldsValue({
        //           [key]: field.value,
        //         });
        //         break;
        //       case "beats":
        //       case "remis":
        //       case "finished":
        //       case "recover":
        //       case "weight":
        //       case "height":
        //       case "systolique":
        //       case "diastolique":
        //       case "systoliqueRight":
        //       case "diastoliqueRight":
        //       case "rapportLVEF":
        //       case "finishedDoctor":
        //       case "recoverDoctor":
        //       case "transport":
        //       case "transportAutre":
        //       case "InsuffisanceCardiaque":
        //       case "cliniques":
        //         measureForm.setFieldsValue({ [key]: selectedVisit[key].value });
        //         break;
        //       // case "cliniques":
        //       //   console.log(selectedVisit[key].value)

        //       //   const toCheckboxList = selectedVisit[key].value.map(e => ({
        //       //     "label" : e,
        //       //     "value": e,
        //       //   }))

        //       //   clinicalForm.setFieldsValue({ [key]: toCheckboxList });
        //       //   break
        //       default:
        //         break;
        //     }
        //   });
        //
      } else {
        setValues({ ...selectedVisit });
        const keys = Object.keys(selectedVisit);
        // eslint-disable-next-line array-callback-return
        keys.map((key) => {
          const field = selectedVisit[key];
          switch (key) {
            case "dateInvisigateur":
            // case "dateVisiteV1":
            //   clinicalForm.setFieldsValue({
            //     [key]: selectedVisit[key].value,
            //   });
            //   break;
            case "dateVisite":
              dateVisiteForm.setFieldsValue({ [key]: field.value });
              break;
            case "beats":
            case "remis":
            case "finished":
            case "recover":
            case "weight":
            case "height":
            case "nexus":
            case "systolique":
            case "diastolique":
            case "initial":
              // case "systoliqueRight":
              // case "diastoliqueRight":
              clinicalForm.setFieldsValue({ [key]: selectedVisit[key].value });
              break;
            case "biologicalExamination":
              Object.keys(field).forEach((key) => {
                console.log(field[key].value);
                examinationsForm.setFieldsValue({
                  [key]: field[key].value,
                });
              });
              break;
            case "dateVisite":
              visitInformationsForm.setFieldsValue({ [key]: field.value });
              break;
            case "dateNextVisitHeure":
              visitInformationsForm.setFieldsValue({ [key]: field.value });
              break;
            case "dateNextVisit":
              visitInformationsForm.setFieldsValue({ [key]: field.value });
              break;
            case "examenClinique":
              Object.keys(field).forEach((key) => {
                console.log(field[key].value);
                examenCliniqueForm.setFieldsValue({
                  [key]: field[key].value,
                });
              });
              break;
            default:
              break;
          }
        });
      }
      // setBloodSamples(selectedVisit.bloodSamples);
    }
  }, [selectedVisit]);

  useEffect(() => {
    if (user && user.type === 1) {
      if (visit === "j1" || visit === "j8") {
        // if (selectedVisit.bloodSamples.length !== 2) {
        //   setShowAddSample(true);
        // } else {
        setShowAddSample(false);
        // }
      } else if (visit === "j2" || visit === "j9") {
        // if (selectedVisit.bloodSamples.length !== 1) {
        //   setShowAddSample(true);
        // } else {
        setShowAddSample(false);
        // }
      }
      // else if (selectedVisit.bloodSamples.length !== 12) {
      //   //setShowAddSample(true);
      // }
      else {
        //setShowAddSample(false);
      }
    } else {
      setShowAddSample(false);
    }
  }, [selectedVisit]);

  if (!selectedVisit) return <div />;

  return (
    <div className="section-content">
      <HeadBreadCrumb
        firstSectionName="Visites"
        secondSectionName={parseVisitName(selectedVisit.visitNumber)}
        thirdSectionName={
          // user && user.type === 1
          //   ? `${selectedVisit.patient.lastName.value} ${selectedVisit.patient.firstName.value}`
          //   :
          selectedVisit.patient.customId
        }
        handleGoBack={handleGoBack}
      />
      <Spin spinning={contentIsLoading}>
        <Card style={{ height: "5em", marginBottom: "20px" }}>
          <Tree
            treeData={prepareTreeData(listVisitsByPatient)}
            onSelect={(selectedKey, info) => {
              //selectedPatient(selectedKey[0]);
              history.push({
                pathname: `/${info.node.visit.visitNumber}`,
                state: {
                  withQueryPatientId: info.node.visit.patient,
                  visitsList: listVisitsByPatient,
                  clicked: true,
                  selectedVisitFromTree: info.node.visit,
                },
              });
            }}
          />
        </Card>
        <Card className="formWidth">
          <Steps
            current={current}
            onChange={setCurrent}
            size="small"
            className="m-bottom-medium"
          >
            {visit === "j0" || visit === "j7" ? (
              <>
                <Step title="Inlcusion & Exclusion" />
                <Step title="Antécédents" />
                <Step title="Clinique" />
                <Step title="Radiologie" />
                <Step title="Classification TNM" />
                <Step title="Biologie" />
                <Step title="Histologie" />
                <Step title="Examen moléculaire" />
                <Step title="Traitements" />
                <Step title="Finalisation" />
              </>
            ) : visit === "j1" ? (
              <>
                <Step title="Examen Clinique" />
                <Step title="Informations" />
                <Step title="Finalisation" />
              </>
            ) : visit === "j2" ? (
              <>
                <Step title="Clinique" />
                <Step title="Biologie" />
                <Step title="Examen moléculaire" />
                <Step title="Histologie" />
                <Step title="Classification TNM" />
                <Step title="Finalisation" />
              </>
            ) : visit === "j3" ? (
              <>
                <Step title="Examen Clinique" />
                <Step title="Informations" />
                <Step title="Radiologie" />
                <Step title="Finalisation" />
              </>
            ) : (
              <>
                <Step title="Examen Clinique" />
                <Step title="Radiologie" />
                <Step title="Informations" />
              </>
            )}
          </Steps>
          <div className="m-top-medium" style={{ padding: 24 }}>
            {showStep()}
          </div>
        </Card>
      </Spin>
      <QueryModal
        call={selectedVisit}
        open={openQueryModal}
        field={selectedField}
        handleClose={() => setOpenQueryModal(false)}
      />
    </div>
  );
};

SelectedVisit.propTypes = {
  contentIsLoading: PropTypes.bool.isRequired,
  visit: PropTypes.string.isRequired,
  handleGoBack: PropTypes.func.isRequired,
  selectedVisit: PropTypes.object.isRequired,
  updateVisitAsync: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  getAllVisitsByPatientAsync: PropTypes.func.isRequired,
};

const stateToProps = (state) => ({
  contentIsLoading: state.loading.contentIsLoading,
  selectedVisit: state.visits.selectedVisit,
  user: state.auth.user,
  listVisitsByPatient: state.visits.visitsPatient,
});

const dispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      updateVisitAsync,
      getAllVisitsByPatientAsync,
      updatePatientAsync,
      selectVisitAsync,
    },
    dispatch
  );

export default connect(stateToProps, dispatchToProps)(SelectedVisit);

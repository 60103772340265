import React from "react";
import PropTypes from "prop-types";
import { Form, Card, Typography, Divider } from "antd";
import DynamicField from "../DynamicField";
import Title from "antd/lib/skeleton/Title";

const ExamenMleculaire = (props) => {
  return (
    <Form
      style={{ marginTop: 30 }}
      form={props.form}
      className="biological-examination-form"
    >
      {props.isAdd ? (
        <>
          {props.visit === "j0" ? (
            <Card title="Examen moléculaire" className="add-antecedent-card">
              <div style={{ marginLeft: "4%" }}>
                <Title style={{ marginBottom: 14 }} level={5}>
                  Examen moléculaire
                </Title>
                <div>
                  {["MSIPCR", "TMBNGS", "MSIPARPCR", "HRD"].map((k) => (
                    <DynamicField
                      key={k}
                      field={props.values.examenMolelculaire[k]}
                      onUpdate={props.onUpdate}
                      fieldFamily="examenMolelculaire"
                    />
                  ))}
                </div>
              </div>
            </Card>
          ) : props.visit === "j2" ? (
            <Card title="Examen moléculaire" className="add-antecedent-card">
              <div style={{ marginLeft: "4%" }}>
                <Title style={{ marginBottom: 14 }} level={5}>
                  Examen moléculaire
                </Title>
                <div>
                  {["ADNPCR"].map((k) => (
                    <DynamicField
                      key={k}
                      field={props.values.examenMolelculaire[k]}
                      onUpdate={props.onUpdate}
                      fieldFamily="examenMolelculaire"
                    />
                  ))}
                </div>
              </div>
            </Card>
          ) : null}
        </>
      ) : (
        <>
          {props.visit === "j0" ? (
            <Card title="Examen moléculaire" className="add-antecedent-card">
              <div style={{ marginLeft: "4%" }}>
                <Title style={{ marginBottom: 14 }} level={5}>
                  Examen moléculaire
                </Title>
                <div>
                  {["MSIPCR", "TMBNGS", "MSIPARPCR", "HRD"].map((k) => (
                    <DynamicField
                      key={k}
                      field={props.values.examenMolelculaire[k]}
                      onUpdate={props.onUpdate}
                      fieldFamily="examenMolelculaire"
                    />
                  ))}
                </div>
              </div>
            </Card>
          ) : props.visit === "j2" ? (
            <Card title="Examen moléculaire" className="add-antecedent-card">
              <div style={{ marginLeft: "4%" }}>
                <Title style={{ marginBottom: 14 }} level={5}>
                  Examen moléculaire
                </Title>
                <div>
                  {["ADNPCR"].map((k) => (
                    <DynamicField
                      key={k}
                      field={props.values.examenMolelculaire[k]}
                      onUpdate={props.onUpdate}
                      fieldFamily="examenMolelculaire"
                    />
                  ))}
                </div>
              </div>
            </Card>
          ) : null}
        </>
      )}
    </Form>
  );
};
ExamenMleculaire.propTypes = {
  form: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  onUpdate: PropTypes.func,
};

ExamenMleculaire.defaultProps = {
  onUpdate: () => {},
};

export default ExamenMleculaire;
